import { endCall } from '../core/sideEffects';
import { connect } from 'react-redux';
import EndCall, { EndCallProps } from '../components/EndCall';
import { State } from '../core/reducers';

const mapStateToProps = (state: State, props: EndCallProps) => ({
  call: state.calls.call,
  user: state.contosoClient.user,
  userId: state.contosoClient.user.identity,
  messages: state.chat.messages,
  remoteParticipants: state.calls.remoteParticipants,
  accountId: state.sdk.meetingData.AccountInfo.OrgAccountId,
  endUserCall: (): void => {
    state.calls.call && endCall(state.calls.call, { forEveryone: false });
  },
 });



const connector: any = connect(mapStateToProps);
export default connector(EndCall);
