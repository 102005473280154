import { BlobServiceClient, BlobClient, ContainerClient } from '@azure/storage-blob';
import axios from 'axios';


const containerName = 'gatheringjarfiles';
const sasToken = 'sv=2021-06-08&ss=bft&srt=sco&sp=rwdlacuitfx&se=2022-12-30T21:10:50Z&st=2022-06-22T13:10:50Z&spr=https&sig=m1CUttGMZHH94%2Bmv%2BLmF4qtWRw6strV0cULXjA4K204%3D';
const storageAccountName = 'gatheringjar'; 
//const accessKey = `ppIxgRJU4etyLC0t6Mj32dSZ+MolhsDYzHuNQNiseXy0iKzYWs1y4BpNS7cVXVCJ5T3x5vHB8Cep+AStFpjckQ==`;
//const sharedKeyCredential = new StorageSharedKeyCredential(storageAccountName, accessKey);
const container = `clickandpledge`;
const connectionString = `BlobEndpoint=https://gatheringjar.blob.core.windows.net/;QueueEndpoint=https://gatheringjar.queue.core.windows.net/;FileEndpoint=https://gatheringjar.file.core.windows.net/;TableEndpoint=https://gatheringjar.table.core.windows.net/;SharedAccessSignature=sv=2021-06-08&ss=bft&srt=sco&sp=rwdlacuitfx&se=2022-12-30T21:10:50Z&st=2022-06-22T13:10:50Z&spr=https&sig=m1CUttGMZHH94%2Bmv%2BLmF4qtWRw6strV0cULXjA4K204%3D`;


function findAllByKey(obj:any, keyToFind:any): never[] {
  return Object.entries(obj)
    .reduce((acc, [key, value]: any) => (key === keyToFind)
      ? acc.concat(value)
      : (typeof value === 'object')
      ? acc.concat(findAllByKey(value, keyToFind))
      : acc
    , [])
}

export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}

// return list of blobs in container to display
export const getBlobsInContainer = async (gid: string) => {
  const returnedBlobUrls: string[] = [];
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);

  // for await (const blob of containerClient.listBlobsFlat()) {
  //  // if(blob.name.indexOf(gid) !== -1){
  
  //       returnedBlobUrls.push(
  //           `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
  //         );
  //  // }
    
  // }
  //await Promise.all(returnedBlobUrls)

//let i=0;
  let iter = containerClient.listBlobsFlat();
let blobItem = await iter.next();
while (!blobItem.done) {
  if(blobItem.value.name.indexOf(gid) !== -1){
 
    returnedBlobUrls.push(
                `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blobItem.value.name}`
              );
  }
  blobItem = await iter.next();
}
  return returnedBlobUrls;
}

const createBlobInContainer = async (containerClient: ContainerClient, file: File, path: any) => {
  
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(path+file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadData(file, options);
}

const uploadFileToBlob = async (file: File | null, user: string, gid: string): Promise<string[]> => {

  if (!file) return [];
  let Ftype = file.name.split('.').pop();
  let extension = Ftype;
  if(Ftype == 'jpg' || 'jpeg') extension = 'jpeg';

  let new_file = new File([file], `${gid}@${user}.${Ftype}`,{type: `image/${extension}`});

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  // await containerClient.createIfNotExists({
  //   access: 'container',
  // });

  // upload file
  await createBlobInContainer(containerClient, new_file, '');

  // get list of blobs in container
  return getBlobsInContainer(gid);
};

const uploadProfileImage = async (params:any) => {
  try {
    const file = params.file;
    const blobService = new BlobServiceClient(
     `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
   );
  
   const containerClient: ContainerClient = blobService.getContainerClient(container);
   if(!containerClient.exists()){
    await containerClient.createIfNotExists({
      access: 'container',
    });
   }
  
     const path =`${params.accountId}/gatheringjar/profileimages/`;
     await createBlobInContainer(containerClient, file, path);
    return {status: 200};
  } catch (error) {
    return error;
  }

};

const getProfileImage = async (params:any) => {

  // const blobService = new BlobServiceClient(
  //   `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  // );
const blobPath = `${params.accountId}/gatheringjar/profileimages/${params.userImage}`;  
const blobClient = new BlobClient(connectionString, container, blobPath);

  if(await blobClient.exists()){
    let fname = params.userImage.replace(/@/gi, "%40");
        fname = fname.replace(/#/gi, "%23");
        fname = fname.replaceAll(" ", "%20");
     return `https://${storageAccountName}.blob.core.windows.net/${container}/${params.accountId}/gatheringjar/profileimages/${fname}`
  }else{
    return null;
  }
};

const getAllProfilePics = async (params:any) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
 
  const containerClient: ContainerClient = blobService.getContainerClient(container);

  const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/${params.gId}.json`;
  let response = await axios.get(`https://${storageAccountName}.blob.core.windows.net/${container}/${blobPath}`);
  let filedata = response.data;
  let allFinalImages= [];
  if(filedata){
    let names = findAllByKey(filedata, 'displayName');
    let emails = findAllByKey(filedata, 'email');
    let combined = names.map(function(item, index) {
      return  emails[index]+'#'+item+'.jpg';
    });

    let alluserimg:any[] = [];
    const prefix = `${params.accountId}/gatheringjar/profileimages/`;
    let iter = containerClient.listBlobsFlat({ prefix: prefix });
    if(iter){
      for await (const item of iter) {
        alluserimg.push(item.name.split('/').pop())
      }
    }
   
    if(alluserimg.length && combined.length){
      
      let filteredimgs = alluserimg.filter(item => combined.includes(item));
      
      filteredimgs = filteredimgs.map(list =>{
        let imagename = list.replace(/@/gi, "%40");
            imagename = imagename.replace(/#/gi, "%23");
            return `https://${storageAccountName}.blob.core.windows.net/${container}/${prefix}${imagename}`;
      });
      filteredimgs = [...new Set(filteredimgs)];
      
      allFinalImages = filteredimgs;
    }else{
      allFinalImages = [];
    }
  }

 return allFinalImages;
};

const uploadChatAttachment =async (params:any) => {
  const file = params.file;
    const blobService = new BlobServiceClient(
     `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
   );
  
   const containerClient: ContainerClient = blobService.getContainerClient(container);
   if(!containerClient.exists()){
    await containerClient.createIfNotExists({
      access: 'container',
    });
   }
  
     const path =`${params.accountId}/gatheringjar/attachments/${params.gId}/${params.tId}/`;
     await createBlobInContainer(containerClient, file, path);
    return {status: 200};
}

const getAttachmentURL = async (params:any) => {
  const blobPath = `${params.accountId}/gatheringjar/attachments/${params.gId}/${params.tId}/${params.fileName}`;  
const blobClient = new BlobClient(connectionString, container, blobPath);

  if(await blobClient.exists()){
    let fname = params.tId+'/'+params.fileName;
        fname = fname.replace(/@/gi, "%40");
        fname = fname.replace(/#/gi, "%23");
        fname = fname.replace(/:/gi, "%3A");
        fname = fname.replaceAll(" ", "%20");
        
     return `https://${storageAccountName}.blob.core.windows.net/${container}/${params.accountId}/gatheringjar/attachments/${params.gId}/${fname}`
  }else{
    return null;
  }
};

const getAllAttachments =async (params:any) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
 
  const containerClient: ContainerClient = blobService.getContainerClient(container);
  const prefix = `${params.accountId}/gatheringjar/attachments/${params.gId}/${params.tId}/`;
  let iter = containerClient.listBlobsFlat({ prefix: prefix });
  let allattachments = [];
  for await (const item of iter) {
    let newobj:any = item.name.split('/').pop();
    allattachments.push({ id: newobj.split('#')[0], name: newobj.split('#').pop(), uploadDateTime: new Date().toLocaleString() });
  }
  //console.log(`Sub== BlobItem: name - `,allattachments);
  return allattachments;
};

const saveMeetingInfo =async (params:any) => {
   const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient: ContainerClient = blobService.getContainerClient(container);
    if(!containerClient.exists()){
      await containerClient.createIfNotExists({
        access: 'container',
      });
     }
     const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/${params.gId}.json`; 
     const blobClient = containerClient.getBlockBlobClient(blobPath);
      const options = { blobHTTPHeaders: { blobContentType: 'application/json' } };
      
     const blobClient1 = new BlobClient(connectionString, container, blobPath);
     
       if(await blobClient1.exists()){
        let response = await axios.get(`https://${storageAccountName}.blob.core.windows.net/${container}/${blobPath}`);
          let filedata = response.data;
           if(filedata.hasOwnProperty(Object.keys(params.data)[0])){
             let obj:any = {};
             let val:any = Object.values(params.data)[0];

            obj[Object.keys(val)[Object.keys(val).length - 1]] = val[Object.keys(val)[Object.keys(val).length - 1]];
            Object.assign(filedata[Object.keys(filedata)[Object.keys(filedata).length - 1]],obj);
           }
           let obj1:any = JSON.stringify(filedata);
           await blobClient.uploadData(obj1, options);
       }else{
        let obj2:any = JSON.stringify(params.data);
        await blobClient.uploadData(obj2, options);
       }

  };

const saveEndMeetingInfo = async (params:any) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(container);
  const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/${params.gId}.json`; 
  const blobClient = containerClient.getBlockBlobClient(blobPath);
  const options = { blobHTTPHeaders: { blobContentType: 'application/json' } };
  
  let response = await axios.get(`https://${storageAccountName}.blob.core.windows.net/${container}/${blobPath}`);
  let filedata = response.data;
  if(filedata){
    let fval:any, fdata = {};
       fdata = filedata;
       fval = Object.values(fdata)[0];
       if(fdata.hasOwnProperty(params.gId)){
        fval['meetingEndedAt'] = new Date().toLocaleString();
        fval['meetingStatus'] = 'Ended';
      }
      if(fval.hasOwnProperty(params.clientId)){
        fval[params.clientId]['calls']['callEndedAt'] = new Date();
        fval[params.clientId]['calls']['state'] = "Disconnected";
      }
      let obj3:any = JSON.stringify(fdata);
        await blobClient.uploadData(obj3, options);
  }
     
};

const saveLogInfo = async (params:any) => {
  
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(container);
  if(!containerClient.exists()){
    await containerClient.createIfNotExists({
      access: 'container',
    });
   }
   const blobPath = `${params.accountId}/gatheringjar/logs/${params.gId}/log_${params.gId}.json`; 
   const blobClient = containerClient.getBlockBlobClient(blobPath);
    const options = { blobHTTPHeaders: { blobContentType: 'application/json' } };
    
   const blobClient1 = new BlobClient(connectionString, container, blobPath);
   
     if(await blobClient1.exists()){
      let response = await axios.get(`https://${storageAccountName}.blob.core.windows.net/${container}/${blobPath}`);
      let fdata = response.data;
      let loger = [];
      let fval:any = Object.values(fdata)[0];
        if(fdata.hasOwnProperty(params.gId)){
         if(fval.logs){
           fval.logs.push(params);
         }else{
          loger.push(params)
          fval['logs'] = loger;
         }
        }
        let obj5:any = JSON.stringify(fdata);
      await blobClient.uploadData(obj5, options);
     }else{
      let gId = params.gId;
      let obj:any = {}, log = [];
      log.push(params)
      obj[gId] = {};
      obj[gId]['meetingId']= gId;
      obj[gId]['logs']= log;
      let obj4:any = JSON.stringify(obj);
      await blobClient.uploadData(obj4, options);
     }
};

const saveChatThreadsList =async (params:any) => {

  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(container);
  if(!containerClient.exists()){
    await containerClient.createIfNotExists({
      access: 'container',
    });
   }
   const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/chatThreadsList.json`; 
   const blobClient = containerClient.getBlockBlobClient(blobPath);
    const options = { blobHTTPHeaders: { blobContentType: 'application/json' } };
    
   const blobClient1 = new BlobClient(connectionString, container, blobPath);
   
     if(await blobClient1.exists()){
      let response = await axios.get(`https://${storageAccountName}.blob.core.windows.net/${container}/${blobPath}`);
        let filedata = response.data;
         
         let newArray = filedata.threadList;
         let newObj = {
          userId: params.uId,
          threadId: params.tId
        };
        let exists = newArray.some((e:any) => Object.entries(e).toString() === Object.entries(newObj).toString());
        if(!exists) newArray.push(newObj);
       
       let finaldataExt = {
        threadList: [...newArray]
      }
    
         let obj1:any = JSON.stringify(finaldataExt);
         await blobClient.uploadData(obj1, options);
     }else{
      let threadList = [];
      let data = {
        userId: params.uId,
        threadId: params.tId
      }
      threadList.push(data);
      let finaldata = {
        threadList: [...threadList]
      }
     
      let obj2:any = JSON.stringify(finaldata);
      await blobClient.uploadData(obj2, options);
     }
};

const getChatThreadLists = async (params:any) => {
  try {
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient: ContainerClient = blobService.getContainerClient(container);
     const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/chatThreadsList.json`; 
     const blobClient = containerClient.getBlockBlobClient(blobPath);
     const downloadBlockBlobResponse = await blobClient.download();
     const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody);
    
     // [Browsers only] A helper method used to convert a browser Blob into string.
     async function blobToString(blob:any) {
       const fileReader = new FileReader();
       return new Promise((resolve, reject) => {
         fileReader.onloadend = (ev:any) => {
           resolve(ev.target.result);
         };
         fileReader.onerror = reject;
         fileReader.readAsText(blob);
       });
     }
     return {
      statusCode: 200,
      data: downloaded
     }
  } catch (error) {
    return {
      statusCode: 400,
      data: error
     }
  }
  



};

const connectionList = async (params: any) => {

  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(container);
  if(!containerClient.exists()){
    await containerClient.createIfNotExists({
      access: 'container',
    });
   }
   const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/connectionList.json`; 
   const blobClient = containerClient.getBlockBlobClient(blobPath);
    const options = { blobHTTPHeaders: { blobContentType: 'application/json' } };
    
   const blobClient1 = new BlobClient(connectionString, container, blobPath);
   
     if(await blobClient1.exists()){
      let response = await axios.get(`https://${storageAccountName}.blob.core.windows.net/${container}/${blobPath}`);
        let filedata = response.data;
         
         let newArray = filedata.connectionList;
         let newObj = {
          [params.email]:params.connectionId
         }
          newArray.push(newObj);
       
       let finaldataExt = {
        connectionList: [...newArray]
      }

         let obj1:any = JSON.stringify(finaldataExt);
         await blobClient.uploadData(obj1, options);
        /**Download data */
         const downloadBlockBlobResponse = await blobClient.download();
         const downloaded:any = await blobToString(await downloadBlockBlobResponse.blobBody);
         async function blobToString(blob:any) {
           const fileReader = new FileReader();
           return new Promise((resolve, reject) => {
             fileReader.onloadend = (ev:any) => {
               resolve(ev.target.result);
             };
             fileReader.onerror = reject;
             fileReader.readAsText(blob);
           });
         }
         return {
          statusCode: 200,
          data: JSON.parse(downloaded)
         }


     }else{
      let connectionList = [];
      let newObj = {
        [params.email]:params.connectionId
       }
       connectionList.push(newObj);
      let finaldata = {
        connectionList: [...connectionList]
      }
      let obj2:any = JSON.stringify(finaldata);
      await blobClient.uploadData(obj2, options);

      /**Download data */
      const downloadBlockBlobResponse = await blobClient.download();
      const downloaded:any = await blobToString(await downloadBlockBlobResponse.blobBody);
      async function blobToString(blob:any) {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
          fileReader.onloadend = (ev:any) => {
            resolve(ev.target.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsText(blob);
        });
      }
      return {
       statusCode: 200,
       data: JSON.parse(downloaded)
      }
     }
}

const getConnectionList = async (params: any) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(container);
  if(!containerClient.exists()){
    await containerClient.createIfNotExists({
      access: 'container',
    });
   }
   const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/connectionList.json`; 
   const blobClient = containerClient.getBlockBlobClient(blobPath);
   const downloadBlockBlobResponse = await blobClient.download();
   const downloaded:any = await blobToString(await downloadBlockBlobResponse.blobBody);
   async function blobToString(blob:any) {
     const fileReader = new FileReader();
     return new Promise((resolve, reject) => {
       fileReader.onloadend = (ev:any) => {
         resolve(ev.target.result);
       };
       fileReader.onerror = reject;
       fileReader.readAsText(blob);
     });
   }
   return {
    statusCode: 200,
    data: JSON.parse(downloaded)
   }
}

const saveUserData = async (params:any) => {
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(container);
  if(!containerClient.exists()){
    await containerClient.createIfNotExists({
      access: 'container',
    });
   }
   const blobPath = `${params.accountId}/gatheringjar/meetings/${params.gId}/usersInfo.json`; 
   const blobClient = containerClient.getBlockBlobClient(blobPath);
    const options = { blobHTTPHeaders: { blobContentType: 'application/json' } };
    
   const blobClient1 = new BlobClient(connectionString, container, blobPath);
   
     if(await blobClient1.exists()){
      let response = await axios.get(`https://${storageAccountName}.blob.core.windows.net/${container}/${blobPath}`);
        let filedata = response.data;
         
         let newArray = filedata.usersData;
         const found = newArray.some((el:any) => el.email == params.email);
      if(!found){
         let newObj = {
           userId: params.userId,
           email: params.email
         }
          newArray.push(newObj);
        }
       let finaldataExt = {
        usersData: [...newArray]
      }

         let obj1:any = JSON.stringify(finaldataExt);
         await blobClient.uploadData(obj1, options);
        /**Download data */
         const downloadBlockBlobResponse = await blobClient.download();
         const downloaded:any = await blobToString(await downloadBlockBlobResponse.blobBody);
         async function blobToString(blob:any) {
           const fileReader = new FileReader();
           return new Promise((resolve, reject) => {
             fileReader.onloadend = (ev:any) => {
               resolve(ev.target.result);
             };
             fileReader.onerror = reject;
             fileReader.readAsText(blob);
           });
         }
         return {
          statusCode: 200,
          data: JSON.parse(downloaded)
         }


     }else{
      let usersList = [];
      let newObj = {
           userId: params.userId,
           email: params.email
       }
       usersList.push(newObj);
      let finaldata = {
        usersData: [...usersList]
      }
      let obj2:any = JSON.stringify(finaldata);
      await blobClient.uploadData(obj2, options);

      /**Download data */
      const downloadBlockBlobResponse = await blobClient.download();
      const downloaded:any = await blobToString(await downloadBlockBlobResponse.blobBody);
      async function blobToString(blob:any) {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
          fileReader.onloadend = (ev:any) => {
            resolve(ev.target.result);
          };
          fileReader.onerror = reject;
          fileReader.readAsText(blob);
        });
      }
      return {
       statusCode: 200,
       data: JSON.parse(downloaded)
      }
     }
}

export { 
  uploadFileToBlob, 
  uploadProfileImage, 
  getProfileImage, 
  saveMeetingInfo, 
  uploadChatAttachment, 
  getAttachmentURL, 
  getAllAttachments, 
  saveEndMeetingInfo,
  saveLogInfo,
  getAllProfilePics,
  saveChatThreadsList,
  getChatThreadLists,
  connectionList,
  getConnectionList,
  saveUserData
};

