import React, { useEffect, useRef, useState } from 'react';
import { Image, ImageFit, Label } from '@fluentui/react';
import { LocalVideoStream, VideoStreamRenderer, VideoStreamRendererView } from '@azure/communication-calling';
import { videoHint, mediaContainer, localVideoContainerStyle } from './styles/StreamMedia.styles';
import { Constants } from '../core/constants';
import staticMediaSVG from '../assets/staticmedia.svg';
import { MicIcon, MicOffIcon, RaiseHandColoredIcon } from '@fluentui/react-icons-northstar';

export interface LocalStreamMediaProps {
  label: string;
  stream: LocalVideoStream;
  userInfo: any;
  isSpeaking: boolean;
  handleLocalMic(params:boolean):void;
  handRaised: boolean;
}

export default (props: LocalStreamMediaProps): JSX.Element => {
  const rendererViewRef = useRef<VideoStreamRendererView>();

  const [activeStreamBeingRendered, setActiveStreamBeingRendered] = useState(false);
  // let userpic='';
  // props.userInfo && props.userInfo.length && props.userInfo.map((list:any)=>{
  //   let img = list.split('@').pop();
  //    if(img.substring(0, img.lastIndexOf("."))== props.label ) userpic = list;
  //    return userpic;
  // })

  const imageProps = {
    src:  props.userInfo.length && props.userInfo[0]? props.userInfo[0]: staticMediaSVG.toString(),
    imageFit: ImageFit.contain,
    styles: {
      root: {
        width: '100%',
        height: '100%',
        display: activeStreamBeingRendered ? 'none' : 'block',
        border: props.isSpeaking ? '2px solid rgb(199 224 244)': ''
      }
    }
  };

  const { stream, label } = props;

  useEffect(() => {
    (async (): Promise<void> => {
      if (stream) {
        const renderer: VideoStreamRenderer = new VideoStreamRenderer(stream);
        rendererViewRef.current = await renderer.createView({ scalingMode: 'Crop', isMirrored: true });

        const container = document.getElementById(Constants.LOCAL_VIDEO_PREVIEW_ID);

        if (container && container.childElementCount === 0) {
          container.appendChild(rendererViewRef.current.target);
          setActiveStreamBeingRendered(true);
        }
      } else {
        if (rendererViewRef) {
          rendererViewRef.current?.dispose();
          setActiveStreamBeingRendered(false);
        }
      }
    })();

    return (): void => {
      if (rendererViewRef) {
        rendererViewRef.current?.dispose();
        rendererViewRef.current = undefined;
        setActiveStreamBeingRendered(false);
      }
    };
  }, [stream]);

  const handleMicChange = (mic:boolean) => {
    props.handleLocalMic(mic);
  }

  return (
    <div className={mediaContainer}>
      <div
        style={{ display: activeStreamBeingRendered ? 'block' : 'none' }}
        className={localVideoContainerStyle}
        id={Constants.LOCAL_VIDEO_PREVIEW_ID}
      />
     
      <Image {...imageProps} />
      {props.handRaised && <RaiseHandColoredIcon size="largest" style={{position:'absolute',top:'0',float:'left', left:'0'}}/>}
      <Label className={videoHint} style={{display:'flex'}}>{label}{props.isSpeaking ? <div onClick={()=>handleMicChange(true)}><MicIcon size="medium" style={{paddingLeft:'0.4rem',color:'chartreuse',cursor:'pointer'}} /></div> : <div onClick={()=>handleMicChange(false)}><MicOffIcon size="medium" style={{paddingLeft:'0.4rem',cursor:'pointer'}}/></div>} </Label>
    </div>
  );
};
