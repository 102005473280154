import { CallClient } from '@azure/communication-calling';

const SET_USERID = 'SET_USERID';
const SET_CALL_CLIENT = 'SET_CALL_CLIENT';
const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
const SET_TOKEN = 'SET_TOKEN';
const SET_MEETING_DATA = 'SET_MEETING_DATA';
const SET_WAITING_LIST = 'SET_WAITING_LIST';
const SET_CONNECTION_LIST = 'SET_CONNECTION_LIST';
const SET_LOCAL_USER = 'SET_LOCAL_USER';
const SET_ATTENDEE_DATA = 'SET_ATTENDEE_DATA';
const SET_COHOSTS = 'SET_COHOSTS';
const SET_WAITINGROOM_ENABLED = 'SET_WAITINGROOM_ENABLED';
const SET_IS_MEETING_LOCKED = 'SET_IS_MEETING_LOCKED';
const SET_MUTEUNMUTE_ALL = 'SET_MUTEUNMUTE_ALL';
const SET_RAISE_HAND = 'SET_RAISE_HAND';

interface SetUserIdAction {
  type: typeof SET_USERID;
  userId: string;
}

interface SetCallClient {
  type: typeof SET_CALL_CLIENT;
  callClient: CallClient;
}

interface SetDisplayNameAction {
  type: typeof SET_DISPLAY_NAME;
  displayName: string;
}

interface SetTokenAction {
  type: typeof SET_TOKEN;
  token: any;
}

interface SetMeetingDataAction {
  type: typeof SET_MEETING_DATA;
  meetingData: any;
}

interface SetWaitingListAction {
  type: typeof SET_WAITING_LIST;
  waitingList: [];
}

interface SetConnectionListAction {
  type: typeof SET_CONNECTION_LIST;
  connectionList: [];
}

interface SetLocalUserAction {
  type: typeof SET_LOCAL_USER;
  localwaitinguser: any;
}

interface SetAttendeeDataAction {
  type: typeof SET_ATTENDEE_DATA;
  attendeeData: [];
}

interface SetCoHostAction {
  type: typeof SET_COHOSTS;
  cohosts: [];
}

interface SetWaitingRoomEnableAction {
  type: typeof SET_WAITINGROOM_ENABLED;
  isWaitingRoomEnabled: boolean;
}

interface SetMeetingLockAction {
  type: typeof SET_IS_MEETING_LOCKED;
  isMeetingLocked: boolean;
}

interface SetMuteUnmuteAllAction {
  type: typeof SET_MUTEUNMUTE_ALL;
  ismicAll: boolean;
}

interface SetRaiseHandAction {
  type: typeof SET_RAISE_HAND;
  raiseHands: [];
}

export const setUserId = (userId: string): SetUserIdAction => {
  return {
    type: SET_USERID,
    userId
  };
};

export const setCallClient = (callClient: CallClient): SetCallClient => {
  return {
    type: SET_CALL_CLIENT,
    callClient
  };
};

export const setDisplayName = (displayName: string): SetDisplayNameAction => {
  return {
    type: SET_DISPLAY_NAME,
    displayName
  };
};

export const setToken = (token: any): SetTokenAction => {
  return {
    type: SET_TOKEN,
    token
  };
};

export const setMeetingData = (meetingData: any): SetMeetingDataAction => {
  return {
    type: SET_MEETING_DATA,
    meetingData
  };
};

export const setWaitingList = (waitingList: []): SetWaitingListAction => {
  return {
    type: SET_WAITING_LIST,
    waitingList
  };
};

export const setConnectionList = (connectionList: []): SetConnectionListAction => {
  return {
    type: SET_CONNECTION_LIST,
    connectionList
  };
};

export const setLocalWaitingUser = (localwaitinguser: any): SetLocalUserAction => {
  return {
    type: SET_LOCAL_USER,
    localwaitinguser
  };
};

export const setAttendeeDataList = (attendeeData: []): SetAttendeeDataAction => {
  return {
    type: SET_ATTENDEE_DATA,
    attendeeData
  };
};

export const setCoHostList = (cohosts: []): SetCoHostAction => {
  return {
    type: SET_COHOSTS,
    cohosts
  };
};

export const setIsWaitingRoomEnabled = (isWaitingRoomEnabled: boolean): SetWaitingRoomEnableAction => {
  return {
    type: SET_WAITINGROOM_ENABLED,
    isWaitingRoomEnabled
  };
};

export const setIsMeetingLocked = (isMeetingLocked: boolean): SetMeetingLockAction => {
  return {
    type: SET_IS_MEETING_LOCKED,
    isMeetingLocked
  };
};

export const setIsMuteUnuteAll = (ismicAll: boolean): SetMuteUnmuteAllAction => {
  return {
    type: SET_MUTEUNMUTE_ALL,
    ismicAll
  };
};

export const setRaiseHands = (raiseHands: []): SetRaiseHandAction => {
  return {
    type: SET_RAISE_HAND,
    raiseHands
  };
};

export { SET_USERID, SET_CALL_CLIENT, SET_DISPLAY_NAME, SET_TOKEN, SET_MEETING_DATA,  SET_WAITING_LIST, SET_CONNECTION_LIST, SET_LOCAL_USER, SET_ATTENDEE_DATA, SET_COHOSTS, SET_WAITINGROOM_ENABLED, SET_IS_MEETING_LOCKED, SET_MUTEUNMUTE_ALL, SET_RAISE_HAND };

export type SdkTypes = SetUserIdAction | SetCallClient | SetDisplayNameAction | SetTokenAction | SetMeetingDataAction | SetWaitingListAction | SetConnectionListAction | SetLocalUserAction | SetAttendeeDataAction | SetCoHostAction | SetWaitingRoomEnableAction | SetMeetingLockAction | SetMuteUnmuteAllAction | SetRaiseHandAction;
