import { connect } from 'react-redux';
import ConfigurationScreen, { ConfigurationScreenProps, TokenResponse } from '../components/Configuration';
import { addUserToThread, isValidThread } from '../core/sideEffects';
import { setCallAgent, setGroup } from '../core/actions/calls';
import { setThreadId } from '../core/actions/ThreadAction';
import { setVideoDeviceInfo, setAudioDeviceInfo, setAudioSpeakerDeviceInfo } from '../core/actions/devices';
import { initCallClient, joinGroup, registerToCallAgent, updateDevices } from '../core/sideEffects';
import { setMic } from '../core/actions/controls';
import { State } from '../core/reducers';
import {
  AudioDeviceInfo,
  VideoDeviceInfo,
  LocalVideoStream,
  CallAgent,
  CallEndReason
} from '@azure/communication-calling';
import { CommunicationUserToken } from '@azure/communication-identity';
import { utils } from '../Utils/Utils';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { setUserId, setMeetingData, setWaitingList, setConnectionList, setLocalWaitingUser, setCoHostList, setIsWaitingRoomEnabled, setIsMeetingLocked } from '../core/actions/sdk';


const mapStateToProps = (state: State, props: ConfigurationScreenProps) => ({
  deviceManager: state.devices.deviceManager,
  callAgent: state.calls.callAgent,
  group: state.calls.group,
  mic: state.controls.mic,
  screenWidth: props.screenWidth,
  audioDeviceInfo: state.devices.audioDeviceInfo,
  audioSpeakerDeviceInfo: state.devices.audioSpeakerDeviceInfo,
  videoDeviceInfo: state.devices.videoDeviceInfo,
  videoDeviceList: state.devices.videoDeviceList,
  audioDeviceList: state.devices.audioDeviceList,
  audioSpeakerDeviceList: state.devices.audioSpeakerDeviceList,
  cameraPermission: state.devices.cameraPermission,
  microphonePermission: state.devices.microphonePermission,
  waitingList: state.sdk.waitingList,
  connList: state.sdk.connectionList,
  isLocked: state.sdk.isMeetingLocked,
  iswaitingEnabled: state.sdk.isWaitingRoomEnabled,
  joinGroup: async (callAgent: CallAgent, groupId: string, localVideoStream: LocalVideoStream): Promise<void> => {
    callAgent &&
      (await joinGroup(
        callAgent,
        {
          groupId
        },
        {
          videoOptions: {
            localVideoStreams: localVideoStream ? [localVideoStream] : undefined
          },
          audioOptions: { muted: !state.controls.mic }
        }
      ));
  },
  getToken: async (): Promise<TokenResponse> => {
    //const urlParams = new URLSearchParams(window.location.search);
    const tokenResponse: CommunicationUserToken = await utils.getTokenForUser();
    const userToken = tokenResponse.token;
    const userId = tokenResponse.user.communicationUserId;

    const tokenCredential = new AzureCommunicationTokenCredential({
      tokenRefresher: (): Promise<string> => {
        return utils.getRefreshedTokenForUser(userId);
      },
      refreshProactively: true,
      token: userToken
    });

    return {
      tokenCredential,
      userId
    };
  },
  createCallAgent: async (
    tokenCredential: AzureCommunicationTokenCredential,
    displayName: string
  ): Promise<CallAgent> => {
    const callClient = state.sdk.callClient;

    if (callClient === undefined) {
      throw new Error('CallClient is not initialized');
    }

    const callAgent: CallAgent = await callClient.createCallAgent(tokenCredential, { displayName });
    return callAgent;
  }
});

const mapDispatchToProps = (dispatch: any) => ({
  setMic: (mic: boolean): void => dispatch(setMic(mic)),
  setAudioDeviceInfo: (deviceInfo: AudioDeviceInfo): void => dispatch(setAudioDeviceInfo(deviceInfo)),
  setAudioSpeakerDeviceInfo: (deviceInfo: AudioDeviceInfo): void => dispatch(setAudioSpeakerDeviceInfo(deviceInfo)),
  setVideoDeviceInfo: (deviceInfo: VideoDeviceInfo): void => dispatch(setVideoDeviceInfo(deviceInfo)),
  setupCallClient: (unsupportedStateHandler: () => void): void => dispatch(initCallClient(unsupportedStateHandler)),
  registerToCallEvents: async (
    userId: string,
    callAgent: CallAgent,
    endCallHandler: (reason: CallEndReason) => void
  ): Promise<void> => {
    dispatch(setUserId(userId));
    dispatch(setCallAgent(callAgent));
    dispatch(registerToCallAgent(userId, callAgent, endCallHandler));
  },
  setGroup: (groupId: string): void => dispatch(setGroup(groupId)),
  updateDevices: (): void => dispatch(updateDevices()),
  setup: async (displayName: string, userEmail: string, profileImage: File) => {
    dispatch(addUserToThread(displayName, userEmail, profileImage));
  },
  setThreadId:(threadId: string): void => dispatch(setThreadId(threadId)),
  isValidThread: async (threadId: string) => dispatch(isValidThread(threadId)),
  setMeetingInfo:(meetingData:any): void => dispatch(setMeetingData(meetingData)),
  setWaitingList:(list:any): void => dispatch(setWaitingList(list)),
  setConnectionList: (clist:any): void => dispatch(setConnectionList(clist)),
  setLocalWaitingUser: (user:any): void => dispatch(setLocalWaitingUser(user)),
  setCoHost: (data: any): void => dispatch(setCoHostList(data)),
  setIsWaitingEnabled: (isenabled: boolean): void => dispatch(setIsWaitingRoomEnabled(isenabled)),
  setIsMeetingLock: (islocked: boolean): void => dispatch(setIsMeetingLocked(islocked))
  
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(ConfigurationScreen);
