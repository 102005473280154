import React from 'react';
import { Stack, PrimaryButton, Icon, Image, IImageStyles } from '@fluentui/react';
import { VideoCameraEmphasisIcon } from '@fluentui/react-icons-northstar';
//import SingleChat from './SingleChat';
import heroSVG from '../assets/hero.svg';
import {
  imgStyle,
  containerTokens,
  listStyle,
  iconStyle,
  headerStyle,
  upperStackTokens,
  videoCameraIconStyle,
  buttonStyle,
  nestedStackTokens,
  upperStackStyle,
  listItemStyle
} from './styles/HomeScreen.styles';



export interface HomeScreenProps {
  startCallHandler(): void;
 // setcreateThread(): void;
 // creatThread(threadId: any):void;
}

const imageStyleProps: IImageStyles = {
  image: {
    height: '100%',
    width: '100%'
  },
  root: {}
};

export default (props: HomeScreenProps): JSX.Element => {
 
 
  const iconName = 'SkypeCircleCheck';
  const imageProps = { src: heroSVG.toString() };
  const headerTitle = 'Exceptionally simple video calling';
  const startCallButtonText = 'Start a call';
  const listItems = [
    'Customize with your web stack',
    'Connect with users with seamless collaboration across web',
    'High quality, low latency capabilities for an uninterrupted calling experience',
    'Learn about this'
  ];
  // const [threadId , setThreadId] = useState()
  // const { setcreateThread } = props;
  //const [isCreatingThread, setIsCreatingThread] = useState(false);

  // const getThreadIdFromUrl = (): string | null => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   return urlParams.get('threadId');
  // };
  
  // const getThreadId = () => {
  //   if (threadId) return threadId;
  //   const uriTid = getThreadIdFromUrl();
  //   let tid: any = uriTid == null || uriTid === '' || typeof uriTid !== 'string' ?   setcreateThread() : uriTid;
  //   setThreadId(tid);
   
  //   return tid;
  // };

const onstartCallHandler = () => {
  props.startCallHandler();
  //props.creatThread(getThreadId())
}





  // const creatThreadLoading = () => {
  //   return <Spinner label={spinnerLabel} ariaLive="assertive" labelPosition="top" />;
  // };
  return (
    <Stack horizontal horizontalAlign="center" verticalAlign="center" tokens={containerTokens}>
      <Stack className={upperStackStyle} tokens={upperStackTokens}>
        <div className={headerStyle}><img src="./images/logo.png" alt="Logo"/>{headerTitle}</div>
        <Stack tokens={nestedStackTokens}>
          <ul className={listStyle}>
            <li className={listItemStyle}>
              <Icon className={iconStyle} iconName={iconName} /> {listItems[0]}
            </li>
            <li className={listItemStyle}>
              <Icon className={iconStyle} iconName={iconName} /> {listItems[1]}
            </li>
            <li className={listItemStyle}>
              <Icon className={iconStyle} iconName={iconName} /> {listItems[2]}
            </li>
            {/* <li className={listItemStyle}>
              <Icon className={iconStyle} iconName={iconName} /> {listItems[3]}{' '}
              <a href="https://docs.microsoft.com/en-us/azure/communication-services/samples/calling-hero-sample?pivots=platform-web">
                sample
              </a>
            </li> */}
            
          </ul>
        </Stack>
     
        <PrimaryButton className={buttonStyle} onClick={()=>onstartCallHandler()} >
          <VideoCameraEmphasisIcon className={videoCameraIconStyle} size="medium" />
          {startCallButtonText}
        </PrimaryButton>
        {/* <div className="plg-donate" data-widget-id="Hw0VIaCKIoZWdUAZZ1Owqg"></div> */}
      </Stack>
      <Image
        alt="Welcome to the Azure Communication Services Calling app"
        className={imgStyle}
        styles={imageStyleProps}
        {...imageProps}
      />
     {/* <SingleChat chatno={1}/> */}
   
    </Stack>
  );
};
