import { connect } from 'react-redux';
import { State } from '../core/reducers';
import ParticipantStack from '../components/ParticipantStack';
import { individualParticipantMuteChange, saveIndividualChatThreads, setMicrophone } from '../core/sideEffects';
import { setNoOfChatItems, setWhomToChat } from '../core/actions/MessagesAction';
import { setIsMuteUnuteAll, setWaitingList } from 'core/actions/sdk';

const mapStateToProps = (state: State) => ({
  userId: state.sdk.userId,
  displayName: state.calls.callAgent?.displayName,
  call: state.calls.call,
  callState: state.calls.callState,
  remoteParticipants: state.calls.remoteParticipants,
  screenShareStreams: state.streams.screenShareStreams,
  user: state.contosoClient.user,
  groupId: state.calls.group,
  images: state.contosoClient.images,
  chatScreenItems: state.chat.items,
  settings: state.sdk.meetingData,
  waitingList: state.sdk.waitingList,
  connList: state.sdk.connectionList,
  localuser: state.sdk.localwaitinguser,
  attendeeData: state.sdk.attendeeData,
  mic: state.controls.mic,
  cohosts: state.sdk.cohosts,
  micAll: state.sdk.ismicAll,
  raisehands: state.sdk.raiseHands
  
});
const mapDispatchToProps = (dispatch: any) => ({
  setIndividualMuteChange: (participant: any, isMuted: boolean): void => dispatch(individualParticipantMuteChange(participant, isMuted)),
  setChatItems:(items: any): void => dispatch(setNoOfChatItems(items)),
  setChatThreadList: async (tId: any, uId: any) => {
    dispatch(saveIndividualChatThreads(tId,uId));
  },
  setWhomToChat: (whom: any) => dispatch(setWhomToChat(whom)),
  setWaitingList:(list:any): void => dispatch(setWaitingList(list)),
  setMic: (mic: boolean): void => dispatch(setMicrophone(mic)),
  setMicAll: (micall:boolean): void => dispatch(setIsMuteUnuteAll(micall))
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(ParticipantStack);
