import React from 'react';
import { Persona, PersonaSize, Stack, IconButton, PersonaPresence, FontIcon, OverflowSet, PrimaryButton, DefaultButton, Label } from '@fluentui/react';
import {
  itemStyles,
  participantStackStyle,
  participantStackTokens,
  overFlowButtonStyles,
  iconStyle,
  itemWaitingStyles
} from './styles/ParticipantStack.styles';
import { RemoteParticipant, Call } from '@azure/communication-calling';
import { ParticipantStream } from '../core/reducers';
import { utils } from '../Utils/Utils';
import { RaiseHandColoredIcon } from '@fluentui/react-icons-northstar';
//import { ChatIcon, MicIcon, MicOffIcon } from '@fluentui/react-icons-northstar';
//import { createIndividualThread } from 'core/sideEffects';


//import { getBlobsInContainer } from '../Utils/FileUpload';
//import { individualParticipantMuteChange } from '../core/sideEffects';

export interface ParticipantStackProps {
  userId: string;
  displayName: string;
  call: Call;
  callState: string;
  screenShareStreams: ParticipantStream[];
  remoteParticipants: RemoteParticipant[];
  user:any;
  groupId:string;
  images:any;
  setIndividualMuteChange(participant:any, isMuted: boolean): void;
  setChatItems(items: any): void;
  chatScreenItems: any;
  setChatThreadList(tId: any, uId: any): void;
  setWhomToChat(whom:any): void;
  settings: any;
  waitingList: any;
  socket: any;
  setWaitingList(list:any): void;
  connList: any;
  localuser: any;
  attendeeData: any;
  setMic(mic:boolean): void;
  mic: boolean;
  cohosts: any;
  setMicAll(micall:boolean):void;
  micAll:boolean;
  raisehands:any;
}
export interface CallParticipant {
  key: string;
  name: string;
  participant: RemoteParticipant | undefined;
  state: string;
  isScreenSharing: boolean;
  isSpeaking: boolean;
  userInfo:any;
  email: any;
  isCohost: boolean;
  isHandRaised: boolean;
}


export default (props: ParticipantStackProps): JSX.Element => {

  //const [usersmsg, setUsersMsg] = useState<any[]>([]);
  const { images } = props;
  const profileimages = [...new Set(images)];
  const mSettings = props.settings.MeetingSettings;
  const isIamCoHost = props.cohosts.some((el:any) => el == props.localuser.email);
  const isIamHost = mSettings.host[0] == props.localuser.email;
 
  //let muteunmuteAll = false;


const showSingleChat = async(item:any) => {
     props.setWhomToChat({val:item.name,key:item.key,text:item.name});
}

const setAdmitUser = (udata:any, type:any) => {
  const pendingwaitlist = props.waitingList.length? props.waitingList.filter( (i:any) => i.email != udata.email):[];
  props.setWaitingList(pendingwaitlist);
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: udata,
      type: type
    }
  }))
}

const admitAll = () => {
  props.setWaitingList([]);
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: {},
      type: "admitall"
    }
  }))
}

const makeCoHost = (cdata:any) => {
 
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: cdata,
      type: "makecohost"
    }
  }))

}

const removeCoHost = (chost:any)=>{
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: chost,
      type: "removecohost"
    }
  }))
}



const muteonoff = () => {
  props.setMic(!props.mic)
}

const handleRemoteMicChange = (uid:any, mice:boolean) => {
  const data = {userId:uid, mic:mice};
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: data,
      type: "remotemuteunmute"
    }
  }))
}

const unmuteAll = (mik:boolean) => {
  props.setMicAll(true);
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: {mic:mik},
      type: "unmuteAll"
    }
  }))
}

const muteAll = (mik:boolean) => {
  props.setMicAll(false);
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: {mic:mik},
      type: "muteAll"
    }
  }))

}

  const onRenderItem = (item: any): JSX.Element => (
    <>
    
      <Persona
        text={item.name}
        styles={itemStyles}
        size={PersonaSize.size32}
        imageUrl={item.userInfo}
        presence={item.state === 'Connected' ? PersonaPresence.online : PersonaPresence.offline}
      />
    
      {item.isHandRaised && <RaiseHandColoredIcon />}
      {item.isScreenSharing && <FontIcon className={iconStyle} iconName="ScreenCast" />}
      
    </>
  );

  const onRenderWaitingItem = (item: any): JSX.Element => (
    <>
   
      <Persona
        text={item.name}
        styles={itemWaitingStyles}
        size={PersonaSize.size32}
        //imageUrl={item.userInfo}
        //presence={item.state === 'Connected' ? PersonaPresence.online : PersonaPresence.offline}
      />
     
     <PrimaryButton style={{minWidth:'40px',height:'24px',borderRadius:'10px',fontSize:'10px',padding:'0 4px', margin:'5px 5px 0 0'}} onClick={()=>setAdmitUser(item,'admitteduser')}>Admit</PrimaryButton>
     <DefaultButton style={{minWidth:'40px',height:'24px',borderRadius:'10px',fontSize:'10px',padding:'0 4px', margin:'5px 0 0 0'}} onClick={()=>setAdmitUser(item,'removeduser')}>Remove</DefaultButton>
    </>
  );
  const onRenderOverflowButton = (overflowItems: any): JSX.Element => (
    <IconButton
      role="menuitem"
      title="More options"
      styles={overFlowButtonStyles}
      menuIconProps={{ iconName: 'More' }}
      menuProps={{ items: overflowItems }}
    />
  );
  const getParticipants = (participants: CallParticipant[]): JSX.Element[] =>
    participants.map((item, i) => (
 
      <OverflowSet
        key={i}
        items={[item]}
        role="menubar"
        vertical={false}
        overflowItems={
          props.userId == item.key
              ? [{
                key: "item1",
                name: item.isSpeaking ? "Mute" : "Unmute",
                onClick: muteonoff,
              }]
          :(!isIamHost && !isIamCoHost) &&
            props.userId != item.key
              ? [
                  {
                    key: "item2",
                    name: "Send Message",
                    onClick: () => showSingleChat(item),
                  }
                ]
          :(!isIamHost && isIamCoHost) &&
            props.userId != item.key
          ?[
            {
              key: "item3",
              name: item.isSpeaking ? "Mute" : "Unmute",
              onClick: item.isSpeaking
                      ? () => handleRemoteMicChange(item.key, true)
                      : () => handleRemoteMicChange(item.key, false),
            },
            {
              key: "item4",
              name: "Send Message",
              onClick: () => showSingleChat(item),
            }
          ]      
          : [
                  {
                    key: "item5",
                    name: item.isSpeaking ? "Mute" : "Unmute",
                    onClick: item.isSpeaking
                      ? () => handleRemoteMicChange(item.key, true)
                      : () => handleRemoteMicChange(item.key, false),
                  },
                  {
                    key: "item6",
                    name: "Send Message",
                    onClick: () => showSingleChat(item),
                  },
                  {
                    key: "item7",
                    name: item.isCohost ? "Remove as co-host" : "Make as co-host",
                    onClick: item.isCohost
                      ? () => removeCoHost(item.email)
                      : () => makeCoHost(item.email),
                  }
                ]
          }
          
        onRenderOverflowButton={onRenderOverflowButton}
        onRenderItem={onRenderItem}
      />
    ));

    const getWaitingParticipants = (participants: CallParticipant[]): JSX.Element[] =>
    participants.map((item, i) => (
     
      <OverflowSet
        key={i}
        items={[item]}
        role="menubar"
        vertical={false}
        onRenderOverflowButton={onRenderOverflowButton}
        onRenderItem={onRenderWaitingItem}
      />
     
    ));
  
  const activeScreenShareStream = props.screenShareStreams && props.screenShareStreams.length === 1;
  const screenShareStream = props.screenShareStreams[0];
  const participants: CallParticipant[] = props.remoteParticipants.map((participant,index) => {
    const isScreenSharing = activeScreenShareStream ? screenShareStream.user === participant : false;
    const userspic =  profileimages && profileimages.length && profileimages.filter((l:any)=>{
     // let img = l.split('@').pop();
     let uname = l.split("/").pop().split("%23").pop().replace('.jpg','');
      if(uname == participant.displayName ) return l;
    });

    const foundobj = props.attendeeData.find((o:any) => o.userId == utils.getId(participant.identifier));
  
    return {
      key: utils.getId(participant.identifier),
      name: participant.displayName ?? utils.getId(participant.identifier),
      participant: participant,
      state: participant.state,
      isScreenSharing: isScreenSharing,
      isSpeaking: participant.isSpeaking,
      userInfo: userspic,
      email: foundobj && foundobj.email,
      isCohost: foundobj && props.cohosts.some((el:any) => el == foundobj.email),
      isHandRaised:props.raisehands.some((el:any) => el == utils.getId(participant.identifier))
    };
  });
  const userspersonalpic =  profileimages && profileimages.length && profileimages.filter((l:any)=>{
    //let img = l.split('@').pop();
    let uname = l.split("/").pop().split("%23").pop().replace('.jpg','');
    if(uname == props.displayName ) return l[0];
  });

  const foundobj = props.attendeeData.find((o:any) => o.userId == props.userId);

  participants.push({
    key: `${props.userId}`,
    name: `${props.displayName} (You)`,
    participant: undefined,
    state: 'Connected',
    isScreenSharing: activeScreenShareStream ? utils.getId(screenShareStream.user.identifier) === props.userId : false,
    isSpeaking: !props.call.isMuted,
    userInfo: userspersonalpic,
    email: foundobj && foundobj.email,
    isCohost: foundobj && props.cohosts.some((el:any) => el == foundobj.email),
    isHandRaised:props.raisehands.some((el:any) => el == props.userId)
  });
  return (
    <Stack className={participantStackStyle} tokens={participantStackTokens}>
      {props.waitingList.length && (isIamHost || isIamCoHost) && (
      <>
      <div style={{width:'100%',display:'flex'}}>
        <Label style={{fontSize:'16px', width:'70%'}}>{props.waitingList.length} - Waiting</Label> <PrimaryButton style={{minWidth:'40px',height:'24px',borderRadius:'10px',fontSize:'10px',padding:'0 4px', margin:'5px 5px 0 0'}} onClick={admitAll}>Admit All</PrimaryButton></div>
       <div style={{maxHeight:'8rem', overflowY:'auto'}}> 
      {getWaitingParticipants(props.waitingList)}
      </div>
      </>
     )}
     <div style={{width:'100%',display:'flex'}}>
      <Label style={{fontSize:'18px', width:'70%'}}>Participants</Label> 
      {(isIamHost || isIamCoHost) && (
       <>
   
      {!props.micAll ? <PrimaryButton style={{minWidth:'40px',height:'24px',borderRadius:'10px',fontSize:'10px',padding:'0 4px', margin:'5px 5px 0 0'}} onClick={()=>unmuteAll(false)}>Unmute All</PrimaryButton> :
      <PrimaryButton style={{minWidth:'40px',height:'24px',borderRadius:'10px',fontSize:'10px',padding:'0 4px', margin:'5px 5px 0 0'}} onClick={()=>muteAll(true)}>Mute All</PrimaryButton>}
      </>
      )}
      </div>
      
       {getParticipants(participants)}
       
    </Stack>
  );
};
