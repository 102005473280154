import React from 'react';
import {  Button } from '@fluentui/react-northstar';

export interface ErrorFallbackProps {
    errorMessage: string;
    
  }
export default (props: ErrorFallbackProps): JSX.Element => {
  
    const onErrorHome = () => {
        window.location.href = window.location.href.split('?')[0];
    }
    return (
     <>
      {/* <ItemLayout content={props.errorMessage} /> */}
      <h2>Oops!</h2><br/>
      {/* <div>{props.errorMessage}</div> */}
      <div>Something went wrong. Please try again.</div><br/>
      <Button content="Go Home" primary onClick={onErrorHome}/>
     </>
    );
  };