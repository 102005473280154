import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  mediaGalleryGridStyle,
  mediaGalleryStyle,
  mediaGallerySubstageStyle,
  substageMediaGalleryStyle
} from './styles/MediaGallery.styles';
import { RemoteParticipant, LocalVideoStream, CallAgent } from '@azure/communication-calling';
import { utils } from '../Utils/Utils';
import LocalStreamMedia from './LocalStreamMedia';
import RemoteStreamMedia from './RemoteStreamMedia';
import { SelectionState } from '../core/RemoteStreamSelector';
import { Stack } from '@fluentui/react';
import { Constants } from '../core/constants';
import { SpeakerPersonIcon } from '@fluentui/react-icons-northstar';
import { getAllProfilePics, saveUserData } from '../Utils/FileUpload';
//import { getBlobsInContainer } from '../Utils/FileUpload';
//import axios from 'axios';

export interface MediaGalleryProps {
  userId: string;
  displayName: string;
  remoteParticipants: RemoteParticipant[];
  localVideoStream: LocalVideoStream;
  dominantParticipants: SelectionState[];
  callAgent: CallAgent;
  user: any;
  images: any;
  groupId: string;
  setProfileImage(images: any): void;
  accountId: any;
  mic: boolean;
  local: any;
  attendeesData: any;
  setAttendeeData(data:any): void;
  cohosts:any;
  settings:any;
  socket:any;
  connList:any;
  setMic(mic:boolean):void;
  handlelocalmic():void;
  raisehands:any;
}

export default (props: MediaGalleryProps): JSX.Element => {
  const [gridCol, setGridCol] = useState(1);
  const [gridRow, setGridRow] = useState(1);
  const [usersimage, setUsersImage] = useState<any>([]);

  const mSettings = props.settings.MeetingSettings;
 // const {  images } = props;
const getUserInfo = useCallback(async() => {

  //if(props.local && props.local.email){
    const userdata = await saveUserData({
      gId: props.groupId,
      accountId: props.accountId,
      userId: props.userId,
      email: props.user.userEmail
    });
    props.setAttendeeData(userdata.data.usersData);
 // }
  },[])

 useEffect(() =>{
  getUserInfo();
 },[props.remoteParticipants.length])

  useEffect(() => {
    //    const timer = setTimeout(() => {
    //   getBlobsInContainer(props.groupId).then((list:any)=>{
    //    // console.log("Sub== useeff", list);
    //     if(list && list.length) {
    //       setUsersImage(list);
          
    //     }
    //   });
    // }, 1000);
 
    // return () => clearTimeout(timer);
     //let remoteLen = props.remoteParticipants.length + 1;
    // console.log("Sub== Rlen",remoteLen)
    //let allimgLength = 0;
    // if(props.images.length !== usersimage.length){
  //   console.log("Sub== PropsImgs",props.images)
  //   let profilebodyreq = {
  //     httpMethod: 'POST',
  //     path: '/getallprofileimages',
  //     body: {
  //       groupId: props.groupId
  //     }
  //   };
  //  axios.post(`https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/getallprofileimages`,profilebodyreq)
  //   .then((res)=>{
  //     console.log("Sub== AllProfilImg", res);
  //      props.setProfileImage([...new Set(res.data.body)]);
  //      setUsersImage(res.data.body);
  //   });
  //}
  getAllProfilePics({
    accountId: props.accountId,
    gId: props.groupId
  }).then((res)=>{

        props.setProfileImage([...new Set(res)]);
        setUsersImage(res);
  });
 
  }, [props.remoteParticipants.length, props.images.length])
  
const onHandleCohost = (data:any, type:any) => {

 if(data){
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: data.email,
      type: type
    }
  }))
 }
}

const localMicChange = (params:boolean): void =>{
   props.setMic(!params);
}

const onRemoteMicChange = (uid:any, mic:boolean) => {

  const data = {userId:uid, mic:mic};
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: data,
      type: "remotemuteunmute"
    }
  }))
}
  // For now we are only going to support up to a 4x3 grid or 10 participants in a call
  // Since this is a sample, we will just hard-code how we want the grid to scale
  // the rows and columns for the number of users in the call

   const rows = [1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7,
    7, 7, 7, 8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
    11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 13, 13, 13, 13, 13, 13, 13, 13];
   const cols = [1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7,
    7, 7, 7, 8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
    11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
    12, 12, 13, 13, 13, 13, 13, 13, 13, 13];

  // const rows = Constants.ROWS;
  // const cols = Constants.COLS;
  
  // const dominantParticipantCount = utils.isSafari()
  //   ? Constants.DOMINANT_PARTICIPANTS_COUNT_SAFARI
  //   : Constants.DOMINANT_PARTICIPANTS_COUNT;

    const dominantParticipantCount =  Constants.DOMINANT_PARTICIPANTS_COUNT;  

  if (dominantParticipantCount < 0 || dominantParticipantCount > rows.length - 1) {
    console.warn(`Please use a value for dominant participants between 0 <= x <= ${rows.length - 1}`);
  }

  const numRemoteParticipantsToRender = Math.min(dominantParticipantCount, rows.length - 1);

  const clamp = (num: number, min: number, max: number): number => Math.min(Math.max(num, min), max);

  const getMediaGalleryTilesForParticipants = (
    participants: RemoteParticipant[],
    displayName: string
  ): JSX.Element[] => {
    const remoteParticipantsMediaGalleryItems = participants.map((participant) => (
      <div key={`${utils.getId(participant.identifier)}-tile`} className={mediaGalleryStyle}>
        <RemoteStreamMedia
          key={utils.getId(participant.identifier)}
          stream={participant.videoStreams[0]}
          isParticipantStreamSelected={
            props.dominantParticipants.filter((p) => p.participantId === utils.getId(participant.identifier)).length > 0
          }
          label={participant.displayName ?? utils.getId(participant.identifier)}
          isSpeaking={participant.isSpeaking}
          userInfo={
            usersimage && usersimage.length && usersimage.filter((l:any)=>{
              //let img = l.split('@').pop();
              let uname = l.split("/").pop().split("%23").pop().replace('.jpg','');
              if(uname == participant.displayName ) return l;
            })
           }
          attData={props.attendeesData} 
          cohosts={props.cohosts}
          isHost={mSettings.host[0] == props.local.email ? true: false}
          onHandleCohost={onHandleCohost}
          uId={utils.getId(participant.identifier)}
          isMainCohost={props.cohosts.some((el:any) => el == props.local.email)}
          handleRemoteMicChange={onRemoteMicChange}
          handRaised={props.raisehands.some((el:any) => el == utils.getId(participant.identifier))}
        />
      </div>
    ));

    // for now we will always add the local user to the main stage
    const localParticipantMediaGalleryItem = (
      <div key="localParticipantTile" className={mediaGalleryStyle}>
       
        <LocalStreamMedia label={displayName} stream={props.localVideoStream} isSpeaking={props.mic} userInfo={usersimage && usersimage.length && usersimage.filter((l:any)=>{
              //let img = l.split('@').pop();
              let uname = l.split("/").pop().split("%23").pop().replace('.jpg','');
              if(uname == displayName ) return l;
            })} 
            handleLocalMic={localMicChange}
            handRaised={props.raisehands.some((el:any) => el == props.userId)}
            />
      </div>
    );

    remoteParticipantsMediaGalleryItems.unshift(localParticipantMediaGalleryItem);

    return remoteParticipantsMediaGalleryItems;
  };

  // const getSubstageMediaGalleryTilesForParticipants = (participants: RemoteParticipant[]): JSX.Element[] => {
  //   const remoteParticipantsMediaGalleryItems = participants.map((participant) => (
  //     <div key={`${utils.getId(participant.identifier)}-tile`} className={substageMediaGalleryStyle}>
  //       <RemoteStreamMedia
  //         key={utils.getId(participant.identifier)}
  //         stream={participant.videoStreams[0]}
  //         isParticipantStreamSelected={false}
  //         label={participant.displayName ?? utils.getId(participant.identifier)}
  //       />
  //     </div>
  //   ));

  //   return remoteParticipantsMediaGalleryItems;
  // };

  // determine number of rows/columns to add to the grid
  const numberStreamsToRender = useMemo(
    () => clamp(props.remoteParticipants.length, 0, numRemoteParticipantsToRender),
    [numRemoteParticipantsToRender, props.remoteParticipants.length]
  );
  if (cols[numberStreamsToRender] !== gridCol) {
    if (numberStreamsToRender > cols.length - 1) {
      throw new Error(
        `attempting to set up a number of columns in the gallery for an unexpected number of participants ${numberStreamsToRender}`
      );
    }
    setGridCol(cols[numberStreamsToRender]);
  }

  if (rows[numberStreamsToRender] !== gridRow) {
    if (numberStreamsToRender > rows.length - 1) {
      throw new Error(
        `attempting to set up a number of rows in the gallery for an expected number of participants ${numberStreamsToRender}`
      );
    }
    setGridRow(rows[numberStreamsToRender]);
  }

  // sort by dominance
  const participantsToLayout = props.remoteParticipants.sort((a, b) => {
    const isParticipantADominant =
      props.dominantParticipants.filter((p) => p.participantId === utils.getId(a.identifier)).length > 0;
    const isParticipantBDominant =
      props.dominantParticipants.filter((p) => p.participantId === utils.getId(b.identifier)).length > 0;
    if (isParticipantADominant && !isParticipantBDominant) {
      return -1;
    } else if (!isParticipantADominant && isParticipantBDominant) {
      return 1;
    }
    return 0;
  });
  //const extrausers = "extrausers";
  const mainStageParticipants = participantsToLayout.slice(0, numRemoteParticipantsToRender);
  const substageParticipants = participantsToLayout.slice(numRemoteParticipantsToRender);
  // don't show the substage if its not necessary
  const isSubstageVisible = substageParticipants.length > 0;
  //console.log("AV== img",usersimage, props.displayName)
  return (
    <Stack style={{ height: '100%' }}>
      <div
        id="video-gallery"
        className={mediaGalleryGridStyle}
        style={{
          gridTemplateRows: `repeat(${gridRow}, minmax(0, 1fr))`,
          gridTemplateColumns: `repeat(${gridCol}, 1fr)`
        }}
      >
        {getMediaGalleryTilesForParticipants(mainStageParticipants, props.displayName)}
      </div>
      
      {isSubstageVisible && (
        <Stack horizontal className={mediaGallerySubstageStyle}>
          <div className={"extrausers"+" "+substageMediaGalleryStyle}>
             <SpeakerPersonIcon outline size='largest'/>
              <span className='users-count'>+ {substageParticipants.length}</span>
          </div>
          {/* {getSubstageMediaGalleryTilesForParticipants(substageParticipants)} */}
        </Stack>
      )}
    </Stack>
  );
};
