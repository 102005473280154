import { ChatParticipant } from '@azure/communication-chat';
import { ExtendedChatMessage } from '../reducers/MessagesReducer';

export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_TYPING_NOTIFICATIONS = 'SET_TYPING_NOTIFICATIONS';
export const SET_TYPING_USERS = 'SET_TYPING_USERS';
export const SET_FAILED_MESSAGES = 'SET_FAILED_MESSAGES';
export const SET_NO_OF_CHATBOX = 'SET_NO_OF_CHATBOX';
export const SET_INDIVIDUAL_MESSAGES = 'SET_INDIVIDUAL_MESSAGES';
export const SET_WHOM_TO_CHAT = 'SET_WHOM_TO_CHAT';

export interface SetMessagesAction {
  type: typeof SET_MESSAGES;
  messages: ExtendedChatMessage[];
}

export type TypingNotification = {
  recipientId: string; // the user that is sending a message
  originalArrivalTime: Date; // when the message was last sent
  threadId: string;
  version: string;
};

export interface SetTypingNotificationsAction {
  type: typeof SET_TYPING_NOTIFICATIONS;
  id: string;
  typingNotification: TypingNotification;
}

export interface SetTypingUsersAction {
  type: typeof SET_TYPING_USERS;
  typingUsers: ChatParticipant[];
}

export interface SetFailedMessagesAction {
  type: typeof SET_FAILED_MESSAGES;
  failedMessages: string[];
}

export interface SetNoOfChatBoxAction {
  type: typeof SET_NO_OF_CHATBOX;
  items: any;
}

export interface SetIndividulMessagesAction {
  type: typeof SET_INDIVIDUAL_MESSAGES;
  individualmessages: any;
}

export interface SetWhomToChatAction {
  type: typeof SET_WHOM_TO_CHAT;
  whom: any;
}

export const setMessages = (messages: ExtendedChatMessage[]): SetMessagesAction => ({
  type: SET_MESSAGES,
  messages
});

export const setTypingNotifications = (
  id: string,
  typingNotification: TypingNotification
): SetTypingNotificationsAction => ({
  type: SET_TYPING_NOTIFICATIONS,
  id,
  typingNotification
});

export const setTypingUsers = (typingUsers: ChatParticipant[]): SetTypingUsersAction => ({
  type: SET_TYPING_USERS,
  typingUsers
});

export const setFailedMessages = (failedMessages: string[]): SetFailedMessagesAction => ({
  type: SET_FAILED_MESSAGES,
  failedMessages
});

export const setNoOfChatItems = (items: any): SetNoOfChatBoxAction => ({
  type: SET_NO_OF_CHATBOX,
  items
});

export const setIndividualMessages = (individualmessages: any): SetIndividulMessagesAction => ({
  type: SET_INDIVIDUAL_MESSAGES,
  individualmessages
});

export const setWhomToChat = (whom: any): SetWhomToChatAction => ({
  type: SET_WHOM_TO_CHAT,
  whom
});

export type MessagesActionTypes =
  | SetMessagesAction
  | SetTypingNotificationsAction
  | SetTypingUsersAction
  | SetFailedMessagesAction
  | SetNoOfChatBoxAction
  | SetIndividulMessagesAction
  | SetWhomToChatAction;
