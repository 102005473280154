export class Constants {
  static INCOMING = 'Incoming';
  static RINGING = 'Ringing';
  static CONNECTING = 'Connecting';
  static CONNECTED = 'Connected';
  static DEFAULT_IMG_WIDTH = 200;
  static CROP_MEDIA = 'Crop';
  static CONFIGURATION_LOCAL_VIDEO_PREVIEW_ID = 'ConfigurationLocalVideoPreview';
  static LOCAL_VIDEO_PREVIEW_ID = 'LocalVideoPreview';
  static MINI_HEADER_WINDOW_WIDTH = 360;

  static TAB_HEADER_WINDOW_WIDTH = 768;
  // For safari browsers we want to set the default to 1 remote participant that can show their video based on quality constraints
  static DOMINANT_PARTICIPANTS_COUNT_SAFARI = 3;
  // For non-safari browsers we want to set the default to 4 remote participant that can show their video based on quality constraints
  static DOMINANT_PARTICIPANTS_COUNT = 11;

  static GUID_FOR_INITIAL_TOPIC_NAME = 'GatheringJar';

  // Project configurations
  
  static INITIAL_MESSAGES_SIZE = 2000;
  static MAXIMUM_LENGTH_OF_NAME = 10;
  static MAXIMUM_LENGTH_OF_MESSAGE = 8000;
  static MAXIMUM_LENGTH_OF_TOPIC = 30;
  static MAXIMUM_LENGTH_OF_TYPING_USERS = 35;
  static MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS = 8000;
  static MAXIMUM_INT64 = 9223372036854775807;
  static NUMBER_OF_MESSAGES_TO_LOAD = 10;
  static PAGE_SIZE = 200;
  static PARTICIPANTS_THRESHOLD = 50;

  // Keyboard keys
  static ENTER_KEY = 13;
  static SPACE_KEY = 32;

  // Http Status Code
  static OK = 200;
// 250 grid layout
  static ROWS = [1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7,
                 7, 7, 7, 8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
                 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
                 12, 12, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 14, 14, 14, 14, 14,
                 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
                 15, 15, 15, 15, 15, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
                 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 
                 18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19,
                 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 
                 21, 21, 21, 21, 21, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22];
  static COLS = [1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7,
                7, 7, 7, 8, 8, 8, 8, 8, 8, 8, 9, 9, 9, 9, 9, 9, 9, 9, 9, 9, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10,
                11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 11, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12, 12,
                12, 12, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 13, 14, 14, 14, 14, 14,
                14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
                15, 15, 15, 15, 15, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16,
                17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 17, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 
                18, 18, 18, 18, 18, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19, 19,
                20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 
                21, 21, 21, 21, 21, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22];



  // Regex
  static URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  static EMPTY_MESSAGE_REGEX = /^\s*$/;
  static SALT_KEY= "CnPgatheringjar!@0047";
  static DONATE_KEYS = ["cnp","CnP","C&P","c&p","candp","CandP","clickandpledge","ClickandPledge","donate","Donate","DONATE"];
  // static S3_CONFIG={
  //   bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  //   region: process.env.REACT_APP_S3_REGION,
  //   accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  //   secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
  // };
  static BucketName = process.env.REACT_APP_S3_BUCKET_NAME;
}
export const COOL_PERIOD_THRESHOLD = 60 * 1000;
export const OK = 200;
export const CREATED = 201;
export const MULTI_STATUS = 207;
export const PRECONDITION_FAILED_STATUS_CODE = 412;
export const TOO_MANY_REQUESTS_STATUS_CODE = 429;
export const MAXIMUM_RETRY_COUNT = 3;
export const ORG_ACCOUNT_ID = 14521;
export const DOWNLOAD_SUB_URL = "https://aws.cause.clickandpledge.dev/accounts/14521/gatheringjar/";

