import {
  AudioDeviceInfo,
  Call,
  CommunicationServicesError,
  GroupCallLocator,
  JoinCallOptions,
  DeviceManager,
  DeviceAccess,
  RemoteParticipant,
  VideoDeviceInfo,
  CallAgent,
  CallClient,
  HangUpOptions,
  CallEndReason
} from '@azure/communication-calling';
import { 
  CommunicationUserKind, 
  AzureCommunicationTokenCredential,
  CommunicationTokenRefreshOptions,
  CommunicationUserIdentifier } from '@azure/communication-common';
  import {
    ChatClient,
    ChatThreadClient,
    SendReadReceiptRequest,
    ChatMessageReadReceipt,
    ChatMessage,
    ChatParticipant
  } from '@azure/communication-chat';
  import {
    ChatThreadPropertiesUpdatedEvent,
    ParticipantsAddedEvent,
    ParticipantsRemovedEvent
  } from '@azure/communication-signaling';
import { Dispatch } from 'redux';
import { 
  utils,
  compareMessages,
  //convertToClientChatMessage,
  createNewClientChatMessage,
  isUserMatchingIdentity 
} from '../Utils/Utils';

import { callAdded, callRemoved, setCallState, setParticipants, setCallAgent } from './actions/calls';
import { setMic, setShareScreen } from './actions/controls';
import {
  setAudioDeviceInfo,
  setAudioSpeakerDeviceInfo,
  setAudioDeviceList,
  setAudioSpeakerDeviceList,
  setCameraPermission,
  setMicrophonePermission,
  setVideoDeviceInfo,
  setVideoDeviceList,
  setDeviceManager
} from './actions/devices';
import { addScreenShareStream, removeScreenShareStream } from './actions/streams';
import { State } from './reducers';
import { setLogLevel } from '@azure/logger';
import RemoteStreamSelector from './RemoteStreamSelector';
import { Constants, TOO_MANY_REQUESTS_STATUS_CODE, OK } from './constants';
import { setCallClient, setToken, setUserId } from './actions/sdk';
import { setChatClient, setContosoUser, setContosoUsers, setContosoUsersImage, setIndividualChatClients } from './actions/ContosoClientAction';
import { setReceipts } from './actions/ConversationsAction';
import { setMessages, setTypingNotifications, setTypingUsers, setFailedMessages, setIndividualMessages } from './actions/MessagesAction';
import { setThreadId, setThreadTopicName } from './actions/ThreadAction';
import { setThreadMembers, setThreadMembersError, setRemovedFromThread } from './actions/ThreadMembersAction';
import { User } from './reducers/ContosoClientReducers';
import {  ExtendedChatMessage } from './reducers/MessagesReducer';
import { addFiles, setFileBlobUrl } from './actions/FilesAction';
import axios from 'axios';
import { uploadChatAttachment, getAttachmentURL, getAllAttachments, getAllProfilePics, saveChatThreadsList, getChatThreadLists } from '../Utils/FileUpload';
//import uploadFileToBlob from '../Utils/FileUpload';
//import AES from 'crypto-js/aes';

interface FileEventMessageContent {
  event: 'FileUpload',
  fileId: string,
  fileName: string,
}

// Helper function to try parsing a text chat message as a special File Event message
const parseFileEventMessageContent = (messageContent: string): FileEventMessageContent | null => {
  try {
    const parsedEvent = JSON.parse(messageContent);
    if (parsedEvent
      && typeof parsedEvent === 'object'
      && parsedEvent['event'] === 'FileUpload'
      && typeof parsedEvent['fileId'] === 'string'
      && typeof parsedEvent['fileName'] === 'string') {
        return parsedEvent as FileEventMessageContent;
    }
    
    return null;
  } catch (e) {
    return null;
  }
};

export const setMicrophone = (mic: boolean) => {
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    const state = getState();

    if (state === undefined || state.calls.call === undefined) {
      console.error('state or state.controls.mic is null');
      return;
    }

    try {
      if (!state.controls.mic) {
        await state.calls.call.unmute();
      } else {
        await state.calls.call.mute();
      }

      dispatch(setMic(mic));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setShareUnshareScreen = (shareScreen: boolean) => {
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    const state = getState();

    if (state === undefined || state.calls.call === undefined) {
      console.error('state or state.controls.shareScreen is null');
      return;
    }

    try {
      if (!state.controls.shareScreen) {
        await state.calls.call.startScreenSharing();
      } else {
        await state.calls.call.stopScreenSharing();
      }

      dispatch(setShareScreen(shareScreen));
    } catch (e) {
      console.error(e);
    }
  };
};

const subscribeToParticipant = (participant: RemoteParticipant, call: Call, dispatch: Dispatch): void => {
  // const dominantParticipantCount = utils.isSafari()
  //   ? Constants.DOMINANT_PARTICIPANTS_COUNT_SAFARI
  //   : Constants.DOMINANT_PARTICIPANTS_COUNT;

    const dominantParticipantCount = Constants.DOMINANT_PARTICIPANTS_COUNT;
  const remoteStreamSelector = RemoteStreamSelector.getInstance(dominantParticipantCount, dispatch);

  participant.on('stateChanged', () => {
    remoteStreamSelector.participantStateChanged(
      utils.getId(participant.identifier),
      participant.displayName ?? '',
      participant.state,
      !participant.isMuted,
      participant.videoStreams[0].isAvailable
    );
    dispatch(setParticipants([...call.remoteParticipants.values()]));
  });

  participant.on('isMutedChanged', () => {
    remoteStreamSelector.participantAudioChanged(utils.getId(participant.identifier), !participant.isMuted);
  });

  participant.on('isSpeakingChanged', () => {
    dispatch(setParticipants([...call.remoteParticipants.values()]));
  });

  participant.on('videoStreamsUpdated', (e): void => {
    e.added.forEach((addedStream) => {
      if (addedStream.mediaStreamType === 'ScreenSharing') {
        addedStream.on('isAvailableChanged', () => {
          if (addedStream.isAvailable) {
            dispatch(addScreenShareStream(addedStream, participant));
          } else {
            dispatch(removeScreenShareStream(addedStream, participant));
          }
        });

        if (addedStream.isAvailable) {
          dispatch(addScreenShareStream(addedStream, participant));
        }
      } else if (addedStream.mediaStreamType === 'Video') {
        addedStream.on('isAvailableChanged', () => {
          remoteStreamSelector.participantVideoChanged(utils.getId(participant.identifier), addedStream.isAvailable);
        });
      }
    });
    dispatch(setParticipants([...call.remoteParticipants.values()]));
  });
};

const updateAudioDevices = async (
  deviceManager: DeviceManager,
  dispatch: Dispatch,
  getState: () => State
): Promise<void> => {
 
    const microphoneList: AudioDeviceInfo[] = await deviceManager.getMicrophones();
    dispatch(setAudioDeviceList(microphoneList));

    const speakersList: AudioDeviceInfo[] = await deviceManager.getSpeakers();
    dispatch(setAudioSpeakerDeviceList(speakersList));

    const state = getState();
    if (state.devices.audioDeviceInfo === undefined && microphoneList.length > 0) {
      dispatch(setAudioDeviceInfo(microphoneList[0]));
      deviceManager.selectMicrophone(microphoneList[0]);
    }else if(state.devices.audioSpeakerDeviceInfo === undefined && speakersList.length > 0){
      dispatch(setAudioSpeakerDeviceInfo(speakersList[0]));
      deviceManager.selectSpeaker(speakersList[0]);
    } else if (
      state.devices.audioDeviceInfo &&
      !utils.isSelectedAudioDeviceInList(state.devices.audioDeviceInfo, microphoneList)
    ) {
      deviceManager.selectMicrophone(state.devices.audioDeviceInfo);
    }else if (
      state.devices.audioSpeakerDeviceInfo &&
      !utils.isSelectedAudioDeviceInList(state.devices.audioSpeakerDeviceInfo, speakersList)
    ){
      deviceManager.selectSpeaker(state.devices.audioSpeakerDeviceInfo);
    }
};

const updateVideoDevices = async (
  deviceManager: DeviceManager,
  dispatch: Dispatch,
  getState: () => State
): Promise<void> => {
  const cameraList: VideoDeviceInfo[] = await deviceManager.getCameras();
  dispatch(setVideoDeviceList(cameraList));

  const state = getState();
  if (state.devices.videoDeviceInfo === undefined) {
    dispatch(setVideoDeviceInfo(cameraList[0]));
  } else if (
    state.devices.videoDeviceInfo &&
    !utils.isSelectedVideoDeviceInList(state.devices.videoDeviceInfo, cameraList)
  ) {
    dispatch(setVideoDeviceInfo(state.devices.videoDeviceInfo));
  }
};

const subscribeToDeviceManager = async (
  deviceManager: DeviceManager,
  dispatch: Dispatch,
  getState: () => State
): Promise<void> => {
  // listen for any new events
  deviceManager.on('videoDevicesUpdated', async () => {
    updateVideoDevices(deviceManager, dispatch, getState);
  });

  deviceManager.on('audioDevicesUpdated', async () => {
    updateAudioDevices(deviceManager, dispatch, getState);
  });

  deviceManager.askDevicePermission({ audio: true, video: true }).then((e: DeviceAccess) => {
    if (e.audio !== undefined) {
      if (e.audio) {
        dispatch(setMicrophonePermission('Granted'));

        updateAudioDevices(deviceManager, dispatch, getState);
      } else {
        dispatch(setMicrophonePermission('Denied'));
      }
    }

    if (e.video !== undefined) {
      if (e.video) {
        dispatch(setCameraPermission('Granted'));
        updateVideoDevices(deviceManager, dispatch, getState);
      } else {
        dispatch(setCameraPermission('Denied'));
      }
    }
  });
};

export const updateDevices = () => {
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    const state = getState();
    const deviceManager = state.devices.deviceManager;

    if (deviceManager == null) {
      console.error('no device manager available');
      return;
    }

    const cameraList: VideoDeviceInfo[] = await deviceManager.getCameras();

    dispatch(setVideoDeviceList(cameraList));

    const microphoneList: AudioDeviceInfo[] = await deviceManager.getMicrophones();
    const speakersList: AudioDeviceInfo[] = await deviceManager.getSpeakers();
    //const audiosList = [...microphoneList, ...speakersList];
    dispatch(setAudioDeviceList(microphoneList));
    dispatch(setAudioSpeakerDeviceList(speakersList));
  };
};

export const registerToCallAgent = (
  userId: string,
  callAgent: CallAgent,
  callEndedHandler: (reason: CallEndReason) => void
) => {
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    setLogLevel('verbose');

    setUserId(userId);
    setCallAgent(callAgent);

    callAgent.on('callsUpdated', (e: { added: Call[]; removed: Call[] }): void => {
      e.added.forEach((addedCall) => {
        //console.log(`Call added : Call Id = ${addedCall.id}`);

        const state = getState();
        if (state.calls.call && addedCall.direction === 'Incoming') {
          addedCall.hangUp();
          return;
        }

        dispatch(callAdded(addedCall));

        addedCall.on('stateChanged', (): void => {
          dispatch(setCallState(addedCall.state));
        });

        dispatch(setCallState(addedCall.state));

        addedCall.on('isScreenSharingOnChanged', (): void => {
          dispatch(setShareScreen(addedCall.isScreenSharingOn));
        });

        dispatch(setShareScreen(addedCall.isScreenSharingOn));
      
        // if remote participants have changed, subscribe to the added remote participants
        addedCall.on('remoteParticipantsUpdated', (ev): void => {
          // for each of the added remote participants, subscribe to events and then just update as well in case the update has already happened
          const state = getState();
         
         ev.added.forEach((addedRemoteParticipant) => {
            subscribeToParticipant(addedRemoteParticipant, addedCall, dispatch);
            dispatch(setParticipants([...state.calls.remoteParticipants, addedRemoteParticipant]));
          });

          // We don't use the actual value we are just going to reset the remoteParticipants based on the call
          if (ev.removed.length > 0) {
            dispatch(setParticipants([...addedCall.remoteParticipants.values()]));
          }
        });

        dispatch(setParticipants([...state.calls.remoteParticipants]));
      });
      e.removed.forEach((removedCall) => {
        const state = getState();
        if (state.calls.call && state.calls.call === removedCall) {
          dispatch(callRemoved(removedCall, state.calls.group));
          if (removedCall.callEndReason && removedCall.callEndReason.code !== 0) {
            removedCall.callEndReason && callEndedHandler(removedCall.callEndReason);
          }
        }
      });
    });
  };
};

export const initCallClient = (unsupportedStateHandler: () => void) => {
  return async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    let callClient;

    // check if chrome on ios OR firefox browser
    // if (utils.isOnIphoneAndNotSafari() || utils.isUnsupportedBrowser()) {
    //   unsupportedStateHandler();
    //   return;
    // }

    try {
      setLogLevel('verbose');
      callClient = new CallClient();
    } catch (e) {
      unsupportedStateHandler();
      return;
    }

    if (!callClient) {
      return;
    }

    const deviceManager: DeviceManager = await callClient.getDeviceManager();
    dispatch(setCallClient(callClient));
    dispatch(setDeviceManager(deviceManager));
    subscribeToDeviceManager(deviceManager, dispatch, getState);
  };
};


export const setPhonecall = async( callAgent: any, phoneNumber: string) => {
   try {

     let call = callAgent.startCall(
      [{phoneNumber: phoneNumber}], { alternateCallerId: {phoneNumber: '+918074503984'}
    });

    return call;
   } catch (error) {
     return error;
   }
};

export const endPhonecall = async (call: any) => {
   try {
    call.hangUp({
      forEveryone: true
    });
   } catch (error) {
     return error
   }
};

// what does the forEveryone parameter really mean?
export const endCall = async (call: Call, options: HangUpOptions): Promise<void> => {
  await call.hangUp(options).catch((e: CommunicationServicesError) => console.error(e));
 };

export const joinGroup = async (
  callAgent: CallAgent,
  context: GroupCallLocator,
  callOptions: JoinCallOptions
): Promise<void> => {
  try {
    await callAgent.join(context, callOptions);
    
  } catch (e) {
    console.log('Failed to join a call', e);
    return;
  }
};

export const addParticipant = async (call: Call, user: CommunicationUserKind): Promise<void> => {
  await call.addParticipant(user);
};

export const removeParticipant = async (call: Call, user: CommunicationUserKind): Promise<void> => {
  if(Object.keys(user).length && Object.keys(call).length){
    await call.removeParticipant(user).catch((e: CommunicationServicesError) => console.error(e));
  }
};


// This function sets up the user to chat with the thread
const addUserToThread = (displayName: string, userEmail: string, profileImage: File) => async (dispatch: Dispatch, getState: () => State) => {

  const urlParams = new URLSearchParams(window.location.search);
  const tID:any =  urlParams.get('threadId');

  let state: State = getState();
  if (state.thread.threadId === undefined && tID =='') {
    console.error('Thread Id not created yet');
    return;
  }
  let threadId: string = tID ? tID : state.thread.threadId;

  // get environment url from server
  let environmentUrl = await getEnvironmentUrl();

  if (environmentUrl === undefined) {
    console.error('unable to get environment url from server');
    return;
  }
  
  // create our user
  let userToken = await getToken();

  if (userToken === undefined) {
    console.error('unable to get a token');
    return;
  }
  
  let user = userToken.user as CommunicationUserIdentifier;

  let options: CommunicationTokenRefreshOptions = {
    token: userToken.token,
    tokenRefresher: () => refreshTokenAsync(user.communicationUserId),
    refreshProactively: true
  };

  let userAccessTokenCredentialNew = new AzureCommunicationTokenCredential(options);
  let chatClient = new ChatClient(environmentUrl, userAccessTokenCredentialNew);
  
  // set emoji for the user
 
  //let imglist = uploadFileToBlob(profileImage, displayName, state.calls.group);

  // setEmoji(displayName, await imglist);
 //uploadFile(user.communicationUserId, profileImage, dispatch);

  // subscribe for message, typing indicator, and read receipt
  let chatThreadClient =  chatClient.getChatThreadClient(threadId);
  //dispatch(setContosoUsersImage(await imglist));

  subscribeForMessage(chatClient, dispatch, getState);
  subscribeForTypingIndicator(chatClient, dispatch);
  subscribeForReadReceipt(chatClient, chatThreadClient, dispatch);
  subscribeForChatParticipants(chatClient, user.communicationUserId,  dispatch, getState);
  subscribeForTopicUpdated(chatClient, dispatch, getState);
 
  dispatch(setContosoUser(user.communicationUserId, userToken.token, displayName, userEmail, profileImage));
  dispatch(setThreadId(threadId));
  dispatch(setChatClient(chatClient));
  dispatch(setToken(userToken))
  await addThreadMemberHelper(
    threadId,
    state.calls.group,
    {
      identity: user.communicationUserId,
      token: userToken.token,
      displayName: displayName,
      memberRole: 'User'
    },
    dispatch
  );
 
  await getThreadInformation(chatClient, dispatch, getState);
  await getMessages(chatClient, dispatch, getState);
};

const subscribeForTypingIndicator = async (chatClient: ChatClient, dispatch: Dispatch) => {
  await chatClient.startRealtimeNotifications();
  chatClient.on('typingIndicatorReceived', async (event:any) => {
    const fromId = (event.sender as CommunicationUserKind).communicationUserId;
    const typingNotification = {
      from: fromId,
      originalArrivalTime: event.receivedOn,
      recipientId: (event.recipient as CommunicationUserKind).communicationUserId,
      threadId: event.threadId,
      version: event.version
    };
    dispatch(setTypingNotifications(fromId, typingNotification));
  });
};

const subscribeForMessage = async (chatClient: ChatClient, dispatch: Dispatch, getState: () => State) => {
  await chatClient.startRealtimeNotifications();
  
  chatClient.on('chatMessageReceived', async (event:any) => {
    let state: State = getState();

    
    let messages: any = state.chat.messages !== undefined ? state.chat.messages : [];

    // If message is a file event, add the file to state
    // Also add the message to state, since the message was sent server-side and the client doesn't know about it yet
   
   // const fileEventMessage = parseFileEventMessageContent(event.content);
    
    if (event.metadata && event.metadata.hasAttachment=='true') {
      // dispatch(addFiles([{
      //   id: event.metadata.fileId,
      //   name: event.metadata.fileName,
      //   uploadDateTime: event.createdOn,
      // }]));

      const fileMessage: any = {
         //  ...event,
        sender: event.sender,
        id: event.id,
        senderDisplayName: event.senderDisplayName,
        createdOn: event.createdOn,
        content: { message: event.message },
        extendedMessageType: 'FileEvent',
        attached: true,
        fileData: {
          id: event.metadata.fileId,
          name: event.metadata.fileName,
        },
        from: event.metadata.from,
        to: event.metadata.to
      };
  
      messages.push(fileMessage);
      dispatch(setMessages(messages.sort(compareMessages)));
    }
    // Also add messages to state if they were sent by other users (i.e. ignore own messages)
    else if (!isUserMatchingIdentity(event.sender, state.contosoClient.user.identity)) {
    
      const normalMessage: any = {
            sender: event.sender,
            id: event.id,
            senderDisplayName: event.senderDisplayName,
            createdOn: event.createdOn,
            content: { message: event.message },
            extendedMessageType: 'None',
            from: event.metadata.from,
            to: event.metadata.to
      };
  
      messages.push(normalMessage);
      dispatch(setMessages(messages.sort(compareMessages)));
    }
    // if (!isUserMatchingIdentity(event.sender, state.contosoClient.user.identity)) {
    //   const clientChatMessage = {
    //     sender: event.sender,
    //     id: event.id,
    //     senderDisplayName: event.senderDisplayName,
    //     createdOn: event.createdOn,
    //     content: { message: event.message }
    //   };

    //   messages.push(clientChatMessage);
    //   dispatch(setMessages(messages.sort(compareMessages)));
    // }
  });
};

const subscribeForReadReceipt = async (
  chatClient: ChatClient,
  chatThreadClient: ChatThreadClient,
  dispatch: Dispatch
) => {
  await chatClient.startRealtimeNotifications();
  chatClient.on('readReceiptReceived', async (event:any) => {
    let receipts: ChatMessageReadReceipt[] = [];
    for await (let page of chatThreadClient.listReadReceipts().byPage()) {
      for (const receipt of page) {
        receipts.push(receipt);
      }
    }
    dispatch(setReceipts(receipts));
  });
};

const subscribeForChatParticipants = async (
  chatClient: ChatClient,
  identity: string,
  dispatch: Dispatch,
  getState: () => State
) => {
  chatClient.on('participantsRemoved', async (event: ParticipantsRemovedEvent) => {
    const state = getState();
    let participants: ChatParticipant[] = [];
    for (let chatParticipant of event.participantsRemoved) {
      // if you are in the list, remove yourself from the chat
      if (isUserMatchingIdentity(chatParticipant.id, identity)) {
        dispatch(setRemovedFromThread(true));
        return;
      }
    }

    const originalParticipants = state.threadMembers.threadMembers;
    for (var i = 0; i < originalParticipants.length; i++) {
      const participantId = (originalParticipants[i].id as CommunicationUserIdentifier).communicationUserId;
      if (
        event.participantsRemoved.filter((chatParticipant: ChatParticipant) => isUserMatchingIdentity(chatParticipant.id, participantId))
          .length === 0
      ) {
        participants.push(originalParticipants[i]);
      }
    }

    dispatch(setThreadMembers(participants));
  });

  chatClient.on('participantsAdded', async (event: ParticipantsAddedEvent) => {
    const state = getState();
    let participants: ChatParticipant[] = [...state.threadMembers.threadMembers];

    // there is a chance that the participant added is you and so there is a chance that you can come in as a
    // new participant as well
     event.participantsAdded.map((chatParticipant: ChatParticipant) => {
      return {
        id: chatParticipant.id,
        displayName: chatParticipant.displayName,
        shareHistoryTime: new Date(chatParticipant?.shareHistoryTime || new Date())
      };
    });

    // add participants not in the list
    for (var j = 0; j < event.participantsAdded.length; j++) {
      const addedParticipant = event.participantsAdded[j];
      const id = (addedParticipant.id as CommunicationUserIdentifier).communicationUserId;
      if (
        participants.filter((participant: ChatParticipant) => isUserMatchingIdentity(participant.id, id)).length === 0
      ) {
        participants.push(addedParticipant);
      }
    }

    // also make sure we get the emojis for the new participants
    let users = Object.assign({}, state.contosoClient.users);
  //  let profileImgs= await getBlobsInContainer(state.calls.group);
  //   for (var i = 0; i < addedParticipants.length; i++) {
  //     let threadMember = addedParticipants[i];
  //     let identity:any = threadMember.displayName;
  //     let pic = profileImgs && profileImgs.length && profileImgs.filter((l:any)=>{
  //       let img = l.split('@').pop();
  //       if(img.substring(0, img.lastIndexOf("."))== identity ) return l;
  //     })
  //     if(pic){
  //       users[identity] = { emoji: pic[0] };
  //     }
  //     console.log("USER== 11",users)
  //     // var user = users[identity];
  //     // if (user == null) {
  //     //   var serverUser = await getEmoji(identity);
  //     //   if (serverUser !== undefined) {
  //     //     users[identity] = { emoji: serverUser.emoji };
  //     //   }
  //     // }
  //   }

    dispatch(setContosoUsers(users));
    dispatch(setThreadMembers(participants));
  });
};

const subscribeForTopicUpdated = async (chatClient: ChatClient, dispatch: Dispatch, getState: () => State) => {
  chatClient.on('chatThreadPropertiesUpdated', async (e: ChatThreadPropertiesUpdatedEvent) => {
    const state = getState();
    let threadId = state.thread.threadId;

    if (!threadId) {
      console.error('no threadId set');
      return;
    }

    dispatch(setThreadTopicName(e.properties.topic));
  });
};

const sendTypingNotification = () => async (dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  let chatClient = state.contosoClient.chatClient;
  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = state.thread.threadId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  
  await sendTypingNotificationHelper(await chatClient.getChatThreadClient(threadId));
};

const updateTypingUsers = () => async (dispatch: Dispatch, getState: () => State) => {
  let typingUsers = [];
  let state: State = getState();
  let typingNotifications = state.chat.typingNotifications;
  for (let id in typingNotifications) {
    let typingNotification = typingNotifications[id];
    if (!typingNotification.originalArrivalTime) {
      continue;
    }
    if (shouldDisplayTyping(typingNotification.originalArrivalTime)) {
      let threadMember = state.threadMembers.threadMembers.find((threadMember) =>
        isUserMatchingIdentity(threadMember.id, id)
      );
      if (threadMember) {
        typingUsers.push(threadMember);
      }
    }
  }
  dispatch(setTypingUsers(typingUsers));
};

const shouldDisplayTyping = (lastReceivedTypingEventDate: number) => {
  let currentDate = new Date();
  let timeSinceLastTypingNotificationMs = currentDate.getTime() - lastReceivedTypingEventDate;
  return timeSinceLastTypingNotificationMs <= Constants.MINIMUM_TYPING_INTERVAL_IN_MILLISECONDS;
};

const sendMessage = (messageContent: any) => async (dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  let chatClient = state.contosoClient.chatClient;
  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = state.thread.threadId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  let displayName = state.contosoClient.user.displayName;
  let userId = state.contosoClient.user.identity;

  // we use this client message id to have a local id for messages
  // if we fail to send the message we'll at least be able to show that the message failed to send on the client
  let clientMessageId = (Math.floor(Math.random() * Constants.MAXIMUM_INT64) + 1).toString(); //generate a random unsigned Int64 number
  let newMessage = createNewClientChatMessage(userId, displayName, clientMessageId, messageContent);
  let chatWithWhom = state.chat.whom;
   newMessage.from = Object.keys(chatWithWhom).length === 0 ? 'all': state.contosoClient.user.displayName;
   newMessage.to = Object.keys(chatWithWhom).length === 0 ? 'all': chatWithWhom.val;
  let messages = getState().chat.messages;
  messages.push(newMessage);
  dispatch(setMessages(messages));

  await sendMessageHelper(
    await chatClient.getChatThreadClient(threadId),
    threadId,
    messageContent,
    displayName,
    clientMessageId,
    '',
    dispatch,
    0,
    getState
  );
};

const isValidThread = (threadId: string) => async (dispatch: Dispatch) => {
  
  try {

    // let bodyData = {
    //   threadId: threadId
    // };
    // let checkRequestOptions = {
    //   httpMethod: 'POST',
    //   path: '/isvalidthread',
    //   body: bodyData
    // };
 
//     let response = await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/isvalidthread', checkRequestOptions);
// console.log("RES==",response)
//     if(response.status === 200){
      dispatch(setThreadId(threadId));
      return true;
    // }else{
    //   return false;
    // }
    
    
  } catch (error) {
    console.error('Failed at getting isThreadIdValid, Error: ', error);
    return error;
  }
};

const getMessages = async (chatClient: ChatClient, dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = state.thread.threadId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  
  let chatMessages = await getMessagesHelper(await chatClient.getChatThreadClient(threadId));
 
  if (chatMessages === undefined) {
    console.error('unable to get messages');
    return;
  }

  
  // Parse each message and check if it's a file event
  // If so, set special metadata that can be used during rendering
  const messages: ExtendedChatMessage[] = chatMessages.map((message:any) => {
    const fileEventMessage = message.content !== undefined ? parseFileEventMessageContent(message.content) : null;
    if (fileEventMessage !== null) {
      return {
        ...message,
        extendedMessageType: 'FileEvent',
        attached: true,
        fileData: {
          id: fileEventMessage.fileId,
          name: fileEventMessage.fileName,
        },
      };
    }

    return {
      ...message,
      extendedMessageType: 'None',
    };
  });

  return dispatch(setMessages(messages.reverse()));
};

const createThread = async(gid:any) => {
  let threadId:any = await createThreadHelper(gid);
 
   if (threadId !== null && threadId.charAt(0) !=='{') {
    // console.log("T==",threadId.charAt(0))
    //window.history.pushState({}, document.title, window.location.href + '?groupId=' + gid+'&threadId='+threadId);
    window.location.href += `&threadId=${threadId}`;
    //return threadId;
  } else {
    window.location.href = window.location.href.split('?')[0];
    console.error('unable to generate a new chat thread');
  }
};

const addThreadMember = () => async (dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  let user = state.contosoClient.user;
  let threadId = state.thread.threadId;

  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  await addThreadMemberHelper(
    threadId,
    state.calls.group,
    {
      identity: user.identity,
      token: user.token,
      displayName: user.displayName,
      memberRole: 'User'
    },
    dispatch
  );
};

const removeThreadMemberByUserId = (userId: string) => async (dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  let chatClient = state.contosoClient.chatClient;
  let threadId = state.thread.threadId;
  if (chatClient === undefined) {
    console.error("Chat client doesn't created yet");
    return;
  }
  if(userId==='') return;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  let chatThreadClient = await chatClient.getChatThreadClient(threadId);
  try {
    await chatThreadClient.removeParticipant({
      communicationUserId: userId
    });
  } catch (error) {
    console.log(error);
  }
};

const getThreadMembers = () => async (dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  let chatClient = state.contosoClient.chatClient;
  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = state.thread.threadId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  let chatThreadClient = await chatClient.getChatThreadClient(threadId);

  try {
    let threadMembers = [];
    for await (let page of chatThreadClient.listParticipants().byPage()) {
      for (const threadMember of page) {
        threadMembers.push(threadMember);
      }
    }
    dispatch(setThreadMembers(threadMembers));
  } catch (error) {
    console.error('Failed at getting members, Error: ', error);
    dispatch(setThreadMembersError(true));
  }
};

// We want to grab everything about the chat thread that has occured before we register for events.
// We care about pre-existing messages, the chat topic, and the participants in this chat
const getThreadInformation = async (chatClient: ChatClient, dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = state.thread.threadId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }

  let Rparticipants = state.calls.remoteParticipants;
  let newuser = state.contosoClient.user;
  let newuserobj={
    displayName: newuser.displayName,
    id:{
      communicationUserId: newuser.identity,
      kind: "communicationUser"
    }
  };
  let participantarray:any = [];
  if(Rparticipants && Rparticipants.length){
    Rparticipants.map((r:any,i)=>{
      let obj = {
       displayName : r._displayName,
       id:r._identifier 
      };
      participantarray.push(obj);
      
    })
  }
  participantarray.push(newuserobj);
 
  let chatParticipants = participantarray;
 
  if (chatParticipants.length === 0) {
    console.error('unable to get members in the thread');
    return;
  }

  // remove undefined display name chat participants
  const validChatParticipants = chatParticipants.filter(
    (chatParticipant:any) => chatParticipant.displayName !== undefined && chatParticipant.id !== undefined
  );

  // get the emojis for the new participants
  let users = state.contosoClient.users;
 

  dispatch(setThreadId(threadId));
  dispatch(setThreadTopicName("GatheringJar"));
  dispatch(setContosoUsers(users));
  dispatch(setThreadMembers(validChatParticipants));
};

const updateThreadTopicName = async (
  chatClient: ChatClient,
  threadId: string,
  topicName: string,
  setIsSavingTopicName: React.Dispatch<boolean>
) => {
  const chatThreadClient = await chatClient.getChatThreadClient(threadId);
  updateThreadTopicNameHelper(chatThreadClient, topicName, setIsSavingTopicName);
};

// Thread Helper
const createThreadHelper = async (gid:any) => {
  try {
    // let createThreadRequestOptions = { method: 'GET' };
    // let createThreadResponse = await fetch('http://localhost:5000/createThread', createThreadRequestOptions);
    // let threadId = await createThreadResponse.text();
    let createThreadRequestOptions = { 
      httpMethod: 'POST', 
      path: '/createThread',
      body:{
        groupId: gid
      } 
    };
    let createThreadResponse = await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/createthread', createThreadRequestOptions);
    let response = await createThreadResponse.data.body;
    
    //dispatch(setToken(response.tokenDetails));
    return response;
  } catch (error) {
    console.error('Failed at creating thread, Error: ', error);
    return error;
  }
};

const updateThreadTopicNameHelper = async (
  chatThreadClient: ChatThreadClient,
  topicName: string,
  setIsSavingTopicName: React.Dispatch<boolean>
) => {
  try {
    await chatThreadClient.updateTopic(topicName);
    setIsSavingTopicName(false);
  } catch (error) {
    console.error('Failed at updating thread property, Error: ', error);
  }
};

// Thread Member Helper
const addThreadMemberHelper = async (threadId: string, gid:any, user: User, dispatch: Dispatch) => {
  
  try {
    let bodyData = {
      id: user.identity,
      displayName: user.displayName,
      threadId: threadId,
      groupId: gid
    };
    let addMemberRequestOptions = {
      httpMethod: 'POST',
      path: '/addUser',
      body: bodyData
    };
    
 
  await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/adduser', addMemberRequestOptions);

// if(res.data.statusCode !== 200){
  
//    addUserToThread(user.displayName);
   
// }
    
    
//     let environmentUrl = await getEnvironmentUrl();
//     let path = `${environmentUrl}chat/threads/${threadId}/participants/:add?api-version=2021-09-07`;
//     let participant={
//       "participants": [
//         {
//           "communicationIdentifier": {
//             "rawId": user.identity,
//             "communicationUser": {
//               "id": user.identity
//             }
//           },
//           "displayName": user.displayName
//         }
//        ]
//     };
//     const headers = { 
//       'Authorization': `Bearer ${user.token}`
//   };
// let response = await axios.post(path, participant, { headers });
// console.log("RES==",response)
    // let body1:any = {
    //   id: user.identity,
    //   displayName: user.displayName,
    //   threadId: threadId
    // };
    // //let bdata = AES.encrypt(JSON.stringify({body:body1}), Constants.SALT_KEY).toString();
    // let addMemberRequestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   //body: body1
    //   body: JSON.stringify(body1)
    // };
   
    // await fetch('http://localhost:5000/addUser', addMemberRequestOptions);

  } catch (error) {
    console.error('Failed at adding thread member, Error: ', error);
  }
};

// Message Helper
const sendMessageHelper = async (
  chatThreadClient: ChatThreadClient,
  threadId: string,
  messageContent: string,
  displayName: string,
  clientMessageId: string,
  fileData: any,
  dispatch: Dispatch,
  retryCount: number,
  getState: () => State
) => {
  // for real time messages we want to store it locally and render it and then sync it with the server message later
  // 1. send the message
  // 2. cache the message locally using the message.id
  // 3. when we get the server synced message we match with the message.id
  let failedMessages = getState().chat.failedMessages;
  let chatWithWhom = getState().chat.whom;

  try {
    // const messageResult = await chatThreadClient.sendMessage(
    //   { content: messageContent },
    //   { senderDisplayName: displayName }
    // );
    // const message: ChatMessage = await chatThreadClient.getMessage(messageResult.id);
    // //updateMessagesArray(dispatch, getState, convertToClientChatMessage(message, clientMessageId));
    // updateMessagesArray(dispatch, getState, {
    //   ...message,
    //   clientMessageId,
    //   extendedMessageType: 'None',
    // })

    let SendMessageRequest = {
      content: messageContent,
      senderDisplayName: displayName,
      metadata: {
        'hasAttachment' : fileData.id ? 'true' : 'false',
        'attachmentUrl' : '',
        'fileId': fileData.id ? fileData.id : '',
        'fileName': fileData.id ? fileData.name : '',
        'from': Object.keys(chatWithWhom).length === 0 ? 'all': getState().contosoClient.user.displayName,
        'to': Object.keys(chatWithWhom).length === 0 ? 'all': chatWithWhom.val
      },
      
    };
    chatThreadClient.sendMessage(SendMessageRequest).then(async (res:any) => {

      //if (res._response.status === CREATED) {
        if (res.id) {
          let message: ChatMessage | undefined = await getMessageHelper(chatThreadClient, res.id, dispatch);
          if (message && !fileData) {
            updateMessagesArray(dispatch, getState, {
              ...message,
              clientMessageId,
              extendedMessageType: 'None',
               from: Object.keys(chatWithWhom).length === 0 ? 'all': getState().contosoClient.user.displayName,
               to: Object.keys(chatWithWhom).length === 0 ? 'all': chatWithWhom.val
            });
          } else if(fileData.id){
            updateMessagesArray(dispatch, getState, {
              clientMessageId: clientMessageId,
              createdOn: new Date(),
              id: res.id,
              extendedMessageType: 'FileEvent',
              fileData: fileData,
              type: 'text',
              sequenceId: '',
              version: ''
            });
          }else{
            updateMessagesArray(dispatch, getState, {
              clientMessageId: clientMessageId,
              createdOn: new Date(),
              id: res.id,
              extendedMessageType: 'None',
              type: 'text',
              sequenceId: '',
              version: '',
              from: Object.keys(chatWithWhom).length === 0 ? 'all': getState().contosoClient.user.displayName,
              to: Object.keys(chatWithWhom).length === 0 ? 'all': chatWithWhom.val
            })
          }
        }
      // } else if (res._response.status === TOO_MANY_REQUESTS_STATUS_CODE) {
      //   dispatch(setContosoUserCoolPeriod(new Date()));
      //   // retry after cool period
      //   setTimeout(() => {
      //     sendMessageHelper(
      //       chatThreadClient,
      //       threadId,
      //       messageContent,
      //       displayName,
      //       clientMessageId,
      //       dispatch,
      //       retryCount,
      //       getState
      //     );
      //   }, COOL_PERIOD_THRESHOLD);
      // } else if (res._response.status === PRECONDITION_FAILED_STATUS_CODE) {
      //   if (retryCount >= MAXIMUM_RETRY_COUNT) {
      //     console.error('Failed at sending message and reached max retry count');
      //     failedMessages.push(clientMessageId);
      //     setFailedMessages(failedMessages);
      //     return;
      //   }
      //   // retry in 0.2s
      //   setTimeout(() => {
      //     sendMessageHelper(
      //       chatThreadClient,
      //       threadId,
      //       messageContent,
      //       displayName,
      //       clientMessageId,
      //       dispatch,
      //       retryCount + 1,
      //       getState
      //     );
      //   }, 200);
      // } 
      // else {
      //   failedMessages.push(clientMessageId);
      //   setFailedMessages(failedMessages);
      // }
    });

  } catch (error) {
    // console.error('Failed at getting messages, Error: ', error);
    // let failedMessages = getState().chat.failedMessages;
    // failedMessages.push(clientMessageId);
    // setFailedMessages(failedMessages);

    // const message = getState().chat.messages.filter((message) => message.clientMessageId === clientMessageId)[0];
    // //message.failed = true;
    // updateMessagesArray(dispatch, getState, message);
    console.error('Failed at sending message, Error: ', error);
    failedMessages.push(clientMessageId);
    setFailedMessages(failedMessages);
  }
};

// Merge our local messages with server synced messages
const updateMessagesArray = async (dispatch: Dispatch, getState: () => State, newMessage: ExtendedChatMessage) => {
  // let state: State = getState();
  // let messages: ExtendedChatMessage[] = state.chat.messages !== undefined ? state.chat.messages : [];

  // // the message id is what we we get from the server when it is synced. There will be other server attributes
  // // on the message but the id should be consistent.
  // messages = messages.map((message: ExtendedChatMessage) => {
  //   return message.clientMessageId === newMessage.clientMessageId ? Object.assign({}, message, newMessage) : message;
  // });
  // dispatch(setMessages(messages.sort(compareMessages)));
  let state: State = getState();
  let messages: ExtendedChatMessage[] = state.chat.messages !== undefined ? state.chat.messages : [];
  messages = messages.map((message: ExtendedChatMessage) => {
    if (message.clientMessageId === newMessage.clientMessageId) {
      return {
        ...message,
        ...newMessage
      };
    } else {
      return message;
    }
  });
  dispatch(setMessages(messages.sort(compareMessages)));
};

const getMessageHelper = async (chatThreadClient: ChatThreadClient, messageId: string, dispatch: Dispatch) => {
  try {
    let messageResponse: any = await chatThreadClient.getMessage(messageId);
  
    if (messageResponse._response.status === OK) {
      let chatMessage: ChatMessage = messageResponse;
      return chatMessage;
    } else if (messageResponse._response.status === TOO_MANY_REQUESTS_STATUS_CODE) {
      return undefined;
    }
    return;
  } catch (error) {
    console.error('Failed at getting messages, Error: ', error);
    return;
  }
};

const getMessagesHelper = async (chatThreadClient: ChatThreadClient): Promise<ChatMessage[] | undefined> => {
  try {
    let messages: ChatMessage[] = [];
    let getMessagesResponse = await chatThreadClient.listMessages({
      maxPageSize: Constants.PAGE_SIZE
    });

    let messages_temp = [];

    for await (let page of getMessagesResponse.byPage()) {
      for (const message of page) {
        messages_temp.push(message);
      }
    }

    while (true) {
      if (messages_temp === undefined) {
        console.error('Unable to get messages from server');
        return;
      }

      // filter and only return top 100 text messages
      messages.push(...messages_temp.filter((message) => message.type === 'text'));
      if (messages.length >= Constants.INITIAL_MESSAGES_SIZE) {
        return messages.slice(0, Constants.INITIAL_MESSAGES_SIZE);
      }
      // if there is no more messages
      break;
    }

    return messages.slice(0, Constants.INITIAL_MESSAGES_SIZE);
  } catch (error) {
    console.error('Failed at getting messages, Error: ', error);
    return;
  }
};

// Typing Notification Helper
const sendTypingNotificationHelper = async (chatThreadClient: ChatThreadClient) => {
  try {
    await chatThreadClient.sendTypingNotification();
  } catch (error) {
    console.error('Failed at sending typing notification, Error: ', error);
  }
};

const getEnvironmentUrl = async () => {
  try {
    let postRequestOptions = {
      httpMethod: 'POST',
      path:'/environmentUrl'
    };
    let response = await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/environmenturl', postRequestOptions);
     return await response.data.body;

    //  let getRequestOptions = {
    //   method: 'GET'
    // };
    // let response = await fetch('http://localhost:5000/environmentUrl', getRequestOptions);
    //  return await response.text();
  } catch (error) {
    console.error('Failed at getting environment url, Error: ', error);
    return;
  }
};

// Token Helper
const getToken = async () => {
 
  let postRequestOptions = {
    httpMethod: 'POST',
    path:'/token'
  };
  const response = await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/token',postRequestOptions);
  if (response.data) {
        return response.data.body;
    }
    // let getRequestOptions = {
    //   method: 'GET'
    // };
    // const response = await fetch('http://localhost:5000/token',getRequestOptions);
    // if (response) {
     
    //      return await response.json();
    //   }
    throw new Error('Invalid token response');
};

const refreshTokenAsync = async (identity: string): Promise<string> => {
  let postRequestOptions = {
    httpMethod: 'POST',
    path:'/refreshToken',
    body: {
      identity: identity
    }
  };
  const response = await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/refreshtoken',postRequestOptions);
    if (response) {
      const content = response.data.body;
      return content.token;
    }
  // const response = await fetch(`http://localhost:5000/refreshToken/${identity}`);
  //   if (response) {
  //     const content = await response.json();
  //     return content.token;
  //   }
    throw new Error('Invalid token response');
};

const uploadFile = async (displayName: string, email: string, profileImage: any) => {
  //return async (dispatch: Dispatch): Promise<void> => {
    let reqBody:any = {
      file: profileImage,
      email: email,
      displayName: displayName
    };
    let uploadRequestOptions = {
      method: 'POST',
      body:reqBody
    };
       await fetch('http://localhost:5000/uploadprofileimage', uploadRequestOptions);
 
};

// const setEmoji = async (userId: string, emoji: string) => {
//   try {
//     let getTokenRequestOptions = {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ Emoji: emoji })
//     };
//     await (await fetch('/userConfig/' + userId, getTokenRequestOptions)).json;
//   } catch (error) {
//     console.error('Failed at setting emoji, Error: ', error);
//   }
// };

// const getEmoji = async (userId: string) => {
//   try {
//     let getTokenRequestOptions = {
//       headers: { 'Content-Type': 'application/json' },
//       method: 'GET'
//     };
//     return await (await fetch('/userConfig/' + userId, getTokenRequestOptions)).json();
//   } catch (error) {
//     console.error('Failed at getting emoji, Error: ', error);
//   }
// };

const sendReadReceipt = (messageId: string) => async (dispatch: Dispatch, getState: () => State) => {
  // This is sent when we get focus to this tab and see this message
  let state: State = getState();
  let chatClient = state.contosoClient.chatClient;
  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = state.thread.threadId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  await sendReadReceiptHelper(await chatClient.getChatThreadClient(threadId), messageId);
};

const sendReadReceiptHelper = async (chatThreadClient: ChatThreadClient, messageId: string) => {
  let postReadReceiptRequest: SendReadReceiptRequest = {
    chatMessageId: messageId
  };
  await chatThreadClient.sendReadReceipt(postReadReceiptRequest);
};

//Get All Profile Images

const getAllProfileImages = () => async(dispatch: Dispatch, getState: () => State) =>{
  const state = getState();
  const groupId = state.calls.group;
  const accId= state.sdk.meetingData.AccountInfo.OrgAccountId;

  let allprofilepics = await getAllProfilePics({
    accountId: accId,
    gId: groupId
  });
  if(allprofilepics) dispatch(setContosoUsersImage([...new Set(allprofilepics)]));
//   let profilebodyreq = {
//     httpMethod: 'POST',
//     path: '/getallprofileimages',
//     body: {
//       groupId: groupId
//     }
//   };
//  axios.post(`https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/getallprofileimages`,profilebodyreq)
//   .then((res)=>{
//    // console.log("Sub== AllProfilImgSF", res);
//     if(res.data.statusCode !== 400){
//       dispatch(setContosoUsersImage([...new Set(res.data.body)]))
//     }
//     // props.setProfileImage([...new Set(res.data.body)]);
     
//   });
};


// Files
const sendFile = (file: any) => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  
  const userId = state.contosoClient.user.identity;
  const userDisplayName = state.contosoClient.user.displayName;
  const threadId = state.thread.threadId;
  const groupId = state.calls.group;
  const accId= state.sdk.meetingData.AccountInfo.OrgAccountId;
  if (threadId === undefined) {
    return false;
  }
  const uuid = Math.round(new Date().getTime()/1000).toString();
  const data = new FormData();
  data.append('file', file);
  data.append('fileName', file.name);
  data.append('userId', userId);
 
  let F_Name =  file.name.split('.').shift();
  let Ftype =   file.name.split('.').pop();

  //if(Ftype == 'jpeg') Ftype = 'jpg';
 
  let extension = Ftype;
   if(Ftype == 'jpg' || Ftype == 'jpeg') extension = 'jpeg';
   if(Ftype == 'png' || Ftype == 'PNG') extension = 'png';
   let extensionType=''
   if(Ftype == 'png' || Ftype == 'jpg' || Ftype == 'jpeg') extensionType = 'image'; 
   if(Ftype == 'pdf' || Ftype == 'doc' || Ftype == 'docx') extensionType = 'application'; 
  
  let new_file = new File([file], `${uuid}#${F_Name}.${Ftype}`,{type: `${extensionType}/${extension}`});
  //let fname = new_file.name;


  try {

    await uploadChatAttachment({
      file: new_file,
      gId: groupId,
      tId: threadId,
      accountId: accId
    });
  // let getSignedUrlBody = {
  //   httpMethod: 'POST',
  //   path:'/uploadattachments',
  //   body: {
  //     fileName: fname,
  //     threadId: threadId,
  //     userId: userId,
  //     groupId: groupId  
  //   }
  // };
  
  // axios.post(`https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/uploadattachments`, getSignedUrlBody)
  // .then((response)=>{
   
  //     let url = response.data.body;
  //     axios({
  //       method: "PUT",
  //       url: url,
  //       data: new_file,
  //       headers: { "Content-Type": "multipart/form-data" }
  //   }).then(async()=>{
      const chatClient = state.contosoClient.chatClient;
      if (chatClient === undefined) {
        return false;
      }

      let clientMessageId = (Math.floor(Math.random() * Constants.MAXIMUM_INT64) + 1).toString(); //generate a random unsigned Int64 number
      
     
      await sendMessageHelper(
        await chatClient.getChatThreadClient(threadId),
        threadId,
        '',
        userDisplayName,
        clientMessageId,
        {
          id: uuid,
          name: file.name,
        },
        dispatch,
        0,
        getState
      );

      dispatch(addFiles([{
        id: uuid,
        name: file.name,
        uploadDateTime: Date.now(),
      }]))
      // subscribeForMessage(chatClient, dispatch, getState);
       getAllFiles();
      return true;
   // })
 // });
  //return;
  } catch (error) {
    console.error('Failed to send file: ', error);
    return false;
  }
};

const getFile = (fileId: string) => async (dispatch: Dispatch, getState: () => State) => {
   const state = getState();
   const threadId = state.thread.threadId;
   const userId = state.contosoClient.user.identity;
   const groupId = state.calls.group;
   const accId= state.sdk.meetingData.AccountInfo.OrgAccountId;
// let getAttachmentsURL = {
//   httpMethod: 'POST',
//   path:'/getattachmenturl',
//   body: {
//     threadId: threadId,
//     fileName: fileId,
//     groupId: groupId  
//   }
// };

// let attachmentUrl = await axios.post(`https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/getattachmenturl`, getAttachmentsURL);
let attachurl = await getAttachmentURL({
  gId: groupId,
  tId: threadId,
  accountId: accId,
  fileName: fileId
});
  // Update file with new blob URL
   dispatch(setFileBlobUrl(userId, attachurl));
   //return attachmentUrl.data.body;
};

const getAllFiles = () => async (dispatch: Dispatch, getState: () => State) => {
  const threadId = getState().thread.threadId;
  const groupId = getState().calls.group;
  const accId= getState().sdk.meetingData.AccountInfo.OrgAccountId;
// let getAllAttachmentsBody = {
//   httpMethod: 'POST',
//   path:'/getallattachments',
//   body: {
//     threadId: threadId,
//     groupId: groupId
//   }
// };

// let allAttachments = await axios.post(`https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/getallattachments`, getAllAttachmentsBody);
//console.log("Sub== AllAttchments",allAttachments)
  // Update files in state with fetched files
  let allattachments: any = await getAllAttachments({
    gId: groupId,
    tId: threadId,
    accountId: accId
   });
  dispatch(addFiles(allattachments));
};

const individualParticipantMuteChange  = (participant: RemoteParticipant, isMuted: boolean) => async (dispatch: Dispatch, getState: () => State) => {

const dominantParticipantCount = Constants.DOMINANT_PARTICIPANTS_COUNT;
const remoteStreamSelector = RemoteStreamSelector.getInstance(dominantParticipantCount, dispatch);

let callIns: any = getState().calls.call ;
callIns.remoteParticipants[0]._isMuted = isMuted;
callIns.remoteParticipants[0]._isSpeaking = !isMuted;
//participant.on('stateChanged', () => {
  remoteStreamSelector.participantStateChanged(
    utils.getId(participant.identifier),
    participant.displayName ?? '',
    participant.state,
    isMuted,
    participant.videoStreams[0].isAvailable
  );
 
  //dispatch(setParticipants([...callIns.remoteParticipants.values()]));
//});

//participant.on('isMutedChanged', () => {

  remoteStreamSelector.participantAudioChanged(utils.getId(participant.identifier), isMuted);
//});

//participant.on('isSpeakingChanged', () => {
  dispatch(setParticipants([...callIns.remoteParticipants.values()]));
//});


  //remoteStreamSelector.participantAudioChanged(userId, isMuted);
  setMicrophone(isMuted);
};

const getAccountInfo = () =>async (getState: () => State) => {
  let accountState: any = getState().sdk.meetingData;
  return accountState;
};


const addIndividualUserToThread = (displayName: string, threadId: string, userId: string) => async (dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  // get environment url from server
  let environmentUrl = await getEnvironmentUrl();

  if (environmentUrl === undefined) {
    console.error('unable to get environment url from server');
    return;
  }
  //console.log("Debug== uid",userId)
  // create our user
  let userToken = await getToken();

  if (userToken === undefined) {
    console.error('unable to get a token');
    return;
  }
  let user = userToken.user as CommunicationUserIdentifier;

  let options: CommunicationTokenRefreshOptions = {
    token: userToken.token,
    tokenRefresher: () => refreshTokenAsync(user.communicationUserId),
    refreshProactively: true
  };

  let userAccessTokenCredentialNew = new AzureCommunicationTokenCredential(options);
  let chatClient = new ChatClient(environmentUrl, userAccessTokenCredentialNew);

  // subscribe for message, typing indicator, and read receipt
  let chatThreadClient =  chatClient.getChatThreadClient(threadId);
 
  subscribeForIndividualMessage(chatClient, threadId, user.communicationUserId, dispatch, getState);
  subscribeForTypingIndicator(chatClient, dispatch);
  subscribeForReadReceipt(chatClient, chatThreadClient, dispatch);
  subscribeForChatParticipants(chatClient, user.communicationUserId,  dispatch, getState);
  subscribeForIndividualTopicUpdated(chatClient, threadId, dispatch, getState);
  //dispatch(setThreadId(threadId));
  //dispatch(setContosoUser(user.communicationUserId, userToken.token, displayName, userEmail, profileImage));
  const invdChatClients = state.contosoClient.chatClients;
 const cClient = {
  [threadId]: chatClient
 };
 //invdChatClients.push(cClient);
  dispatch(setIndividualChatClients([...invdChatClients, cClient]));
  await addThreadMemberHelper(
    threadId,
    state.calls.group,
    {
      identity: user.communicationUserId,
      token: userToken.token,
      displayName: displayName,
      memberRole: 'User'
    },
    dispatch
  );
 
  await getIndividualThreadInformation(chatClient, threadId, displayName, user.communicationUserId, dispatch, getState);
  await getIndividualMessages(chatClient,chatThreadClient, threadId, dispatch, getState);
};

const createIndividualThread = async(gId:any) => {
    let threadId:any = await createThreadHelper(gId);
    return threadId;
};

const sendIndividualMessage = (messageContent: any, tId: any, uid: any, name: any) => async (dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();

  let chatClient = state.contosoClient.chatClients[0][tId];

  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = tId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  let displayName = state.contosoClient.user.displayName;
  let userId = state.contosoClient.user.identity;

  // we use this client message id to have a local id for messages
  // if we fail to send the message we'll at least be able to show that the message failed to send on the client
  let clientMessageId = (Math.floor(Math.random() * Constants.MAXIMUM_INT64) + 1).toString(); //generate a random unsigned Int64 number
  let newMessage = createNewClientChatMessage(userId, displayName, clientMessageId, messageContent);
  let indMsg = getState().chat.individualmessages;
 
  let messages = indMsg.length ? indMsg[0][threadId] : [];
  messages.push(newMessage);
  //if(!indMsg.length){
    let allmsgs = [];
    let newmsgobj:any = {
      [threadId]: messages
    };
    allmsgs.push(newmsgobj);
  
    dispatch(setIndividualMessages(allmsgs));
  // }else{
  //   console.log("Debug== 1786 Else",messages)
  //   dispatch(setIndividualMessages(messages));
  // }
 

  await sendIndividualMessageHelper(
    await chatClient.getChatThreadClient(threadId),
    threadId,
    messageContent,
    displayName,
    clientMessageId,
    '',
    dispatch,
    0,
    getState
  );
};

const sendIndividualMessageHelper = async (
  chatThreadClient: ChatThreadClient,
  threadId: string,
  messageContent: string,
  displayName: string,
  clientMessageId: string,
  fileData: any,
  dispatch: Dispatch,
  retryCount: number,
  getState: () => State
) => {
  // for real time messages we want to store it locally and render it and then sync it with the server message later
  // 1. send the message
  // 2. cache the message locally using the message.id
  // 3. when we get the server synced message we match with the message.id
  let failedMessages = getState().chat.failedMessages;
  try {
    let SendMessageRequest = {
      content: messageContent,
      senderDisplayName: displayName,
      metadata: {
        'hasAttachment' : fileData.id ? 'true' : 'false',
        'attachmentUrl' : '',
        'fileId': fileData.id ? fileData.id : '',
        'fileName': fileData.id ? fileData.name : '',
      }
    };
  
    chatThreadClient.sendMessage(SendMessageRequest).then(async (res:any) => {

      //if (res._response.status === CREATED) {
        if (res.id) {
          let message: ChatMessage | undefined = await getMessageHelper(chatThreadClient, res.id, dispatch);
          if (message && !fileData) {
            updateIndividualMessagesArray(dispatch, getState, {
              ...message,
              clientMessageId,
              extendedMessageType: 'None',
              from:'',
              to:''
            }, threadId);
          } else if(fileData.id){
            updateIndividualMessagesArray(dispatch, getState, {
              clientMessageId: clientMessageId,
              createdOn: new Date(),
              id: res.id,
              extendedMessageType: 'FileEvent',
              fileData: fileData,
              type: 'text',
              sequenceId: '',
              version: ''
            }, threadId);
          }else{
            updateIndividualMessagesArray(dispatch, getState, {
              clientMessageId: clientMessageId,
              createdOn: new Date(),
              id: res.id,
              extendedMessageType: 'None',
              type: 'text',
              sequenceId: '',
              version: '',
              from:'',
              to:''
            }, threadId)
          }
        }
     
    }).catch(err => console.log("Debug== Err",err));

  } catch (error) {
 
    console.error('Failed at sending message, Error: ', error);
    failedMessages.push(clientMessageId);
    setFailedMessages(failedMessages);
  }
};

// Merge our local messages with server synced messages
const updateIndividualMessagesArray = async (dispatch: Dispatch, getState: () => State, newMessage: ExtendedChatMessage, threadId: any) => {

  let state: State = getState();

  let messages: ExtendedChatMessage[] = state.chat.individualmessages[0][threadId] !== undefined ? state.chat.individualmessages[0][threadId] : [];
  
  messages = messages.map((message: ExtendedChatMessage) => {
    if (message.clientMessageId === newMessage.clientMessageId) {
      return {
        ...message,
        ...newMessage
      };
    } else {
      return message;
    }
  });
  let allmsgs = [];
  let newmsgobj:any = {
    [threadId]: messages
  };
  allmsgs.push(newmsgobj);
 
  dispatch(setIndividualMessages(allmsgs.sort(compareMessages)));
};

const subscribeForIndividualMessage = async (chatClient: ChatClient, threadId: any, userId: any, dispatch: Dispatch, getState: () => State) => {
  await chatClient.startRealtimeNotifications();
  
  chatClient.on('chatMessageReceived', async (event:any) => {
    let state: State = getState();

    let messages: any = state.chat.individualmessages[0][threadId] !== undefined ? state.chat.individualmessages[0][threadId] : [];

    if (event.metadata && event.metadata.hasAttachment=='true') {
 
      const fileMessage: any = {
         //  ...event,
        sender: event.sender,
        id: event.id,
        senderDisplayName: event.senderDisplayName,
        createdOn: event.createdOn,
        content: { message: event.message },
        extendedMessageType: 'FileEvent',
        attached: true,
        fileData: {
          id: event.metadata.fileId,
          name: event.metadata.fileName,
        },
      };
  
      messages.push(fileMessage);
      dispatch(setIndividualMessages(messages.sort(compareMessages)));
    }
    // Also add messages to state if they were sent by other users (i.e. ignore own messages)
    else if (!isUserMatchingIdentity(event.sender, userId)) {
      const normalMessage: any = {
            sender: event.sender,
            id: event.id,
            senderDisplayName: event.senderDisplayName,
            createdOn: event.createdOn,
            content: { message: event.message },
            extendedMessageType: 'None',
      };
  
      messages.push(normalMessage);
      dispatch(setIndividualMessages(messages.sort(compareMessages)));
    }
 
  });
};

const getIndividualMessages = async (chatClient: ChatClient, chatThreadClient: ChatThreadClient, tId: any, dispatch: Dispatch, getState: () => State) => {

  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = tId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }
  
  let chatMessages = await getMessagesHelper(chatThreadClient);

  if (chatMessages === undefined) {
    console.error('unable to get messages');
    return;
  }

  // Parse each message and check if it's a file event
  // If so, set special metadata that can be used during rendering
  const messages: any[] = chatMessages.map((message:any) => {
   
    const fileEventMessage = message.content !== undefined ? parseFileEventMessageContent(message.content) : null;
    if (fileEventMessage !== null) {
      return {
        ...message,
        extendedMessageType: 'FileEvent',
        attached: true,
        fileData: {
          id: fileEventMessage.fileId,
          name: fileEventMessage.fileName,
        },
      };
    }

    return {
      ...message,
      extendedMessageType: 'None',
    };
  });
  return dispatch(setIndividualMessages(messages.reverse()));
};

const getIndividualThreadInformation = async (chatClient: ChatClient, tId: any, name: any, uid:any, dispatch: Dispatch, getState: () => State) => {
  let state: State = getState();
  if (chatClient === undefined) {
    console.error('Chat Client not created yet');
    return;
  }
  let threadId = tId;
  if (threadId === undefined) {
    console.error('Thread Id not created yet');
    return;
  }

  let Rparticipants = state.calls.remoteParticipants;
  //let newuser = state.contosoClient.user;
  let newuserobj={
    displayName: name,
    id:{
      communicationUserId: uid,
      kind: "communicationUser"
    }
  };
  let participantarray:any = [];
  if(Rparticipants && Rparticipants.length){
    Rparticipants.map((r:any,i)=>{
      let obj = {
       displayName : r._displayName,
       id:r._identifier 
      };
      participantarray.push(obj);
      
    })
  }
  participantarray.push(newuserobj);
 
  let chatParticipants = participantarray;
 
  if (chatParticipants.length === 0) {
    console.error('unable to get members in the thread');
    return;
  }

  // remove undefined display name chat participants
  const validChatParticipants = chatParticipants.filter(
    (chatParticipant:any) => chatParticipant.displayName !== undefined && chatParticipant.id !== undefined
  );

  //dispatch(setThreadId(threadId));
  dispatch(setThreadTopicName("GatheringJar"));
  //dispatch(setContosoUsers(users));
  dispatch(setThreadMembers(validChatParticipants));
};

const subscribeForIndividualTopicUpdated = async (chatClient: ChatClient,tId:any,  dispatch: Dispatch, getState: () => State) => {
  chatClient.on('chatThreadPropertiesUpdated', async (e: ChatThreadPropertiesUpdatedEvent) => {
    //const state = getState();
    let threadId = tId;

    if (!threadId) {
      console.error('no threadId set');
      return;
    }

    dispatch(setThreadTopicName(e.properties.topic));
  });
};

const saveIndividualChatThreads = (tId:any, uId: any) => async (dispatch: Dispatch, getState: () => State) => {

  const accId= getState().sdk.meetingData.AccountInfo.OrgAccountId;
  const groupId = getState().calls.group;
  const userId = getState().contosoClient.user.identity;

  saveChatThreadsList({
    accountId: accId,
    gId: groupId,
    uId: userId,
    tId: tId
  });

};

const getChatThreadList = () => (dispatch: Dispatch, getState: () => State) => {
  const accId= getState().sdk.meetingData.AccountInfo.OrgAccountId;
  const groupId = getState().calls.group;
  getChatThreadLists({
    accountId: accId,
    gId: groupId,
  })
};


export {
  sendMessage,
  getMessages,
  createThread,
  addThreadMember,
  getThreadMembers,
  addUserToThread,
  removeThreadMemberByUserId,
  //getEmoji,
  //setEmoji,
  uploadFile,
  sendReadReceipt,
  sendTypingNotification,
  updateTypingUsers,
  isValidThread,
  updateThreadTopicName,
  sendFile,
  getFile,
  getAllFiles,
  getAllProfileImages,
  individualParticipantMuteChange,
  getAccountInfo,
  addIndividualUserToThread,
  createIndividualThread,
  sendIndividualMessage,
  saveIndividualChatThreads,
  getChatThreadList
};
