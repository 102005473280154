import { setContosoUsersImage } from '../core/actions/ContosoClientAction';
import { connect } from 'react-redux';
import MediaGallery from '../components/MediaGallery';
import { getAllProfileImages, setMicrophone } from '../core/sideEffects';
import { State } from '../core/reducers';
import { setAttendeeDataList } from 'core/actions/sdk';
 
const mapStateToProps = (state: State) => ({
  userId: state.sdk.userId,
  displayName: state.calls.callAgent?.displayName,
  callAgent: state.calls.callAgent,
  remoteParticipants: state.calls.remoteParticipants,
  dominantParticipants: state.calls.dominantParticipants,
  user: state.contosoClient.user,
  images: state.contosoClient.images,
  groupId: state.calls.group,
  accountId: state.sdk.meetingData.AccountInfo.OrgAccountId,
  mic: state.controls.mic,
  local: state.sdk.localwaitinguser,
  attendeesData: state.sdk.attendeeData,
  cohosts: state.sdk.cohosts,
  settings: state.sdk.meetingData,
  connList: state.sdk.connectionList,
  raisehands: state.sdk.raiseHands
});
const mapDispatchToProps = (dispatch: any) => ({
  setProfileImage: async (images: any) => dispatch(setContosoUsersImage(images)),
  getAllProfileImgs: () => dispatch(getAllProfileImages()),
  setAttendeeData: (data: any): void => dispatch(setAttendeeDataList(data)),
  setMic: (mic: boolean): void => dispatch(setMicrophone(mic)),
});
const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(MediaGallery);
