import { connect } from 'react-redux';
import GroupCall, { GroupCallProps } from '../components/GroupCall';
import { endCall, setMicrophone } from '../core/sideEffects';
import { setVideoDeviceInfo, setAudioDeviceInfo, setAudioSpeakerDeviceInfo } from '../core/actions/devices';
import { AudioDeviceInfo, VideoDeviceInfo } from '@azure/communication-calling';
import { State } from '../core/reducers';
import { setCoHostList, setConnectionList, setIsMeetingLocked, setIsWaitingRoomEnabled, setRaiseHands, setWaitingList } from 'core/actions/sdk';

const mapStateToProps = (state: State, props: GroupCallProps) => ({
  userId: state.sdk.userId,
  user: state.contosoClient.user,
  callAgent: state.calls.callAgent,
  deviceManager: state.devices.deviceManager,
  group: state.calls.group,
  screenWidth: props.screenWidth,
  call: state.calls.call,
  shareScreen: state.controls.shareScreen,
  mic: state.controls.mic,
  groupCallEndReason: state.calls.groupCallEndReason,
  isGroup: (): boolean | undefined =>
    state.calls.call && state.calls.call.direction !== 'Incoming' && !!state.calls.group,
  remoteParticipants: state.calls.remoteParticipants,
  callState: state.calls.callState,
  localVideo: state.controls.localVideo,
  screenShareStreams: state.streams.screenShareStreams,
  videoDeviceInfo: state.devices.videoDeviceInfo,
  audioDeviceInfo: state.devices.audioDeviceInfo,
  audioSpeakerDeviceInfo: state.devices.audioSpeakerDeviceInfo,
  videoDeviceList: state.devices.videoDeviceList,
  audioDeviceList: state.devices.audioDeviceList,
  audioSpeakerDeviceList: state.devices.audioSpeakerDeviceList,
  cameraPermission: state.devices.cameraPermission,
  usersimgs :  state.contosoClient.images,
  microphonePermission: state.devices.microphonePermission,
  chatScreenItems: state.chat.items,
  waitList: state.sdk.waitingList,
  connList: state.sdk.connectionList,
  localuser: state.sdk.localwaitinguser,
  settings: state.sdk.meetingData,
  cohosts: state.sdk.cohosts,
  ismeetinglocked: state.sdk.isMeetingLocked,
  iswaitingenabled: state.sdk.isWaitingRoomEnabled,
  endAllCall: (): void => {
    state.calls.call && endCall(state.calls.call, { forEveryone: true });
  },
  raisehands: state.sdk.raiseHands
});

const mapDispatchToProps = (dispatch: any) => ({
  mute: (): void => dispatch(setMicrophone(false)),
  setAudioDeviceInfo: (deviceInfo: AudioDeviceInfo): void => {
    dispatch(setAudioDeviceInfo(deviceInfo));
  },
  setAudioSpeakerDeviceInfo: (deviceInfo: AudioDeviceInfo): void => {
    dispatch(setAudioSpeakerDeviceInfo(deviceInfo));
  },
  setVideoDeviceInfo: (deviceInfo: VideoDeviceInfo): void => {
    dispatch(setVideoDeviceInfo(deviceInfo));
  },
  setWaitingList:(list:any): void => dispatch(setWaitingList(list)),
  setConnectionList: (clist:any): void => dispatch(setConnectionList(clist)),
  setCoHost: (data: any): void => dispatch(setCoHostList(data)),
  setMic: (mic: boolean): void => dispatch(setMicrophone(mic)),
  setWaitingRoomEnabled: (iswaiting: boolean): void => dispatch(setIsWaitingRoomEnabled(iswaiting)),
  setMeetingLocked: (islocked: boolean): void => dispatch(setIsMeetingLocked(islocked)),
  setRaiseHand: (hands:[]): void => dispatch(setRaiseHands(hands))
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(GroupCall);
