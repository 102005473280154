import React, { useState, useEffect, useCallback, useRef } from 'react';
import GroupCall from './containers/GroupCall';
//import { createStore, applyMiddleware } from 'redux';
import { connect } from 'react-redux';
// import { reducer } from './core/reducers';
// import thunk from 'redux-thunk';
import EndCall from './containers/EndCall';
import HomeScreen from './containers/HomeScreen';
import ConfigurationScreen from './containers/Configuration';
import { v1 as createGUID } from 'uuid';
import { loadTheme, initializeIcons} from '@fluentui/react';
//import { utils } from './Utils/Utils';
import { CallEndReason } from '@azure/communication-calling';
import { createThread } from './core/sideEffects';
import {ErrorBoundary} from 'react-error-boundary';
//import axios from 'axios';
import ErrorFallback from './components/ErrorFallback';
import {  saveLogInfo } from './Utils/FileUpload';
import WaitingRoom from './containers/WaitingRoom';
import { State } from './core/reducers';
import { setConnectionList, setWaitingList } from 'core/actions/sdk';
//import { ORG_ACCOUNT_ID } from 'core/constants';
//import io from 'socket.io-client';
//const sdkVersion = require('../package.json').dependencies['@azure/communication-calling'];
//const lastUpdated = `Last Updated ${utils.getBuildTime()} with @azure/communication-calling:${sdkVersion}`;

// const appTheme: ITheme = createTheme({
//   palette: {
//     themePrimary: 'red'
    
//   }
// });
loadTheme({});
initializeIcons();


//const store = createStore(reducer, applyMiddleware(thunk));

export interface AppScreenProps {
  waitList: any;
  setWaitingList(list:any):void;
  setConnectionList(clist:any):void;
  connectionList:any;
}

const URL ='wss://7cebxadjoa.execute-api.us-east-1.amazonaws.com/production';

const App = (props: AppScreenProps): JSX.Element => {
  const [page, setPage] = useState('home');
  const [callEndReason, setCallEndReason] = useState<CallEndReason | undefined>();
  const [groupId, setGroupId] = useState('');
  const [threadId, setThreadId] = useState('');
  const [screenWidth, setScreenWidth] = useState(0);
  const [localVideoStream, setLocalVideoStream] = useState(undefined);
  const [errorobj, setErrorObj] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [connectionId, setConnectionId] = useState('');
  //const [waitlist, setWaitList] = useState<any>([]);
 
  const socket = useRef<WebSocket | null>(null);

  const unsupportedStateHandler = useCallback((): void => setPage('unsupported'), []);

  const onSocketOpen = useCallback(()=>{
     socket.current?.send(JSON.stringify({
      action:"getConnection"
     }));
     
    setIsConnected(true);
  },[]);

  const onSocketClose = useCallback(()=>{
    socket.current?.close();
    setIsConnected(false);
  },[]);



  const onSocketMessage = useCallback(async (data)=>{
    const socketData = JSON.parse(data);
    if(socketData.connectionId) setConnectionId(socketData.connectionId);
   
  },[]);

  const onConnect = useCallback(() => {
      if(socket.current?.readyState !== WebSocket.OPEN && !isConnected){
          socket.current = new WebSocket(URL);
          socket.current.addEventListener('open', onSocketOpen);
          socket.current.addEventListener('close', onSocketClose);
          socket.current.addEventListener('message', (event)=>{
            onSocketMessage(event.data)
          });
      }
    }, []);
  
  
  useEffect(() => {
    if(!isConnected){
      onConnect();
    }
   
     return () => {
      socket.current?.close();
      };
  },[]);

  useEffect(() => {
    const setWindowWidth = (): void => {
      const width = typeof window !== 'undefined' ? window.innerWidth : 0;
      setScreenWidth(width);
    };
    setWindowWidth();
    window.addEventListener('resize', setWindowWidth);
    return (): void => window.removeEventListener('resize', setWindowWidth);
  }, []);

  const getGroupIdFromUrl = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('groupId');
  };

  const getGroupId = (): string => {
   
    if (groupId) return groupId;
    const uriGid = getGroupIdFromUrl();
    const gid = uriGid == null || uriGid === '' ? createGUID() : uriGid;
    setGroupId(gid);
    getThreadId(gid);
    return gid;
  };

  const getThreadIdFromUrl = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('threadId');
  };
  
  const getThreadId = (gid:any) => {
    if (threadId) return threadId;
    const uriTid = getThreadIdFromUrl();
    let tid: any = uriTid == null || uriTid === '' || typeof uriTid !== 'string' ?   createThread(gid) : uriTid;
    setThreadId(tid);
   
    return tid;
  };

 
  const ErrorFallbackfnc = ({error, resetErrorBoundary} : any)=> {
    
    return (
      <ErrorFallback errorMessage={error.message} />
    )
  };

  const myErrorHandler = async (error: Error, info: {componentStack: string}) => {
    saveLogInfo({
      gId: groupId ? groupId : getGroupIdFromUrl(),
      accountId: 14521,
      logTime: new Date().toLocaleString(),
      logerror: error
    });
    // let eobj = {
    //   groupId: groupId ? groupId : getGroupIdFromUrl(),
    //   //clientId: threadId ? threadId : '',
    //   logTime: new Date().toLocaleString(),
    //   logerror: error
    // }
    // let postObj = {
    //   httpMethod: 'POST',
    //   path: '/logerror',
    //   body: eobj
    // };
    // await axios.post(' https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/logerror',postObj);
    //await axios.post('http://localhost:5000/logerror', eobj);
  };


 useEffect(()=>{
   startErrorLog()
 },[])

const startErrorLog = () => {
  window.addEventListener("error", async(event) => {
  
    setErrorObj(event.message)
    // if(page === 'configuration' || page === 'call'){
    //   setPage('endCall')
    // }else{
    //   setPage('error');
    // }
    saveLogInfo({
      gId: groupId ? groupId : getGroupIdFromUrl(),
      accountId: 14521,
      logTime: new Date().toLocaleString(),
      logerror: event.message
    });
    // let eobj = {
    //   groupId: groupId ? groupId : getGroupIdFromUrl(),
    //   //clientId: threadId ? threadId : '',
    //   logTime: new Date().toLocaleString(),
    //   logerror: event.message
    // }
    // let postObj = {
    //   httpMethod: 'POST',
    //   path: '/logerror',
    //   body: eobj
    // };
    // await axios.post(' https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/logerror',postObj);
    //await axios.post('http://localhost:5000/logerror', eobj);
   
  });

  window.addEventListener("unhandledrejection", async(event: PromiseRejectionEvent) => {
  
    // let eobj = {
    //   groupId: groupId ? groupId : getGroupIdFromUrl(),
    //   //clientId: threadId ? threadId : '',
    //   logTime: new Date().toLocaleString(),
    //   logerror: event.reason.toString()
    // }
    setErrorObj(event.reason)
    // if(page === 'configuration' || page === 'call'){
    //   setPage('endCall')
    // }else{
    //   setPage('error');
    // }
    saveLogInfo({
      gId: groupId ? groupId : getGroupIdFromUrl(),
      accountId: 14521,
      logTime: new Date().toLocaleString(),
      logerror: event.reason.toString()
    });
    // let postObj = {
    //   httpMethod: 'POST',
    //   path: '/logerror',
    //   body: eobj
    // };
    // await axios.post(' https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/logerror',postObj);
    //await axios.post('http://localhost:5000/logerror', eobj);
  });
}
const reloadHandler = () => {
  setPage('configuration');
  setPage('call');
};

const setWaitingRoom = (iswaiting:Boolean) => {
   if(iswaiting) {
    setPage('waiting');
   }else{
    setPage('call');
   }
}

const setwaitingHandler = (admitted: Boolean) => {
  if(admitted) {
    setPage('call');
   }else{
    setPage('waiting');
   }
};

  const getContent = (): JSX.Element => {
    if (page === 'home') {
      return (
        <HomeScreen
          startCallHandler={(): void => {
           window.history.pushState({}, document.title, window.location.href + '?groupId=' + getGroupId());
          }}
          
        />
      );
    } else if (page === 'configuration') {
     
      return (
        
        <ConfigurationScreen
          startCallHandler={setWaitingRoom}
          unsupportedStateHandler={unsupportedStateHandler}
          callEndedHandler={(errorMsg: CallEndReason): void => {
            setCallEndReason(errorMsg);
            setPage('error');
          }}
          groupId={getGroupId()}
          threadId={threadId}
          screenWidth={screenWidth}
          localVideoStream={localVideoStream}
          setLocalVideoStream={setLocalVideoStream}
          socket={socket}
          connectionId={connectionId}
        />
    
      );
    } else if (page === 'call') {
      return (
        
        <GroupCall
          endCallHandler={(): void => setPage('endCall')}
          groupId={getGroupId()}
          threadId={threadId}
          screenWidth={screenWidth}
          localVideoStream={localVideoStream}
          setLocalVideoStream={setLocalVideoStream}
          reloadCallHandler={reloadHandler}
          socket={socket}
          endMeetingForAll={(): void => {
            window.location.href = window.location.href.split('?')[0];
          }}
        />
        
      );
    }else if (page === 'waiting') {
      return (
        
        <WaitingRoom socket={socket} waitingHandler={setwaitingHandler} removeHandler={(): void => {
          window.location.href = window.location.href.split('?')[0];
        }}/>
        
      );
    } else if (page === 'endCall') {
      return (
        <EndCall
          message={errorobj ? `Oops! Something went wrong. Please try again.`:`You are about to leave the call`}
          rejoinHandler={(): void => {
           // window.location.reload();
            setPage('call');
          }}
          homeHandler={(): void => {
            window.location.href = window.location.href.split('?')[0];
          }}
          
        />
      );
    } else if (page === 'unsupported') {
      window.document.title = 'Unsupported browser';
      return (
        <>
          <div>We are not supporting this Browser, please contact our customer support for any information.</div>
          
        </>
      );
    } else if (page === 'error') {
      window.document.title = 'Call Ended';
      return (
        <div>

          {callEndReason && <div>{`The call has ended with this error code (Code: ${callEndReason.code} Subcode: ${callEndReason.subCode})`}</div> }

          {errorobj && <div><h2>Oops!</h2><br/>{`Something went wrong. Please try again.`}<br/>
          {/* {`${errorobj}`} */}
          </div>}
        </div>
      );
    } else {
      return <></>;
    }
  };

  if (getGroupIdFromUrl() && getThreadIdFromUrl()  && page === 'home') {
    setPage('configuration');
  }

  return <ErrorBoundary FallbackComponent={ErrorFallbackfnc} onError={myErrorHandler}>{getContent()}</ErrorBoundary>;
};

// window.setTimeout(() => {
//   try {
//    // console.log(`Azure Communication Services sample group calling app: ${lastUpdated}`);
//   } catch (e) {}
// }, 0);
const mapStateToProps = (state: State) => ({
    waitList: state.sdk.waitingList,
    connectionList: state.sdk.connectionList
});

const mapDispatchToProps = (dispatch: any) => ({
  setWaitingList:(list:any): void => dispatch(setWaitingList(list)),
  setConnectionList: (clist:any): void => dispatch(setConnectionList(clist))
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(App);

