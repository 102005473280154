import { connect } from 'react-redux';
import WaitingRoom, { WaitingRoomProps } from '../components/WaitingRoom';
import { TokenResponse } from '../components/Configuration';
import { addUserToThread } from '../core/sideEffects';
import { setCallAgent } from '../core/actions/calls';
import { initCallClient, joinGroup, registerToCallAgent } from '../core/sideEffects';
import { State } from '../core/reducers';
import {
  LocalVideoStream,
  CallAgent,
  CallEndReason
} from '@azure/communication-calling';
import { CommunicationUserToken } from '@azure/communication-identity';
import { utils } from '../Utils/Utils';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { setUserId, setWaitingList } from 'core/actions/sdk';


const mapStateToProps = (state: State, props: WaitingRoomProps) => ({
  call: state.calls.call,
  user: state.contosoClient.user,
  userId: state.contosoClient.user.identity,
  connectionList: state.sdk.connectionList,
  group: state.calls.group,
  waitList: state.sdk.waitingList,
  localuser: state.sdk.localwaitinguser,
  joinGroup: async (callAgent: CallAgent, groupId: string, localVideoStream: LocalVideoStream): Promise<void> => {
    callAgent &&
      (await joinGroup(
        callAgent,
        {
          groupId
        },
        {
          videoOptions: {
            localVideoStreams: localVideoStream ? [localVideoStream] : undefined
          },
          audioOptions: { muted: !state.controls.mic }
        }
      ));
  },
  getToken: async (): Promise<TokenResponse> => {
    //const urlParams = new URLSearchParams(window.location.search);
    const tokenResponse: CommunicationUserToken = await utils.getTokenForUser();
    const userToken = tokenResponse.token;
    const userId = tokenResponse.user.communicationUserId;

    const tokenCredential = new AzureCommunicationTokenCredential({
      tokenRefresher: (): Promise<string> => {
        return utils.getRefreshedTokenForUser(userId);
      },
      refreshProactively: true,
      token: userToken
    });

    return {
      tokenCredential,
      userId
    };
  },
  createCallAgent: async (
    tokenCredential: AzureCommunicationTokenCredential,
    displayName: string
  ): Promise<CallAgent> => {
    const callClient = state.sdk.callClient;

    if (callClient === undefined) {
      throw new Error('CallClient is not initialized');
    }

    const callAgent: CallAgent = await callClient.createCallAgent(tokenCredential, { displayName });
    return callAgent;
  }
 });

 const mapDispatchToProps = (dispatch: any) => ({
   
    setupCallClient: (unsupportedStateHandler: () => void): void => dispatch(initCallClient(unsupportedStateHandler)),
    registerToCallEvents: async (
      userId: string,
      callAgent: CallAgent,
      endCallHandler: (reason: CallEndReason) => void
    ): Promise<void> => {
      dispatch(setUserId(userId));
      dispatch(setCallAgent(callAgent));
      dispatch(registerToCallAgent(userId, callAgent, endCallHandler));
    },
  
    setup: async (displayName: string, userEmail: string, profileImage: File) => {
      dispatch(addUserToThread(displayName, userEmail, profileImage));
    },
    setWaitingList:(list:any): void => dispatch(setWaitingList(list)),
  });
  
const connector: any = connect(mapStateToProps, mapDispatchToProps);


export default connector(WaitingRoom);
