import React, { useEffect, useState } from 'react';
import { Spinner, Stack, Text } from '@fluentui/react';
import { Attachment, DownloadIcon, FilesEmptyIcon } from '@fluentui/react-northstar';
import AutoDownloadLink from './AutoDownloadLink';
import { imageLoadingStackStyle, imagePreviewStyle } from './styles/FileAttachmentMessage.styles';
//import { DOWNLOAD_SUB_URL } from '../core/constants';

export interface FileAttachmentMessageProps {
  fileId: string;
  fileName: string;
  blobUrl: any;
  showPreview?: boolean;
  downloadFile: (fileId: string) => void;
  clearFileBlobUrl: (fileId: string) => void;
  accountId:any;
}

// Checks if a file is a previewable image based on the filename
function isPreviewableImage(fileName: string): boolean {
  return (/\.(png|jpg|jpeg)$/i).test(fileName);
}

export default (props: FileAttachmentMessageProps): JSX.Element => {
  const [downloadClicked, setDownloadClicked] = useState<boolean>(false);
  const [newBlobURL, setNewBlobURL] = useState<any>('');
  const fId = props.fileId+'#'+props.fileName;
 
  let blobDependency = props.blobUrl === null || typeof props.blobUrl === 'object' ? true: false;
  useEffect(() => {
    if (props.showPreview && isPreviewableImage(props.fileName) && blobDependency) {
      props.downloadFile(fId);
    }
  }, [props.showPreview, props.fileName, blobDependency, props.fileId, props.downloadFile, fId]);

  useEffect(() => {
    if (props.fileName && props.fileId && blobDependency ) {
      const urlParams = new URLSearchParams(window.location.search);
      const threadId =  urlParams.get('threadId');
      const groupId =  urlParams.get('groupId');
      const fname = `${threadId}/${fId}`;
      const finalname = fname.replace(/@/gi, "%40").replace(/#/gi, "%23").replace(/:/gi, "%3A").replaceAll(" ", "%20");
      // fname = fname.replace(/#/gi, "%23");
      // fname = fname.replace(/:/gi, "%3A");
      // fname = fname.replaceAll(" ", "%20");
      
      //let blobURL:any = DOWNLOAD_SUB_URL+groupId+'/attachments/'+fname;
      
      const blobURL:any = `https://gatheringjar.blob.core.windows.net/clickandpledge/${props.accountId}/gatheringjar/attachments/${groupId}/${finalname}`;
      setNewBlobURL(blobURL);
      //console.log("Sub== NewBlobURL",newBlobURL)
    }
  }, [blobDependency, newBlobURL, fId]);

  if (props.showPreview && isPreviewableImage(props.fileName)) {
    return props.blobUrl !== null && props.blobUrl.constructor !== Object &&  isPreviewableImage(props.blobUrl.split('#').pop())
      ? (
        <img src={props.blobUrl} className={imagePreviewStyle} />
      ): newBlobURL != "" && isPreviewableImage(newBlobURL.split('#').pop())
      ? (
        <img src={newBlobURL} className={imagePreviewStyle} />
      )
      : (
        <Stack horizontalAlign="center" verticalAlign="center" className={imageLoadingStackStyle}>
          <Spinner />
          <Text>Loading image...</Text>
        </Stack>
      );
  }

  return (
    <>
      <Attachment
        header={props.fileName}
        icon={<FilesEmptyIcon outline />}
        action={{
          styles: { marginLeft: '1rem' },
          icon: <DownloadIcon />,
          loading: downloadClicked,
          disabled: downloadClicked,
          onClick: (e) => {
            setDownloadClicked(true);
            if (!props.blobUrl) {
              props.downloadFile(fId);
            }

            e.stopPropagation();
          },
        }}
        actionable
        onClick={() => console.log(`Debug== Attachment clicked for file ${props.fileId}`)}
      />
      {(downloadClicked && (props.blobUrl !== null || newBlobURL)) &&
        <AutoDownloadLink
          link={props.blobUrl !==null ? props.blobUrl : newBlobURL}
          downloadName={props.fileName}
          onTriggered={() => {
            setDownloadClicked(false);
            
            // Don't clear blob URLs for previewable images since the URLs are useful for previews
            // if (!isPreviewableImage(props.fileName)) {
            //      props.clearFileBlobUrl(props.fileId);
            //   //TODO: should also revoke the object URL somewhere
            // }
          }}
        />
      }
    </>
  );
}