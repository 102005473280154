import { connect } from 'react-redux';
import { getAllFiles } from '../core/sideEffects';
import ChatScreen from '../components/ChatScreen';
import { State } from '../core/reducers';

const mapStateToProps = (state: State) => ({
  isRemoved: state.threadMembers.isRemoved
});
const mapDispatchToProps = (dispatch: any) => ({
  // getThreadMembers: () => dispatch(getThreadMembers()),
  // getThread: () => dispatch(getThread()),
  // getMessages: () => dispatch(getMessages()),
  getFiles: () => dispatch(getAllFiles()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChatScreen);
