import { ChatClient } from '@azure/communication-chat';

import {
  ContosoActionTypes,
  SET_CHAT_CLIENT,
  SET_CURRENT_USER,
  SET_USERS,
  SET_USERS_IMAGE,
  Users,
  SET_CURRENT_USER_COOL_PERIOD,
  SET_INDIVIDUAL_CHAT_CLIENTS
} from '../actions/ContosoClientAction';

export interface User {
  identity: string;
  token?: string;
  displayName: string;
  userEmail?: string;
  profileImage?: any;
  memberRole?: String;
  coolPeriod?: Date;
}

export interface ContosoState {
  chatClient: ChatClient | undefined;
  user: User;
  users: Users;
  images: string[];
  chatClients: any;
}

const initContosoState: ContosoState = {
  chatClient: undefined,
  user: { identity: '', token: '', displayName: '', userEmail:'', profileImage:'', memberRole: '' },
  users: {},
  images:[],
  chatClients:[],
};

export const ContosoReducer = (state = initContosoState, action: ContosoActionTypes) => {
  switch (action.type) {
    case SET_CHAT_CLIENT:
      return {
        ...state,
        chatClient: action.chatClient
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: {
          identity: action.identity,
          token: action.token,
          displayName: action.displayName,
          userEmail: action.userEmail,
          profileImage: action.profileImage,
          memberRole: action.memberRole
        }
      };
      case SET_CURRENT_USER_COOL_PERIOD:
        return {
          ...state,
          user: {
            identity: state.user.identity,
            token: state.user.token,
            displayName: state.user.displayName,
            memberRole: state.user.memberRole,
            coolPeriod: action.coolPeriod
          }
        };  
    case SET_USERS:
      return {
        ...state,
        users: Object.assign({}, action.users)
      };
    case SET_USERS_IMAGE:
        return {
          ...state,
          images: [...action.images]
        };  
    case SET_INDIVIDUAL_CHAT_CLIENTS:
          return {
            ...state,
            chatClients: [...action.chatClients]
          };    
    default:
      return state;
  }
};
