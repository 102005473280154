import React, { useEffect, useState } from 'react';
import { Stack, Spinner, PrimaryButton, TextField, Icon, Modal, ResponsiveMode, Checkbox } from '@fluentui/react';
import LocalPreview from './LocalPreview';
import LocalSettings from './LocalSettings';
import DisplayNameField from './DisplayNameField';
import {
  VideoDeviceInfo,
  AudioDeviceInfo,
  LocalVideoStream,
  DeviceManager,
  CallAgent,
  CallEndReason
} from '@azure/communication-calling';
import { VideoCameraEmphasisIcon } from '@fluentui/react-icons-northstar';
import {
  videoCameraIconStyle,
  configurationStackTokens,
  buttonStyle,
  localSettingsContainerStyle,
  mainContainerStyle,
  fullScreenStyle,
  verticalStackStyle,
  inputBoxStyle,
  inputBoxTextStyle,
  inputBoxWarningStyle,
  labelFontStyle,
  warningStyle,
  otpInputStyle,
  validOtpStyle,
  InvalidOtpStyle,
  OtpCheckStyle,
  containerModalStyles
} from './styles/Configuration.styles';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { ORG_ACCOUNT_ID } from '../core/constants';
import axios from 'axios';
//import { Input } from '@fluentui/react-northstar';
import { iconStyle } from './styles/HomeScreen.styles';
import './styles/Configuration.css';
import { connectionList, saveMeetingInfo } from '../Utils/FileUpload';
//import { Input } from '@fluentui/react-northstar';


export type TokenResponse = {
  tokenCredential: AzureCommunicationTokenCredential;
  userId: string;
};

export interface ConfigurationScreenProps {
  userId: string;
  groupId: string;
  callAgent: CallAgent;
  deviceManager: DeviceManager;
  setupCallClient(unsupportedStateHandler: () => void): void;
  setupCallAgent(
    displayName: string,
    groupId: string,
    afterSetupHandler: (callAgent: CallAgent, groupId: string) => void
  ): void;
  setGroup(groupId: string): void;
  setThreadId(threadId: string): void;
  startCallHandler(isWaiting: Boolean): void;
  unsupportedStateHandler: () => void;
  callEndedHandler: (reason: CallEndReason) => void;
  videoDeviceList: VideoDeviceInfo[];
  audioDeviceList: AudioDeviceInfo[];
  audioSpeakerDeviceList: AudioDeviceInfo[];
  setVideoDeviceInfo(device: VideoDeviceInfo): void;
  setAudioDeviceInfo(device: AudioDeviceInfo): void;
  setAudioSpeakerDeviceInfo(device: AudioDeviceInfo): void;
  mic: boolean;
  setMic(mic: boolean): void;
  setLocalVideoStream(stream: LocalVideoStream | undefined): void;
  localVideoRendererIsBusy: boolean;
  videoDeviceInfo: VideoDeviceInfo;
  audioDeviceInfo: AudioDeviceInfo;
  audioSpeakerDeviceInfo: AudioDeviceInfo;
  localVideoStream: LocalVideoStream;
  screenWidth: number;
  joinGroup(callAgent: CallAgent, groupId: string): void;
  getToken(): Promise<TokenResponse>;
  createCallAgent(tokenCredential: AzureCommunicationTokenCredential, displayName: string): Promise<CallAgent>;
  registerToCallEvents(
    userId: string,
    callAgent: CallAgent,
    endCallHandler: (reason: CallEndReason) => void
  ): Promise<void>;
  joinChatHandler(): void;
  setup(displayName: string, userEmail: string, profileImage: any): void;
  isValidThread(threadId: string | null): any;
  threadId: string;
  setMeetingInfo(meetingData:any): void;
  socket: any;
  waitingList: [];
  setWaitingList(list:any): void;
  connectionId:any;
  setConnectionList(clist:any): void;
  setLocalWaitingUser(user:any): void;
  connList: any;
  setCoHost(hosts:any):void;
  setIsWaitingEnabled(isenabled:boolean):void;
  setIsMeetingLock(islocked:boolean):void;
  isLocked: boolean;
  iswaitingEnabled: boolean;
}

export default (props: ConfigurationScreenProps): JSX.Element => {
  const spinnerLabel = 'Initializing call ...';
  const buttonText = 'Join call';
  const TextFieldStyleProps = {
    wrapper: {
      height: '2.3rem'
    },
    fieldGroup: {
      height: '2.3rem'
    }
  };

  const createUserId = (): any => {
  const userId = localStorage.getItem("validatedUser") ? localStorage.getItem("validatedUser") : 'user' + Math.ceil(Math.random() * 1000);
   return userId;
  } 

  const [name, setName] = useState(createUserId());
  const [email, setEmail] = useState<any>(localStorage.getItem("validatedEmail") ? localStorage.getItem("validatedEmail") : '');
  //const [profileImage, setProfileImage] = useState("");
  const [emailError, setEmailError] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [emptyWarning, setEmptyWarning] = useState(false);
  const [isduplicateuser, setIsDuplicateUsers] = useState(false);
 // const [isvalidFile, setIsValidFile] = useState(true);
  const [otp, setOTP] = useState<number>();
  const [checkotp, setCheckOTP] = useState("");
  const [validotp, setValidOTP] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [ismodalopen, setIsModalOpen] = useState(true);
  const [otpbuttondisabled, setOtpButtonDisabled] = useState(false);
  const [rememberme, setRememberMe] = useState(false);
  const [orgAccId, setOrgAccId] = useState('');
  const [host, setHost] = useState('');
  //const [isWaitingEnabled, setIsWaitingEnabled] = useState(false);
  // const [isNameLengthExceedLimit, setNameLengthExceedLimit] = useState(false);

  // const [selectedAvatar, setSelectedAvatar] = useState(CAT);

  // const [isJoining, setIsJoining] = useState(false);

  //const [isValidThread, setIsValidThread] = useState<boolean | undefined>(undefined);

  const { groupId, setupCallClient, setGroup, setThreadId, unsupportedStateHandler, setup, threadId } = props;


  // useEffect(() => {
  //   const isValidThread = async () => {
  //     if (await isValidThreadProp(getThreadId())) {
  //       setIsValidThread(true);
  //     } else {
  //       setIsValidThread(false);
  //     }
  //   };
  //   isValidThread();

  // }, [isValidThreadProp]);
  // const simpleStringify = (object: any) => {
  //   let simpleObject: any = {};
  //     for (var prop in object ){
  //         if (!object.hasOwnProperty(prop)){
  //             continue;
  //         }
  //         if (typeof(object[prop]) == 'object'){
  //             continue;
  //         }
  //         if (typeof(object[prop]) == 'function'){
  //             continue;
  //         }
  //         simpleObject[prop] = object[prop];
  //     }
  //     return JSON.stringify(simpleObject);
  // }


  const savetos3 = async (cagent: any, gId: string) => {
    /** save meeting info to s3 */
    let obj: any = {};
    obj[gId] = {};
    obj[gId]['meetingId'] = gId;
    obj[gId]['accountId'] = ORG_ACCOUNT_ID;
    obj[gId]['meetingStartedAt'] = new Date().toLocaleString();
    obj[gId]['meetingStatus'] = 'Ongoing';
    obj[gId][cagent.clientId] = {};
    obj[gId][cagent.clientId]['callStack'] = {};
    obj[gId][cagent.clientId]['calls'] = {};
    obj[gId][cagent.clientId]['clientId'] = cagent.clientId;
    obj[gId][cagent.clientId]['displayName'] = cagent.displayName;
    obj[gId][cagent.clientId]['email'] = email;
    obj[gId][cagent.clientId]['resourceId'] = cagent._resourceId;
    obj[gId][cagent.clientId]['callStack']['acsResourceId'] = cagent._callStack.acsResourceId;
    obj[gId][cagent.clientId]['calls']['id'] = cagent._calls[0]._id;
    obj[gId][cagent.clientId]['calls']['state'] = cagent._calls[0]._state;
    //obj[gId][cagent.clientId]['calls']['callStartedAt'] = cagent._calls[0]._info._tsCall.callStartedAt;
    obj[gId][cagent.clientId]['calls']['callStartedAt'] = new Date().toLocaleString();
    obj[gId][cagent.clientId]['calls']['callId'] = cagent._calls[0]._info._tsCall.callStats.callId;
    obj[gId][cagent.clientId]['calls']['participantId'] = cagent._calls[0]._info._tsCall.callStats.participantId;
    obj[gId][cagent.clientId]['calls']['acsResourceId'] = cagent._callStack.acsResourceId;

    saveMeetingInfo({
      accountId: orgAccId,
      gId:gId,
      data: obj
    });

    // let reqpostObj = {
    //   httpMethod: 'POST',
    //   path: '/savecallinfos3',
    //   body: obj
    // }
    // await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/savecallinfos3', reqpostObj);
  }

  useEffect(() => {
    setupCallClient(unsupportedStateHandler);
  }, [setupCallClient, unsupportedStateHandler]);

  useEffect(()=>{
       axios.get(`./meetingCredentials.json`).then((resjson)=>{
       
         if(resjson.data){
          setHost(resjson.data.MeetingSettings.host[0]);
          props.setMeetingInfo(resjson.data);
          setOrgAccId(resjson.data.AccountInfo.OrgAccountId);
          props.setCoHost(resjson.data.MeetingSettings.cohosts);
          props.setIsWaitingEnabled(resjson.data.MeetingSettings.showWaitingRoom);
          //setIsWaitingEnabled(resjson.data.MeetingSettings.showWaitingRoom);
          props.setIsMeetingLock(resjson.data.MeetingSettings.isMeetingLocked);
        }
       }).catch(err => console.log(err));
   },[]);

  const setuserName = async (uname: string) => {

    //   let postobj = {
    //       body:{
    //        groupId: groupId
    //       }
    //   };
    //   const ulist = await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/getjoinedusers',postobj);
    //  if( ulist.data && ulist.data.body.length && ulist.data.body.indexOf(uname) > -1){
    //     setIsDuplicateUsers(true);
    //   }else{
    setIsDuplicateUsers(false);
    //}

  };

  const onChangeEmail = (e: any) => {
    let useremail = e.target.value;
    setEmail(useremail);
    setEmailTouched(true);
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(useremail)) {
      setEmailError('');

    } else {
      setEmailError('Enter valid Email!')
    }
  }

  const sendOtp = (e: any) => {
    if(!email){ 
      setEmailError('Enter valid Email!');
      return;
    }
    setOtpButtonDisabled(true);
    let genOTP = Math.floor(Math.random() * 900000) + 100000;
    let mailbodyreq = {
      httpMethod: 'POST',
      path: '/sendmail',
      body: {
        otp: genOTP, 
        toEmail: email
      }
    };
    axios.post(`https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/sendmail`,mailbodyreq).then((res)=>{
      setOTP(genOTP);
      if(rememberme){
        localStorage.setItem("validatedUser", name);
        localStorage.setItem("validatedEmail", email);
        localStorage.setItem("rememberMe", "true");
      }
      
    });
  }

  

  const onChangeOTP = (e: any) => {
    setCheckOTP(e.target.value);
    
    if (e.target.value == otp){
      setValidOTP(false)
    }else{
      setValidOTP(true)
    }
  }




  return (
    <Stack className={mainContainerStyle} horizontalAlign="center" verticalAlign="center">

      {props.deviceManager ? (
        <Stack
          className={props.screenWidth > 750 ? fullScreenStyle : verticalStackStyle}
          horizontal={props.screenWidth > 750}
          horizontalAlign="center"
          verticalAlign="center"
          tokens={props.screenWidth > 750 ? configurationStackTokens : undefined}
        >

          {ismodalopen && (

            <Modal
              titleAriaId={"abc"}
              isOpen={ismodalopen}
              //onDismiss={hideModal}
              isBlocking={true}
              className={containerModalStyles}
              responsiveMode={ResponsiveMode.small}
            //dragOptions={isDraggable ? dragOptions : undefined}
            >
             {props.isLocked && <div style={{color:'red',fontSize:'18px',justifyContent:'center'}}>This meeting has been locked.</div>}
              <DisplayNameField setName={setName} setuserName={setuserName} name={name} setEmptyWarning={setEmptyWarning} isEmpty={emptyWarning} isnameValid={isduplicateuser} /><br />
              <div className={labelFontStyle}>Email <span style={{ color: 'red' }}>*</span></div>
              <div style={{ display: 'flex' }}>
                <TextField
                  autoComplete="off"
                  inputClassName={inputBoxTextStyle}
                  ariaLabel="Choose your email"
                  borderless={true}
                  className={emailTouched && email && emailError ? inputBoxWarningStyle : inputBoxStyle}
                  onChange={onChangeEmail}
                  id="name"
                  placeholder="Enter your email"
                  styles={TextFieldStyleProps}
                  value={email}
                //onBlur={sendOtp}
                />
                <PrimaryButton text="Send OTP" disabled={localStorage.getItem("validatedUser") === name && localStorage.getItem("validatedEmail") === email && props.isLocked ? true : otpbuttondisabled} className='send-otp-button' onClick={sendOtp} />
              </div>
              {otp && !checkotp && email && <span style={{ color: 'green', fontSize: '11px', fontWeight: '500' }}>OTP sent to your email!</span>}
              {!email && emailTouched && (
                <div role="alert" className={warningStyle}>
                  {' '}
                  Email cannot be empty{' '}
                </div>
              )}
              {emailError && (
                <div role="alert" className={warningStyle}>
                  {'Enter valid email '}
                  {' '}
                </div>
              )}
  
              {otp && email && (
                <div className={OtpCheckStyle}>
                  <TextField
                    autoComplete="off"
                    inputClassName={inputBoxTextStyle}
                    borderless={true}
                    className={validotp && checkotp ? inputBoxWarningStyle + " " + otpInputStyle : inputBoxStyle + " " + otpInputStyle}
                    onChange={onChangeOTP}
                    id="otp"
                    placeholder="Verify OTP.."
                    styles={TextFieldStyleProps}
                  // onBlur={validateotp}
                  />
                  {checkotp && <><Icon className={`${iconStyle} ${validotp ? InvalidOtpStyle : validOtpStyle}`} iconName={"SkypeCircleCheck"} /> {!validotp ? "Verified" : "Not Verified"}</>}
                </div>
              )
              }
              <div style={{clear:'both'}}>
              <br/><Checkbox label="Remember Me" checked={localStorage.getItem("rememberMe")==="true" ? true: rememberme} onChange={()=>setRememberMe(!rememberme)}/>

              </div>
              
              <br />
              {/* <div className={labelFontStyle} style={{ width: '100%' }}>Profile Image</div>
              <Input type="file" onChange={onFileChange} />
              {!isvalidFile && (
                <div role="alert" className={warningStyle}>
                  {'File is not valid '}
                  {' '}
                </div>
              )} */}
            {/* } else if (validotp) { */}
              <PrimaryButton
                text="Start Call"
                className='verify-config-button'
                disabled={props.isLocked}
                onClick={async (): Promise<void> => {
                   if (!name) {
                    setEmptyWarning(true);
                  }else if (isduplicateuser) {
                    setIsDuplicateUsers(true)
                  } else if (!email || emailError) {
                    setEmail(email);
                    setEmailTouched(true);
                  }else if(localStorage.getItem("rememberMe") && localStorage.getItem("validatedUser") === name && localStorage.getItem("validatedEmail") === email){
                    setIsModalOpen(false);
                  }else if (validotp || !checkotp) {} else {
                    setIsModalOpen(false);
                  }
                   //setIsModalOpen(false);
                 
                }}
              />
            </Modal>

          )}

          <LocalPreview
            mic={props.mic}
            setMic={props.setMic}
            setLocalVideoStream={props.setLocalVideoStream}
            videoDeviceInfo={props.videoDeviceInfo}
            audioDeviceInfo={props.audioDeviceInfo}
            audioSpeakerDeviceInfo={props.audioSpeakerDeviceInfo}
            localVideoStream={props.localVideoStream}
            videoDeviceList={props.videoDeviceList}
            audioDeviceList={props.audioDeviceList}
            audioSpeakerDeviceList={props.audioSpeakerDeviceList}
          />
          <Stack className={localSettingsContainerStyle}>
            {name && <div className={labelFontStyle}>User: {name}</div>}

            {/* <DisplayNameField setName={setName} setuserName={setuserName} name={name} setEmptyWarning={setEmptyWarning} isEmpty={emptyWarning} isnameValid={isduplicateuser}/><br/>
            <div className={labelFontStyle}>Email <span style={{color:'red'}}>*</span></div>
             <TextField
              autoComplete="off"
              inputClassName={inputBoxTextStyle}
              ariaLabel="Choose your email"
              borderless={true}
              className={emailTouched && email && emailError ? inputBoxWarningStyle  : inputBoxStyle}
              onChange={onChangeEmail}
              id="name"
              placeholder="Enter your email"
              styles={TextFieldStyleProps}
              onBlur={sendOtp}
            />
            {otp && !checkotp && email && <span style={{color:'green',fontSize:'11px',fontWeight:'500'}}>OTP sent to your email!</span>}
            {!email && emailTouched && (
              <div role="alert" className={warningStyle}>
                {' '}
                Email cannot be empty{' '}
              </div>
            )}
            {emailError && (
              <div role="alert" className={warningStyle}>
                {'Enter valid email '}
                {' '}
              </div>
            )}

           {otp && email && (
             <div className={OtpCheckStyle}>
              <TextField
              autoComplete="off"
              inputClassName={inputBoxTextStyle}
              borderless={true}
              className={validotp && checkotp ? inputBoxWarningStyle+ " "+otpInputStyle  : inputBoxStyle+ " "+otpInputStyle}
              onChange={onChangeOTP}
              id="otp"
              placeholder="Verify OTP.."
              styles={TextFieldStyleProps}
             // onBlur={validateotp}
            />
            {checkotp && <><Icon className={`${iconStyle} ${validotp ? InvalidOtpStyle:validOtpStyle}`} iconName={"SkypeCircleCheck"} /> {!validotp ? "Verified":"Not Verified"}</>} 
            </div>
            )
            }

            <br/>
            <div className={labelFontStyle}>Profile Image</div>
            <Input  type="file" onChange={onFileChange}/>
            {!isvalidFile && (
              <div role="alert" className={warningStyle}>
                {'File is not valid '}
                {' '}
              </div>
            )}
            <br/> */}
            {/* <div> */}
            <LocalSettings
              videoDeviceList={props.videoDeviceList}
              audioDeviceList={props.audioDeviceList}
              audioDeviceInfo={props.audioDeviceInfo}
              audioSpeakerDeviceInfo={props.audioSpeakerDeviceInfo}
              videoDeviceInfo={props.videoDeviceInfo}
              setVideoDeviceInfo={props.setVideoDeviceInfo}
              setAudioDeviceInfo={props.setAudioDeviceInfo}
              setAudioSpeakerDeviceInfo={props.setAudioSpeakerDeviceInfo}
              audioSpeakerDeviceList={props.audioSpeakerDeviceList}
              deviceManager={props.deviceManager}
            />
            {/* </div> */}
            <div style={{ display: 'flex' }}>
              <PrimaryButton
                className={buttonStyle}
                disabled={props.isLocked}
                onClick={async (): Promise<void> => {
                 
                  // if (!name ) {
                  //   setEmptyWarning(true);
                  // }else if(isduplicateuser){
                  //   setIsDuplicateUsers(true)
                  // }else if(!email || emailError){
                  //   setEmail(email);
                  //   setEmailTouched(true);
                  // } else if(!isvalidFile) { }else if(validotp){

                  // }else{
                  setShowLoader(true);
                  setEmptyWarning(false);
                  const conndata = await connectionList({
                    gId:groupId,
                    accountId: ORG_ACCOUNT_ID,
                    email: email,
                    connectionId: props.connectionId
                  });
                  if(conndata.data && conndata.data.connectionList){
                    props.setConnectionList(conndata.data.connectionList);
                  }
                
                  if(host == email || !props.iswaitingEnabled){
                     //1. Retrieve a token
                     const { tokenCredential, userId } = await props.getToken();
                     //2. Initialize the call agent
                     const callAgent = await props.createCallAgent(tokenCredential, name);
                      //3. Register for calling events
                      props.registerToCallEvents(userId, callAgent, props.callEndedHandler);
                      //4. Join the call
                      props.joinGroup(callAgent, groupId);
                      setup(name, email, '');
                      savetos3(callAgent, groupId);
                      
                    props.startCallHandler(false);
                  }else{
                    let admituser = [...props.waitingList, {name: name, email: email}];
                    //props.setWaitingList(admituser);
                    props.socket.current?.send(JSON.stringify({
                                    action: "sendMessage",
                                    message: {
                                      connections: conndata.data && conndata.data.connectionList,
                                      data: admituser,
                                      type:"waitingusers"
                                    }
                                  }))
                   
                     //props.socket.emit('joinRoom',admituser);
                    props.startCallHandler(true);
                  }
                  props.setLocalWaitingUser({name: name, email: email});
                  setGroup(groupId);
                  if (typeof threadId === 'string' && threadId !== '') {
                    setThreadId(threadId);
                  }
                  
                  setShowLoader(false);
                  // }
                }}
              >
                <VideoCameraEmphasisIcon className={videoCameraIconStyle} size="medium" />
                {buttonText}
              </PrimaryButton> {showLoader && <Spinner label="wait.." ariaLive="assertive" labelPosition="right" style={{ padding: '2rem 0 0 1rem' }} />}
            </div>
          </Stack>
        </Stack>
      ) : (
        <Spinner label={spinnerLabel} ariaLive="assertive" labelPosition="top" />
      )}
    </Stack>
  );
};
