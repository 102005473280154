import {  ChatMessage } from '@azure/communication-chat';
//import { CommunicationIdentifierKind } from '@azure/communication-common';

import {
  MessagesActionTypes,
  SET_MESSAGES,
  SET_TYPING_NOTIFICATIONS,
  SET_TYPING_USERS,
  SET_FAILED_MESSAGES,
  SET_NO_OF_CHATBOX,
  SET_INDIVIDUAL_MESSAGES,
  SET_WHOM_TO_CHAT
} from '../actions/MessagesAction';


interface ChatMessageWithClientMessageId extends ChatMessage {
  clientMessageId?: string;
}

export interface NormalChatMessage extends ChatMessageWithClientMessageId {
  extendedMessageType: 'None',
  from: string,
  to: string
}

export interface FileChatMessage extends ChatMessageWithClientMessageId {
  extendedMessageType: 'FileEvent';
  fileData: {
    id: string;
    name: string;
  };
}

export type ExtendedChatMessage = NormalChatMessage | FileChatMessage;

export interface MessagesState {
  messages: ExtendedChatMessage[];
  typingNotifications: any;
  typingUsers: any;
  failedMessages: string[];
  items: any;
  individualmessages: any;
  whom: any;
}

// model that allows us to track a message before its replicated on the server
// it also allows us to maintain state if the message was properly sent or not
// export interface ClientChatMessage {
//   clientMessageId?: string;
//   sender?: CommunicationIdentifierKind;
//   senderDisplayName?: string;
//   content?: { message?: string };
//   createdOn: Date;
//   id?: string;
//   failed?: boolean;
// }

const initMessagesState: MessagesState = {
  messages: [],
  typingNotifications: {},
  typingUsers: [],
  failedMessages: [],
  items: [],
  individualmessages: [],
  whom:{}
};

export const MessagesReducer = (state = initMessagesState, action: MessagesActionTypes) => {
  switch (action.type) {
    case SET_MESSAGES:
      return {
        ...state,
        messages: [...action.messages]
      };
    case SET_TYPING_NOTIFICATIONS:
      return {
        ...state,
        typingNotifications: {
          ...state.typingNotifications,
          [action.id]: action.typingNotification
        }
      };
    case SET_TYPING_USERS:
      return {
        ...state,
        typingUsers: [...action.typingUsers]
      };
    case SET_FAILED_MESSAGES:
      return {
        ...state,
        failedMessages: [...action.failedMessages]
      };
      case SET_NO_OF_CHATBOX:
      return {
        ...state,
        items: [...action.items]
      };
      case SET_INDIVIDUAL_MESSAGES:
      return {
        ...state,
        individualmessages: [...action.individualmessages]
      };
      case SET_WHOM_TO_CHAT:
      return {
        ...state,
        whom: action.whom
      };
    default:
      return state;
  }
};
