import { connect } from 'react-redux';
import Calling, { CallingProps, TokenResponse } from '../components/Calling';
import {  setPhonecall, endPhonecall } from '../core/sideEffects';
import { State } from '../core/reducers';
import {
  CallClient,
  CallAgent
} from '@azure/communication-calling';
import { CommunicationUserToken } from '@azure/communication-identity';
import { utils } from '../Utils/Utils';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';


const mapStateToProps = (state: State, props: CallingProps) => ({

  getToken: async (): Promise<TokenResponse> => {
   // const urlParams = new URLSearchParams(window.location.search);
    const tokenResponse: CommunicationUserToken = await utils.getTokenForUser();
    const userToken = tokenResponse.token;
    const userId = tokenResponse.user.communicationUserId;

    const tokenCredential = new AzureCommunicationTokenCredential({
      tokenRefresher: (): Promise<string> => {
        return utils.getRefreshedTokenForUser(userId);
      },
      refreshProactively: true,
      token: userToken
    });

    return {
      tokenCredential,
      userId
    };
  },
  CallingAgent: async (
    tokenCredential: AzureCommunicationTokenCredential
  ): Promise<CallAgent> => {
    const callClient = new CallClient();

    if (callClient === undefined) {
      throw new Error('CallClient is not initialized');
    }

    const callAgent: CallAgent = await callClient.createCallAgent(tokenCredential);
    return callAgent;
  }
});

const mapDispatchToProps = (dispatch: any) => ({

  setPhoneCalling: async (
    callAgent: CallAgent,
    phoneNumber: string
  ): Promise<void> => {
    dispatch(setPhonecall(callAgent, phoneNumber));
  },
  endPhoneCalling: async (
    call: any
  ): Promise<void> => {
    dispatch(endPhonecall(call));
  },
 
  
 
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(Calling);
