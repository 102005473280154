import { ChatClient } from '@azure/communication-chat';

export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USERS = 'SET_USERS';
export const SET_CURRENT_USER_COOL_PERIOD = 'SET_CURRENT_USER_COOL_PERIOD';
export const SET_USERS_IMAGE = 'SET_USERS_IMAGE';
export const SET_INDIVIDUAL_CHAT_CLIENTS = 'SET_INDIVIDUAL_CHAT_CLIENTS';

export type UserConfiguration = {
  emoji: string;
};

export type Users = Record<string, UserConfiguration>;

export interface SetChatClientAction {
  type: typeof SET_CHAT_CLIENT;
  chatClient: ChatClient | undefined;
}

export interface SetContosoUserAction {
  type: typeof SET_CURRENT_USER;
  identity: string;
  token: string;
  displayName: string;
  userEmail: string;
  profileImage: any;
  memberRole?: string;
}

export interface SetContosoUserCoolPeriodAction {
  type: typeof SET_CURRENT_USER_COOL_PERIOD;
  coolPeriod: Date;
}

export interface SetContosoUsersAction {
  type: typeof SET_USERS;
  users: Users;
}

export interface SetContosoUsersImageAction {
  type: typeof SET_USERS_IMAGE;
  images: any;
}

export interface SetIndividualChatClientAction {
  type: typeof SET_INDIVIDUAL_CHAT_CLIENTS;
  chatClients: any;
}

export const setChatClient = (chatClient: ChatClient | undefined): SetChatClientAction => ({
  type: SET_CHAT_CLIENT,
  chatClient
});

export const setContosoUser = (identity: string, token: string, displayName: string, userEmail: string, profileImage: any): SetContosoUserAction => ({
  type: SET_CURRENT_USER,
  identity,
  token,
  displayName,
  profileImage,
  userEmail
});

export const setContosoUsers = (users: Users): SetContosoUsersAction => ({
  type: SET_USERS,
  users
});

export const setContosoUserCoolPeriod = (coolPeriod: Date): SetContosoUserCoolPeriodAction => ({
  type: SET_CURRENT_USER_COOL_PERIOD,
  coolPeriod
});

export const setContosoUsersImage = (images: any): SetContosoUsersImageAction => ({
  type: SET_USERS_IMAGE,
  images
});

export const setIndividualChatClients = (chatClients: any): SetIndividualChatClientAction => ({
  type: SET_INDIVIDUAL_CHAT_CLIENTS,
  chatClients
});

export type ContosoActionTypes = SetChatClientAction | SetContosoUserAction | SetContosoUserCoolPeriodAction | SetContosoUsersAction | SetContosoUsersImageAction | SetIndividualChatClientAction;
