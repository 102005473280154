import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Callout, Label, Spinner, SpinnerSize } from '@fluentui/react';
import { RemoteVideoStream, VideoStreamRenderer, VideoStreamRendererView } from '@azure/communication-calling';
import { videoHint, mediaContainer, streamContainer } from './styles/StreamMedia.styles';
import { utils } from '../Utils/Utils';
import staticMediaSVG from '../assets/staticmedia.svg';
import { Image, ImageFit } from '@fluentui/react';
import { MicIcon, MicOffIcon, RaiseHandColoredIcon } from '@fluentui/react-icons-northstar';

export interface RemoteStreamMediaProps {
  label: string;
  stream: RemoteVideoStream | undefined;
  isParticipantStreamSelected: boolean;
  userInfo: any;
  isSpeaking: boolean;
  attData:any;
  uId:any;
  cohosts:any;
  isHost:boolean;
  onHandleCohost(data:any,type:any):void;
  isMainCohost: boolean;
  handleRemoteMicChange(uid:any, mic:boolean): void;
  handRaised:boolean;
}

export default (props: RemoteStreamMediaProps): JSX.Element => {
  const rendererViewRef = useRef<VideoStreamRendererView>();

  const streamId = props.stream ? utils.getStreamId(props.label, props.stream) : `${props.label} - no stream`;

  const [activeStreamBeingRendered, setActiveStreamBeingRendered] = useState(false);
  const [showRenderLoading, setShowRenderLoading] = useState(false);
  const [showStream, setShowStream] = useState(false);

  
  const imageProps = {
    src: props.userInfo.length && props.userInfo[0]? props.userInfo[0]: staticMediaSVG.toString(),
    imageFit: ImageFit.contain,
    styles: {
      root: {
        width: '100%',
        height: '100%',
        display: activeStreamBeingRendered ? 'none' : 'block',
        border: props.isSpeaking ? '2px solid rgb(199 224 244)': ''
      }
    }
  };

  const loadingStyle = {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const { label, stream, isParticipantStreamSelected, attData } = props;

const foundobj = attData.find((o:any) => o.userId == props.uId);
const isCohost = foundobj && props.cohosts.some((el:any) => el == foundobj.email);
  
const handlecohostchange= (data:any, type:any) => {
  props.onHandleCohost(data,type);
  setShowStream(false);
}

  const renderRemoteStream = useCallback(async () => {
    const container = document.getElementById(streamId);
    if (container && stream && stream.isAvailable && isParticipantStreamSelected) {
      // if we are already rendering a stream we don't want to start rendering the same stream
      if (activeStreamBeingRendered) {
        return;
      }

      // set the flag that a stream is being rendered
      setActiveStreamBeingRendered(true);
      setShowRenderLoading(true);
      const renderer: VideoStreamRenderer = new VideoStreamRenderer(stream);
      // this can block a really long time if we fail to be subscribed to the call and it has to retry
      rendererViewRef.current = await renderer.createView({ scalingMode: 'Crop' });
      setShowRenderLoading(false);
      if (container && container.childElementCount === 0) {
        container.appendChild(rendererViewRef.current.target);
      }
    } else {
      setActiveStreamBeingRendered(false);

      if (rendererViewRef.current) {
        rendererViewRef.current.dispose();
        rendererViewRef.current = undefined;
      }
    }
  }, [
    stream,
    streamId,
    isParticipantStreamSelected,
    activeStreamBeingRendered,
    setShowRenderLoading,
    setActiveStreamBeingRendered
  ]);

  useEffect(() => {
    if (!stream) {
      return;
    }

    stream.on('isAvailableChanged', renderRemoteStream);

    if (stream.isAvailable) {
      renderRemoteStream();
    }
  }, [stream, isParticipantStreamSelected, renderRemoteStream]);

  const handlemicChange = (uid:any, mic: boolean) => {
     props.handleRemoteMicChange(uid,mic);
  }

  return (
    <div className={mediaContainer}>
      <div style={{ display: activeStreamBeingRendered ? 'block' : 'none' }} className={mediaContainer} id={streamId}>
        {showRenderLoading && <Spinner style={loadingStyle} label={`Rendering stream...`} size={SpinnerSize.xSmall} />}
      </div>
      <Image {...imageProps} />
      {props.handRaised && <RaiseHandColoredIcon size="largest" style={{position:'absolute',top:'0',float:'left', left:'0'}}/>}
      {(props.isHost || props.isMainCohost) ?(
          <Label className={videoHint} style={{display:'flex'}}>{label} {props.isSpeaking ? <div onClick={()=>handlemicChange(props.uId,true)}><MicIcon size="medium" style={{paddingLeft:'0.4rem',color:'chartreuse', cursor:'pointer'}}/></div> : <div onClick={()=>handlemicChange(props.uId,false)}><MicOffIcon size="medium" style={{paddingLeft:'0.4rem',cursor:'pointer'}}/></div> }</Label>
      ):(
           <Label className={videoHint}>{label} {props.isSpeaking ? <MicIcon size="medium" style={{paddingLeft:'0.4rem',color:'chartreuse'}}/> : <MicOffIcon size="medium" style={{paddingLeft:'0.4rem'}}/> }</Label>
      )}
      
      {props.isHost && <div id="showcallout" style={{position:'absolute',fontSize:'24px',float:'right',bottom:'10px',right:'15px',cursor:'pointer'}} onClick={()=>setShowStream(!showStream)}>...</div>}
      {showStream && (
        <Callout
        className={streamContainer}
        role="dialog"
        gapSpace={0}
        target={`#showcallout`}
        onDismiss={()=>setShowStream(!showStream)}
        setInitialFocus
        style={{minHeight:'1.5rem',padding:'10px'}}
      >
         {isCohost ?<span style={{cursor:'pointer'}} onClick={()=>handlecohostchange(foundobj,"removecohost")}>Remove as co-host</span> :<span style={{cursor:'pointer'}} onClick={()=>handlecohostchange(foundobj,"makecohost")}>Make as co-host</span>}
      </Callout>
      )}
    </div>
  );
};
