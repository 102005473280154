import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import {
    CallAgent,
    CallEndReason
  } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';  
import {endCallContainerStyle} from './styles/EndChat.styles';
import { ORG_ACCOUNT_ID } from 'core/constants';
import { saveMeetingInfo } from 'Utils/FileUpload';
// import { ORG_ACCOUNT_ID } from 'core/constants';
// import { saveMeetingInfo } from 'Utils/FileUpload';

export type TokenResponse = {
    tokenCredential: AzureCommunicationTokenCredential;
    userId: string;
  };
export interface WaitingRoomProps {
    group: any;
    setupCallClient(unsupportedStateHandler: () => void): void;
    unsupportedStateHandler: () => void;
    setup(displayName: string, userEmail: string, profileImage: any): void;
    joinGroup(callAgent: CallAgent, groupId: string): void;
    getToken(): Promise<TokenResponse>;
    createCallAgent(tokenCredential: AzureCommunicationTokenCredential, displayName: string): Promise<CallAgent>;
    registerToCallEvents(
      userId: string,
      callAgent: CallAgent,
      endCallHandler: (reason: CallEndReason) => void
    ): Promise<void>;
    socket: any;
    waitingHandler(admitted: Boolean): void;
    user: any;
    userId: string;
    setup(name:any, email:any, image: any): void;
    callEndedHandler: (reason: CallEndReason) => void;
    setWaitingList(list:any): void;
    waitList: any;
    localuser: any;
    removeHandler(): void;
}

export default (props: WaitingRoomProps): JSX.Element => {
  const waitingLabel = 'Please wait, the meeting host will let you in soon.';
  const [admituser, setAdmitUser] = useState<any>();

  const { setupCallClient, unsupportedStateHandler } = props;

  useEffect(() => {
    setupCallClient(unsupportedStateHandler);
  }, [setupCallClient, unsupportedStateHandler]);

  const urlParams = new URLSearchParams(window.location.search);
  const groupId:any = urlParams.get('groupId');

const savetoAzure = async (cagent: any, gId: string) => {
    /** save meeting info to Azure */
    let obj: any = {};
    obj[gId] = {};
    obj[gId]['meetingId'] = gId;
    obj[gId]['accountId'] = ORG_ACCOUNT_ID;
    obj[gId]['meetingStartedAt'] = new Date().toLocaleString();
    obj[gId]['meetingStatus'] = 'Ongoing';
    obj[gId][cagent.clientId] = {};
    obj[gId][cagent.clientId]['callStack'] = {};
    obj[gId][cagent.clientId]['calls'] = {};
    obj[gId][cagent.clientId]['clientId'] = cagent.clientId;
    obj[gId][cagent.clientId]['displayName'] = cagent.displayName;
    obj[gId][cagent.clientId]['email'] = admituser?.email;
    obj[gId][cagent.clientId]['resourceId'] = cagent._resourceId;
    obj[gId][cagent.clientId]['callStack']['acsResourceId'] = cagent._callStack.acsResourceId;
    obj[gId][cagent.clientId]['calls']['id'] = cagent._calls[0]._id;
    obj[gId][cagent.clientId]['calls']['state'] = cagent._calls[0]._state;
    //obj[gId][cagent.clientId]['calls']['callStartedAt'] = cagent._calls[0]._info._tsCall.callStartedAt;
    obj[gId][cagent.clientId]['calls']['callStartedAt'] = new Date().toLocaleString();
    obj[gId][cagent.clientId]['calls']['callId'] = cagent._calls[0]._info._tsCall.callStats.callId;
    obj[gId][cagent.clientId]['calls']['participantId'] = cagent._calls[0]._info._tsCall.callStats.participantId;
    obj[gId][cagent.clientId]['calls']['acsResourceId'] = cagent._callStack.acsResourceId;

    saveMeetingInfo({
      accountId: ORG_ACCOUNT_ID,
      gId:gId,
      data: obj
    });
  }

const admitUserToCall = async(user: any) => {
      //1. Retrieve a token
      const { tokenCredential, userId } = await props.getToken();
      //2. Initialize the call agent
      const callAgent = await props.createCallAgent(tokenCredential, user.name);
      //3. Register for calling events
      props.registerToCallEvents(userId, callAgent, props.callEndedHandler);
      //4. Join the call
      props.joinGroup(callAgent, groupId);
      props.setup(user.name, user.email, '');
      savetoAzure(callAgent, groupId);
      props.waitingHandler(true);
};

const admittedSocketMsg = async (data:any) => {
 
  if(data.admitteduser){
    setAdmitUser(data.admitteduser);
    const pendingwaitlist = props.waitList.length ? props.waitList.filter( (i:any) => i.email != data.admitteduser.email):[];
    props.setWaitingList(pendingwaitlist);
    /**For single Admit */
    if(props.localuser.email && (props.localuser.email == data.admitteduser.email)){
      admitUserToCall(data.admitteduser);
    }
    
  }
  if(data.removeduser){
    if(props.localuser.email == data.removeduser.email){
      props.removeHandler();
    }
  }
  if(data.admitall){
    props.setWaitingList([]);
    admitUserToCall(props.localuser);
  }
  
}
props.socket.current.onmessage = (event:any) => {
  const socketData = JSON.parse(event.data);
  if(socketData.waitingusers){
    const waitinguser = socketData.waitingusers[0];
    if(props.localuser.email && (waitinguser.email != props.localuser.email)){
      props.setWaitingList([...props.waitList, waitinguser])
    }
    
  }

  admittedSocketMsg(socketData);
  };
  return (
    <Stack verticalAlign="center"  className={endCallContainerStyle}>
      <Stack.Item align="center">
          <span>{waitingLabel}</span>
        </Stack.Item>
    </Stack>
  );
};
