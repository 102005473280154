import React from 'react';
import { TextField } from '@fluentui/react';

import {
  inputBoxStyle,
  inputBoxTextStyle,
  inputBoxWarningStyle,
  labelFontStyle,
  warningStyle
} from './styles/Configuration.styles';

interface DisplayNameFieldProps {
  setName(name: string): void;
  name: string;
  setEmptyWarning(isEmpty: boolean): void;
  isEmpty: boolean;
  setuserName(name: string): void;
  // userslist: string[];
  // isinvaliduser(isduplicate: boolean): void;
   isnameValid: boolean;
}

const TextFieldStyleProps = {
  wrapper: {
    height: '2.3rem'
  },
  fieldGroup: {
    height: '2.3rem'
  }
};

export default (props: DisplayNameFieldProps): JSX.Element => {
  const onNameTextChange = (event: any): void => {
    props.setName(event.target.value);
    if (event.target.value) {
      props.setEmptyWarning(false);
      // if(props.userslist.length && props.userslist.indexOf(event.target.value) > -1){
      //   props.isinvaliduser(true);
      // }else{
      //   props.isinvaliduser(false);
      // }
    } else {
      props.setEmptyWarning(true);
    }
  };

  const onNameBlurChange = (event: any): void => {
     props.setuserName(event.target.value)
  };

  return (
    <div>
      <div className={labelFontStyle}>Name <span style={{color:'red'}}>*</span></div>
      <TextField
       autoComplete="off"
        inputClassName={inputBoxTextStyle}
        ariaLabel="Choose your name"
        borderless={true}
        className={props.isEmpty ? inputBoxWarningStyle : props.isnameValid ? inputBoxWarningStyle : inputBoxStyle}
        onChange={onNameTextChange}
        id="name"
        placeholder="Enter your name"
        defaultValue={props.name}
        styles={TextFieldStyleProps}
        onBlur={onNameBlurChange}
      />
      {props.isEmpty && (
        <div role="alert" className={warningStyle}>
          {' '}
          Name cannot be empty{' '}
        </div>
      )}
      {props.isnameValid && (
        <div role="alert" className={warningStyle}>
          {' '}
          User with same name already joined{' '}
        </div>
      )}
     
    </div>
  );
};
