import { Dropdown, IDropdownOption, Stack, StackItem, TextField } from '@fluentui/react';
import { EmojiIcon, PaperclipIcon, SendIcon } from '@fluentui/react-northstar';
import React, { useState, Dispatch, useRef } from 'react';
import { RemoteParticipant } from '@azure/communication-calling';
import Picker from 'emoji-picker-react';
import { Constants } from '../core/constants';
import { attachmentIconStyle, sendBoxStyle, sendIconStyle, textFieldStyle, TextFieldStyleProps } from './styles/SendBox.styles';
import { User } from '../core/reducers/ContosoClientReducers';
import ChatSystemMessage from '../containers/ChatSystemMessage';
//import { uploadChatAttachment } from 'Utils/FileUpload';

export interface SendboxProps {
  onSendMessage(messageContent: string): void;
  onSendTypingNotification(
    lastSentTypingNotificationDate: number,
    setLastSentTypingNotificationDate: Dispatch<number>
  ): void;
  onSendFile(file: File): void;
  user: User;
  groupId: any;
  threadId: any;
  remoteParticipants: RemoteParticipant[];
  setWhomToChat(whom: any): void;
  chatwith:any;
}

export default (props: SendboxProps): JSX.Element => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [fileTooLarge, setFileTooLarge] = useState<boolean>(false);
  const [textValue, setTextValue] = useState('');
  const [textValueOverflow, setTextValueOverflow] = useState(false);
  const [lastSentTypingNotificationDate, setLastSentTypingNotificationDate] = useState(0);
 // const [chosenEmoji, setChosenEmoji] = useState<any>([]);
  const [showEmoji, setShowEmoji] = useState(false);
 

  const addMessage = () => {
    // we dont want to send empty messages including spaces, newlines, tabs
    if (!Constants.EMPTY_MESSAGE_REGEX.test(textValue)) {
      props.onSendMessage(textValue);
      setTextValue('');
      //setChosenEmoji([]);
    }
  };
  const setText = (e: any) => {
    if (e.target.value.length > Constants.MAXIMUM_LENGTH_OF_MESSAGE) {
      setTextValueOverflow(true);
    } else {
      setTextValueOverflow(false);
    }
   
    setTextValue(e.target.value);
  };

  const fileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      setFileTooLarge(true);
    } else {
      setFileTooLarge(false);
      props.onSendFile(file);
     
    //  const urlParams = new URLSearchParams(window.location.search);
    //   const threadId =  urlParams.get('threadId');
    //  uploadChatAttachment({
    //     file: file,
    //     gId: props.groupId,
    //     tId: threadId,
    //     accountId: 14521
    //  })
    }

    // Reset the file input so that choosing the same file again still triggers the onChange handler
    event.target.value = "";
  };
  const getDropDownList = (list: any): IDropdownOption[] => {
    return list.map((item:any) => ({
      val: item.displayName,
      key: item.identifier.communicationUserId,
      text: item.displayName
    }));
  };
let userOptions = getDropDownList(props.remoteParticipants);
let initial = {
  val: 'all',
  key: '0',
  text: 'Everyone'
};
userOptions.unshift(initial)

const onEmojiClick = (event:any, emojiObject:any) => {
  //setChosenEmoji([...chosenEmoji,emojiObject.emoji]);
  setShowEmoji(false);
  document.getElementById('sendbox')?.focus();
 let newstr = textValue.concat(emojiObject.emoji);
  setTextValue(newstr);
};

// const removeEmojis = (e:any)=>{
 
//   let key = e.keyCode || e.charCode;
//   if(key == 8 && chosenEmoji.length ){
//    chosenEmoji.pop();
//    console.log("Debug== NewEmoji",chosenEmoji)
//     setChosenEmoji(chosenEmoji);
//     setTextValue(e.target.value);
//   }
// };

// let msg = '';
// if(textValue && chosenEmoji.length === 0 ){
//   msg = textValue;
// }else if(textValue && chosenEmoji.length){
//   msg = textValue+''+chosenEmoji && chosenEmoji.join(' ');
// }else if(!textValue && chosenEmoji.length){
//   msg = chosenEmoji && chosenEmoji.join(' ');
// }

  return (
    <div>
      <Stack horizontal={true}>
       <StackItem style={{position:'absolute',marginTop:'-2.2rem', display:'flex'}}>
       To: <Dropdown
              placeholder={"Everyone"}
              options={userOptions}
              defaultSelectedKey={Object.keys(props.chatwith).length ? props.chatwith.key: '0'}
              style={{paddingLeft:'5px'}}
              onChange={(...args:any): void => {
                props.setWhomToChat(args[1]);
              }}
        />
       </StackItem>
        <TextField
          className={textFieldStyle}
          id="sendbox"
          borderless={true}
          ariaLabel={'Type'}
          inputClassName={sendBoxStyle}
          placeholder="Type your message"
          value={textValue}
          onChange={setText}
         // onKeyDown={removeEmojis}
          autoComplete="off"
          onKeyUp={(ev) => {
            if (ev.which === Constants.ENTER_KEY && !textValueOverflow) {
              addMessage();
            }
            props.onSendTypingNotification(lastSentTypingNotificationDate, setLastSentTypingNotificationDate);
          }}
          styles={TextFieldStyleProps}
        />
       
        <SendIcon
          outline
          className={sendIconStyle}
          id="sendmessage"
          onClick={() => {
            if (!textValueOverflow) {
              addMessage();
            }
          }}
        />
        
      </Stack>
     
      <PaperclipIcon
          outline
          className={attachmentIconStyle}
          onClick={() => {
            if (hiddenFileInput.current !== null) {
              hiddenFileInput.current.click();
            }
          }}
        />
      <EmojiIcon style={{cursor:'pointer', color: `${showEmoji? 'rgb(0, 120, 212)':'black'}`}} outline={showEmoji? false: true} onClick={()=>setShowEmoji(!showEmoji)} />

        {showEmoji && <Picker onEmojiClick={onEmojiClick} pickerStyle={{height:'250px',position:'absolute',right:'25rem',bottom:'1rem'}}/>}
      {/* <Spinner label="Sending file please wait..." ariaLive="assertive" labelPosition="right" /> */}
      <ChatSystemMessage textValueOverflow={textValueOverflow} fileTooLarge={fileTooLarge} />
      <input
        ref={hiddenFileInput}
        type="file"
        onChange={fileChanged}
        style={{ display: 'none' }}
      />
    </div>
  );
};
