import { CallClient } from '@azure/communication-calling';
import { Reducer } from 'redux';
import { 
  SET_USERID, 
  SdkTypes, 
  SET_CALL_CLIENT, 
  SET_DISPLAY_NAME, 
  SET_TOKEN, 
  SET_MEETING_DATA, 
  SET_WAITING_LIST, 
  SET_CONNECTION_LIST, 
  SET_LOCAL_USER, 
  SET_ATTENDEE_DATA, 
  SET_COHOSTS, 
  SET_WAITINGROOM_ENABLED, 
  SET_IS_MEETING_LOCKED, 
  SET_MUTEUNMUTE_ALL, 
  SET_RAISE_HAND 
} from '../actions/sdk';

export interface SdkState {
  userId?: string;
  callClient?: CallClient;
  displayName: string;
  token: any;
  meetingData: any;
  waitingList:[];
  connectionList:[];
  localwaitinguser:any;
  attendeeData:[];
  cohosts:[];
  isWaitingRoomEnabled: boolean;
  isMeetingLocked: boolean;
  ismicAll:boolean;
  raiseHands:[];
}

const initialState: SdkState = {
  displayName: '',
  token:'',
  meetingData:{},
  waitingList:[],
  connectionList:[],
  localwaitinguser: {},
  attendeeData:[],
  cohosts:[],
  isWaitingRoomEnabled: false,
  isMeetingLocked: false,
  ismicAll: false,
  raiseHands:[]
};

export const sdkReducer: Reducer<SdkState, SdkTypes> = (state = initialState, action: SdkTypes): SdkState => {
  switch (action.type) {
    case SET_USERID:
      return { ...state, userId: action.userId };
    case SET_CALL_CLIENT:
      return { ...state, callClient: action.callClient };
    case SET_DISPLAY_NAME:
      return { ...state, displayName: action.displayName };
    case SET_TOKEN:
      return { ...state, token: action.token };
    case SET_MEETING_DATA:
        return { ...state, meetingData: action.meetingData };  
    case SET_WAITING_LIST:
        return { ...state, waitingList: [...action.waitingList] };   
    case SET_CONNECTION_LIST:
        return { ...state, connectionList: action.connectionList }; 
    case SET_LOCAL_USER:
       return { ...state, localwaitinguser: action.localwaitinguser };    
    case SET_ATTENDEE_DATA:
        return { ...state, attendeeData: [...action.attendeeData] }; 
    case SET_COHOSTS:
        return { ...state, cohosts: [...action.cohosts] }; 
    case SET_WAITINGROOM_ENABLED:
        return { ...state, isWaitingRoomEnabled: action.isWaitingRoomEnabled };
    case SET_IS_MEETING_LOCKED:
        return { ...state, isMeetingLocked: action.isMeetingLocked }; 
    case SET_MUTEUNMUTE_ALL:
        return { ...state, ismicAll: action.ismicAll };  
    case SET_RAISE_HAND:
        return { ...state, raiseHands: [...action.raiseHands] };                     
    default:
      return state;
  }
};
