import { Stack, TooltipHost, PrimaryButton, Icon,Persona, PersonaSize, PersonaPresence } from '@fluentui/react';
import {
  Chat,
  MessageSeenIcon,
  Flex,
  Ref,
  Button,
  PresenceAvailableIcon,
  PresenceStrokeIcon,
  RedbangIcon,
  Avatar
} from '@fluentui/react-northstar';
import { SpeakerPersonIcon } from '@fluentui/react-icons-northstar';
import React, { useEffect, useState, createRef, useRef } from 'react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import Draggable from 'react-draggable';
import { Constants } from '../core/constants';
import {
  chatContainerStyle,
  noReadReceiptStyle,
  chatStyle,
  chatMessageStyle,
  newMessageButtonStyle,
  loadMoreMessageButtonStyle,
  readReceiptIconStyle,
  DownIconStyle
} from './styles/ChatThread.styles';
import { User } from '../core/reducers/ContosoClientReducers';
import { ExtendedChatMessage } from '../core/reducers/MessagesReducer';
import { isUserMatchingIdentity } from '../Utils/Utils';
import './styles/ChatThread.css';
import { RemoteParticipant } from '@azure/communication-calling';
import FileAttachmentMessage from '../containers/FileAttachmentMessage';
//import { getBlobsInContainer } from '../Utils/FileUpload';

export interface ChatThreadProps {
  isYourLatestMessage(clientMessageId: string, messages: any[]): boolean;
  isYourLatestSeenMessage(clientMessageId: string, MessagesWithSeen: any[]): boolean;
  isLargeParticipantsGroup(): boolean;
  isMessageSeen(clientMessageId: string, messages: any[]): boolean;
  sendReadReceipt(messages: any[], userId: string): void;
  messages: ExtendedChatMessage[];
  user: User;
  users: any;
  remoteParticipants:RemoteParticipant[]; 
  groupId:string;
  images:any;
  failedMessages: string[];
  chatwith: any;
}

// Reference: https://stackoverflow.com/questions/33235890/react-replace-links-in-a-text

const renderHyperlink = (text: string) => {
  return text.split(' ').map((part) =>
  Constants.URL_REGEX.test(part) ? (
      <a href={part} target="_blank" rel="noopener noreferrer">
        {part}{' '}
      </a>
    ) : (
      part + ' '
    )
  );
};

let createdRef: any = createRef();
let chatThreadRef: any = createRef();

//  A Chatthread will be fed many messages so it will try to map out the messages out of the props and feed them into a
//  Chat item
//  We need to be smarter and figure out for the last N messages are they all of the same person or not?
export default (props: ChatThreadProps): JSX.Element => {
  const [messagesWithAttached, setMessagesWithAttached] = useState<any[]>([]);
  const [indexOfTheFirstMessage, setIndexOfTheFirstMessage] = useState(props.messages.length);
  const [isAtBottomOfScroll, setIsAtBottomOfScroll] = useState(true);
  const [isAtTopOfScroll, setIsAtTopOfScroll] = useState(false);
  const [existsNewMessage, setExistsNewMessage] = useState(false);
  const [shouldUpdateMessageWithAttached, setShouldUpdateMessageWithAttached] = useState(false);
  const [numberOfMessagesToRender, setNumberOfMessagesToRender] = useState(0);
  const [showdonate, setShowDonate] = useState(false);
  //const [usersavtar, setUsersAvtar] = useState([]);

  const existsNewMessageText = 'New Messages';
  const loadMoreText = 'click to load more messages...';
  const unableToLoadMoreText = 'You have reached the beginning of the thread';

  const messagesWithAttachedRef = useRef(messagesWithAttached);
  const setMessagesWithAttachedRef = (messagesWithAttachedValue: any[]) => {
    messagesWithAttachedRef.current = messagesWithAttachedValue;
    /** Private Chat Logic */
      if(Object.keys(props.chatwith).length && props.chatwith.val != 'all'){
        messagesWithAttachedValue = messagesWithAttachedValue.filter((res:any) => (res.from == props.chatwith.val || res.to == props.chatwith.val) && (res.to == props.user.displayName || res.from == props.user.displayName));
     }else if((Object.keys(props.chatwith).length === 0) || (Object.keys(props.chatwith).length && props.chatwith.val == 'all')){
        messagesWithAttachedValue = messagesWithAttachedValue.filter((res:any) => res.to == 'all');
    }
     /**Ends */
    setMessagesWithAttached(messagesWithAttachedValue);
  };

  const isAtBottomOfScrollRef = useRef(isAtBottomOfScroll);
  const setIsAtBottomOfScrollRef = (isAtBottomOfScrollValue: boolean) => {
    isAtBottomOfScrollRef.current = isAtBottomOfScrollValue;
    setIsAtBottomOfScroll(isAtBottomOfScrollValue);
  };

  const numberOfMessagesToRenderRef = useRef(numberOfMessagesToRender);
  const setNumberOfMessagesToRenderRef = (numberOfMessagesToRenderValue: number) => {
    numberOfMessagesToRenderRef.current = numberOfMessagesToRenderValue;
    setNumberOfMessagesToRender(numberOfMessagesToRenderValue);
  };

  useEffect(() => {
    setNumberOfMessagesToRenderRef(Math.ceil(chatThreadRef.current.clientHeight / 34)); //34 px is the minimum height of the chat bubble
  }, [chatThreadRef.current?.clientHeight]);

  useEffect(() => {
    updateIndexOfTheFirstMessage();
  }, [numberOfMessagesToRender]);

  useEffect(() => {
    // get the sender of the most recent message
    const user =
      props.messages && props.messages[props.messages.length - 1] && props.messages[props.messages.length - 1].sender;
    // if you are the person that sent the most recent message
    if (props.messages.length > 0 && user && isUserMatchingIdentity(user, props.user.identity)) {
      // after sending a message, scroll to bottom
      scrollToBottom();
      updateIndexOfTheFirstMessage();
    } else if (isAtBottomOfScroll) {
      updateIndexOfTheFirstMessage();
    } else {
      setExistsNewMessage(true);
    }
    window.addEventListener('focus', sendReadReceipt);
    createdRef.current.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('focus', sendReadReceipt);
      createdRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [props.messages, props.chatwith]);

  useEffect(() => {
    if (shouldUpdateMessageWithAttached) {
      updateMessageWithAttached();
    }
    setShouldUpdateMessageWithAttached(false);
  }, [shouldUpdateMessageWithAttached]);

  // auto scroll to the latest message when we are at bottom of the scroll
  useEffect(() => {
    if (isAtBottomOfScroll) {
      scrollToBottom();
    }
  }, [messagesWithAttached]);


//   useEffect(() => {
//     const timer = setTimeout(() => {
//      getBlobsInContainer(props.groupId).then((list:any)=>{
//        if(list && list.length) setUsersAvtar(list)
//      });
//   }, 1000);

//    return () => clearTimeout(timer);

//  }, [props.remoteParticipants.length])

  const loadMoreMessages = () => {
    updateIndexOfTheFirstMessageToLoadMore();
  };

  const loadNewMessages = () => {
    scrollToBottom();
    updateIndexOfTheFirstMessage();
  };

  const scrollToBottom = () => {
    createdRef.current.scrollTop = createdRef.current.scrollHeight - createdRef.current.clientHeight;
    setExistsNewMessage(false);
  };

  const sendReadReceipt = () => {
    props.sendReadReceipt(messagesWithAttachedRef.current, props.user.identity);
  };

  const readReceiptIcon = (message: any) => {
    // message is pending send or is failed to be sent
    if (message.failed) {
      let messageFailed: boolean =
        props.failedMessages.find((failedMessage: string) => failedMessage === message.clientMessageId) !== undefined;
      return messageFailed ? (
        <TooltipHost content="failed to send">
          <RedbangIcon size="medium" styles={{ color: 'red' }} />{' '}
        </TooltipHost>
      ) : (
        <TooltipHost content="sending">
          <PresenceStrokeIcon size="medium" />{' '}
        </TooltipHost>
      );
    } else {
      // show read receipt if it's not a large participant group
      if (!props.isLargeParticipantsGroup()) {
        let MessagesWithSeen = messagesWithAttached.map((messageWithAttached) => {
          let isSeen = props.isMessageSeen(messageWithAttached.clientMessageId, messagesWithAttached);
          return { ...messageWithAttached, isSeen };
        });
        if (props.isYourLatestSeenMessage(message.clientMessageId, MessagesWithSeen)) {
          return (
            <TooltipHost content="seen">
              <MessageSeenIcon size="medium" />
            </TooltipHost>
          );
        }
      }
      if (props.isYourLatestMessage(message.id, messagesWithAttached)) {
        return (
          <TooltipHost content="sent">
            <PresenceAvailableIcon size="medium" />
          </TooltipHost>
        );
      } else {
        return <div className={noReadReceiptStyle}></div>;
      }
    }
  };

  const handleScroll = () => {
    let atBottom = createdRef.current.scrollTop >= createdRef.current.scrollHeight - createdRef.current.clientHeight;
    let atTop = createdRef.current.scrollTop === 0;
    if (atBottom && !isAtBottomOfScrollRef.current) {
      loadNewMessages();
    }
    setIsAtBottomOfScrollRef(atBottom);
    setIsAtTopOfScroll(atTop);
  };

  const updateIndexOfTheFirstMessageToLoadMore = () => {
    setIndexOfTheFirstMessage(
      indexOfTheFirstMessage > Constants.NUMBER_OF_MESSAGES_TO_LOAD ? indexOfTheFirstMessage - Constants.NUMBER_OF_MESSAGES_TO_LOAD : 0
    );
    setShouldUpdateMessageWithAttached(true);
  };

  const updateIndexOfTheFirstMessage = () => {
    setIndexOfTheFirstMessage(
      props.messages.length > numberOfMessagesToRenderRef.current
        ? props.messages.length - numberOfMessagesToRenderRef.current
        : 0
    );
    setShouldUpdateMessageWithAttached(true);
  };

  const updateMessageWithAttached = () => {
    let newMessagesWithAttached: any[] = [];
    let messagesToRender = props.messages.slice(0, props.messages.length);
    messagesToRender.map((message: any, index: number, messagesList: any) => {
      let mine = message.sender.communicationUserId === props.user.identity;
      let attached: string | boolean = false;
      if (index === 0) {
        if (index !== messagesList.length - 1) {
          //the next message has the same sender
          if (messagesList[index].sender.communicationUserId === messagesList[index + 1].sender.communicationUserId) {
            attached = 'top';
          }
        }
      } else {
        if (messagesList[index].sender.communicationUserId === messagesList[index - 1].sender.communicationUserId) {
          //the previous message has the same sender
          if (index !== messagesList.length - 1) {
            if (messagesList[index].sender.communicationUserId === messagesList[index + 1].sender.communicationUserId) {
              //the next message has the same sender
              attached = true;
            } else {
              //the next message has a different sender
              attached = 'bottom';
            }
          } else {
            // this is the last message of the whole messages list
            attached = 'bottom';
          }
        } else {
          //the previous message has a different sender
          if (index !== messagesList.length - 1) {
            if (messagesList[index].sender.communicationUserId === messagesList[index + 1].sender.communicationUserId) {
              //the next message has the same sender
              attached = 'top';
            }
          }
        }
      }
      let messageWithAttached = { ...message, attached, mine };
   
      newMessagesWithAttached.push(messageWithAttached);
    });
    setMessagesWithAttachedRef(newMessagesWithAttached);
  };

//Donate button show/hide state

const displayDonate = () =>{
  setShowDonate(true)
}
const closeDonate = () =>{
setShowDonate(false)
}



  return (
    <Ref innerRef={chatThreadRef}>
      <Stack className={chatContainerStyle}>
       {isAtTopOfScroll && (
          <Button
            text
            fluid
            className={loadMoreMessageButtonStyle}
            content={indexOfTheFirstMessage === 0 ? unableToLoadMoreText : loadMoreText}
            disabled={indexOfTheFirstMessage === 0}
            onClick={loadMoreMessages}
          />
        )}
        <Ref innerRef={createdRef}>
          <LiveAnnouncer>
            <Chat
              styles={chatStyle}
              items={messagesWithAttached.map((message: any, index: number) => {
                
                const avatar = props.images && props.images.length && props.images.filter((l:any)=>{
                  //let img = l.split('@').pop();
                  const uname = l.split("/").pop().split("%23").pop().replace('.jpg','');
                  if(uname == message.senderDisplayName ) return l;
                });
               
                const liveAuthor = `${message.senderDisplayName} says `;
                const messageContentItem = (
                  <>
                    {Constants.DONATE_KEYS.indexOf(message.content.message) > -1 && message.mine ? (
                      <Button content="Donate" primary className='donate-button' onClick={displayDonate}/>
                    ):(
                    <>
                    
                    {message.extendedMessageType === 'FileEvent' || message.metadata && message.metadata.hasAttachment == "true"
                    ? (
                      <div>
                       
                        <LiveMessage
                          message={`${message.mine ? 'You ' : message.senderDisplayName} sent a file called ${message.metadata && message.metadata.fileName? message.metadata.fileName: message.fileData.name}`}
                          aria-live="polite"
                        />
                        <FileAttachmentMessage fileId={message.metadata && message.metadata.fileId ? message.metadata.fileId: message.fileData.id } fileName={message.metadata && message.metadata.fileName? message.metadata.fileName: message.fileData.name} showPreview={true} />
                      </div>
                    )
                    : (
                      <div>
                       
                      <LiveMessage  message={`${message.mine ? '' : liveAuthor} ${message.content}`} aria-live="polite" />
                      {renderHyperlink(message.content.message)}
                      </div>
                       )
                    }
                   
                    </>
                    )}
                    
                  </>
                );
                return {
                  gutter: message.mine ? (
                    ''
                  ) : (
                    <>
                    {avatar && avatar.length ?
                    // <img src={avatar[0]}  style={{width:'2rem',height:'2rem',borderRadius:'50%'}}/>
                    <Persona
                      text={message.senderDisplayName}
                      size={PersonaSize.size32}
                      imageUrl={avatar[0]}
                      presence={PersonaPresence.online}
                    />:
                     <Avatar icon={<SpeakerPersonIcon size='large'/>} /> 
                    }
                    {/* <Avatar
                      name={message.senderDisplayName}
                      status={{
                        color: 'green',
                        icon: <AcceptIcon />,
                        title: 'Available',
                      }}
                    /> */}
                    </>
                    // <div
                    //   className={messageAvatarContainerStyle(
                    //     props.users[message.sender.communicationUserId] === undefined
                    //       ? ''
                    //       : props.users[message.sender.communicationUserId].emoji
                    //   )}
                    // >
                    //   {props.users[message.sender.communicationUserId] === undefined
                    //     ? ''
                    //     : props.users[message.sender.communicationUserId].emoji}
                    // </div>
                  ),
                  key: index,
                  contentPosition: message.mine ? 'end' : 'start',
                  message: (
                    <Flex vAlign="end" className={`chat-flex-${message.mine ? 'right' : 'left'}`}>
                      <Chat.Message
                        className="chat-message"
                        styles={chatMessageStyle(message.mine)}
                        content={messageContentItem}
                        author={message.senderDisplayName}
                        mine={message.mine}
                        timestamp={JSON.stringify(message.createdOn).slice(12,20).replace('T',' ')}
                      />
                      <div className={readReceiptIconStyle(message.mine)}>{readReceiptIcon(message)}</div>
                    </Flex>
                  ),
                  attached: message.attached,
                  
                };
              })}
            />
          </LiveAnnouncer>
        </Ref>
        {existsNewMessage && (
          <div>
            <PrimaryButton className={newMessageButtonStyle} onClick={loadNewMessages}>
              <Icon iconName="Down" className={DownIconStyle} />
              {existsNewMessageText}
            </PrimaryButton>
          </div>
        )}

        {showdonate &&
        <Draggable axis="both">
            <div className="donate_container" >
              <div className="donate_head">
                  <button  type="button" className="donate_close" title="Close" onClick={closeDonate}>CLOSE X</button>
                </div>
              <iframe allowTransparency={true} frameBorder="0" title="Donate" className="donate_iframe" src={`https://connect.clickandpledge.com/w/Form/69050d8d-c142-4e58-9899-2049b9e34030`}></iframe>
             </div>
            </Draggable>
           
        }
      </Stack>
    </Ref>
  );
};
