import React, { useEffect, useRef } from 'react';

export interface AutoDownloadLinkProps {
  link: any;
  downloadName: string;
  onTriggered: () => void;
}

export default (props: AutoDownloadLinkProps): JSX.Element => {
  const aRef = useRef<HTMLAnchorElement>(null);
  let fname = props.downloadName.split('#').pop();
  fname = fname?.replaceAll("+", "");
 
  useEffect(() => {
    if (aRef.current !== null) {
      aRef.current.click();
      props.onTriggered();
    }
  }, [aRef]);

  return (
    <a
      ref={aRef}
      target="_blank"
      href={props.link}
      download={props.downloadName}
      style={{ display: 'none' }} />
  );
};