import React, { useState, useEffect } from 'react';
import { MessageBar, MessageBarButton, MessageBarType, Overlay, Stack } from '@fluentui/react';
import Header from '../containers/Header';
import MediaGallery from '../containers/MediaGallery';
import MediaFullScreen from './MediaFullScreen';
import CommandPanel, { CommandPanelTypes } from './CommandPanel';
import { Constants, ORG_ACCOUNT_ID } from '../core/constants';
import {
  headerStyles,
  containerStyles,
  paneStyles,
  hiddenContainerClassName,
  activeContainerClassName,
  overlayStyles,
  containerStyleMin,
  waitingUserClassName,
  cohostClassName
} from './styles/GroupCall.styles';
import {
  Call,
  AudioDeviceInfo,
  VideoDeviceInfo,
  RemoteParticipant,
  CallAgent,
  DeviceManager,
  LocalVideoStream
} from '@azure/communication-calling';

import { ParticipantStream } from '../core/reducers/index.js';
import  ChatScreen  from '../containers/ChatScreen';
import Calling from '../containers/Calling';
import { getConnectionList } from 'Utils/FileUpload';


//import axios from 'axios';


export interface GroupCallProps {
  userId: string;
  user: any;
  groupId: string;
  threadId: string;
  call: Call;
  callAgent: CallAgent;
  deviceManager: DeviceManager;
  mic: boolean;
  remoteParticipants: RemoteParticipant[];
  callState: string;
  localVideo: boolean;
  screenShareStreams: ParticipantStream[];
  audioDeviceInfo: AudioDeviceInfo;
  audioSpeakerDeviceInfo: AudioDeviceInfo;
  videoDeviceInfo: VideoDeviceInfo;
  audioDeviceList: AudioDeviceInfo[];
  audioSpeakerDeviceList: AudioDeviceInfo[];
  videoDeviceList: VideoDeviceInfo[];
  screenWidth: number;
  shareScreen: boolean;
  setAudioDeviceInfo(deviceInfo: AudioDeviceInfo): void;
  setAudioSpeakerDeviceInfo(deviceInfo: AudioDeviceInfo): void;
  setVideoDeviceInfo(deviceInfo: VideoDeviceInfo): void;
  mute(): void;
  isGroup(): void;
  joinGroup(): void;
  endCallHandler(): void;
  localVideoStream: LocalVideoStream;
  setLocalVideoStream: void;
  usersimgs: any;
  chatScreenItems: any;
  reloadCallHandler(): void;
  socket: any;
  waitList: any;
  setWaitingList(list:any):void;
  setConnectionList(clist:any):void;
  connList:any;
  localuser: any;
  settings: any;
  cohosts:any;
  setCoHost(data:any): void;
  setMic(mic:boolean):void;
  ismeetinglocked: boolean;
  iswaitingenabled: boolean;
  setWaitingRoomEnabled(iswaiting:boolean): void;
  setMeetingLocked(islocked:boolean): void;
  endAllCall():void;
  endMeetingForAll(): void;
  raisehands:any;
  setRaiseHand(hands:any): void;
}

export default (props: GroupCallProps): JSX.Element => {
  const [selectedPane, setSelectedPane] = useState(CommandPanelTypes.None);
  const activeScreenShare = props.screenShareStreams && props.screenShareStreams.length === 1;
  const [newwaitlist, setNewWaitList] = useState<any>('');
  const [newcohost, setNewCoHost] = useState<any>('');
  const mSettings = props.settings.MeetingSettings;

  const handleselectedPane = () => {
    setSelectedPane(CommandPanelTypes.None)
  }

  const getConnectionlist = async () => {
    const allConns = await getConnectionList({gId: props.groupId, accountId: ORG_ACCOUNT_ID});
        if(allConns.data && allConns.data.connectionList){
          props.setConnectionList(allConns.data.connectionList);
        }
  }
  useEffect(() => {
    getConnectionlist();
  }, [props.remoteParticipants.length])
  

  const onSocketMessage = async (data:any) => {
  
    if(data.waitingusers){
      if(props.localuser.email && (mSettings.host[0] == props.localuser.email || props.cohosts.some((el:any) => el == props.localuser.email))) setNewWaitList(data.waitingusers[0]);
      props.setWaitingList([...props.waitList, data.waitingusers[0]]);
      getConnectionlist();
    }
    if(data.admitteduser || data.removeduser){
      let aruser:any = ''
      if(data.admitteduser) aruser = data.admitteduser;
      if(data.removeduser) aruser = data.removeduser;
      if(aruser && aruser.email){
        const pendingwaitlist = props.waitList.length ? props.waitList.filter( (i:any) => i.email != aruser.email) : [];
        props.setWaitingList(pendingwaitlist);
        if(newwaitlist.email) setNewWaitList('');
      }
      
    }
    if(data.admitall){
      props.setWaitingList([]);
    }

    if(data.makecohost){

      props.setCoHost([...props.cohosts, data.makecohost])
      if(props.localuser && props.localuser.email == data.makecohost) setNewCoHost("You have been made as a co-host.");
    }

    if(data.removecohost){
      if(props.cohosts.length){
        const filteredcohosts = props.cohosts.filter( (i:any) => i != data.removecohost);
        props.setCoHost(filteredcohosts);
        if(props.localuser && props.localuser.email == data.removecohost) setNewCoHost("You have been removed as a co-host.");
      }
    }

    if(data.remotemuteunmute){
      if(data.remotemuteunmute.userId == props.userId){
        props.setMic(!data.remotemuteunmute.mic);
      }
    }

    if(data.unmuteAll){
      props.setMic(true);
    }
    if(data.muteAll){
      props.setMic(false);
    }
    if(data.meetinglock){
      props.setMeetingLocked(!props.ismeetinglocked)
    }
    if(data.checkwaitingroom){
      props.setWaitingRoomEnabled(!props.iswaitingenabled)
    }
    if(data.endallmeeting){
      props.endAllCall();
      props.endMeetingForAll();
    }
    if(data.raisehand){
      props.setRaiseHand([...props.raisehands, data.raisehand]);
    }
    if(data.handsdown){
      const handsFiltered = props.raisehands.filter( (i:any) => i != data.handsdown);
      props.setRaiseHand(handsFiltered)
    }
          
  };

  props.socket.current.onmessage = (event:any) => {
    const socketData = JSON.parse(event.data);
    onSocketMessage(socketData);
  };

  const admitWaitinguser = () => {
    const pendingwaitlist = newwaitlist.email && props.waitList.length ? props.waitList.filter( (i:any) => i.email != newwaitlist.email):[];
    props.setWaitingList(pendingwaitlist);
    props.socket.current?.send(JSON.stringify({
      action: "sendMessage",
      message: {
        connections: props.connList,
        data: newwaitlist,
        type: "admitteduser"
      }
    }))
    setNewWaitList('');
  }

  const seeWaitingList = () => {
    setSelectedPane(CommandPanelTypes.People);
    setNewWaitList('');
  }


  return (
    <Stack horizontalAlign="center" verticalAlign="center" styles={containerStyles}>
      <Stack.Item styles={headerStyles}>
        <Header
          selectedPane={selectedPane}
          setSelectedPane={setSelectedPane}
          endCallHandler={(): void => {
            props.endCallHandler();
          }}
          screenWidth={props.screenWidth}
          localVideoStream={props.localVideoStream}
          setLocalVideoStream={props.setLocalVideoStream}
          reloadCallHandler={(): void => {
            props.reloadCallHandler();
          }}
          newwaitinguser={newwaitlist}
          socket={props.socket}
        />
      </Stack.Item>
      <Stack.Item styles={containerStyles}>
        {newwaitlist && Object.keys(newwaitlist).length &&
              <MessageBar
            actions={
              <div>
                <MessageBarButton onClick={admitWaitinguser}>Admit</MessageBarButton>
                <MessageBarButton onClick={seeWaitingList}>See waiting room</MessageBarButton>
              </div>
            }
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={()=>setNewWaitList('')}
            styles={waitingUserClassName}
          >
            {newwaitlist.name} has entered the waiting room for this meeting.
            
          </MessageBar>
      }
       {newcohost  &&
              <MessageBar
                isMultiline={false}
                onDismiss={()=>setNewCoHost('')}
                styles={cohostClassName}
          >
            {newcohost}
          </MessageBar>
      }
        {/* {!props.shareScreen ? ( */}
          {props.callState === Constants.CONNECTED && (
            <Stack horizontal styles={props.screenWidth > Constants.TAB_HEADER_WINDOW_WIDTH ? containerStyles: containerStyleMin}>
              {selectedPane !== CommandPanelTypes.None && selectedPane !== CommandPanelTypes.Calling && selectedPane !== CommandPanelTypes.Chat &&
                (window.innerWidth > Constants.MINI_HEADER_WINDOW_WIDTH ? (
                  <Stack.Item disableShrink styles={paneStyles}>
                    <CommandPanel {...props} selectedPane={selectedPane} setSelectedPane={setSelectedPane} socket={props.socket}/>
                  </Stack.Item>
                ) : (
                  <Overlay styles={overlayStyles}>
                    <CommandPanel {...props} selectedPane={selectedPane} setSelectedPane={setSelectedPane} socket={props.socket}/>
                  </Overlay>
                ))}
              <Stack.Item grow styles={activeScreenShare ? activeContainerClassName : hiddenContainerClassName}>
                {activeScreenShare && <MediaFullScreen activeScreenShareStream={props.screenShareStreams[0]} />}
              </Stack.Item>
              <Stack.Item grow styles={!activeScreenShare ? activeContainerClassName : hiddenContainerClassName}>
                <MediaGallery localVideoStream={props.localVideoStream} socket={props.socket} />
              </Stack.Item>
              {selectedPane !== CommandPanelTypes.None && selectedPane === CommandPanelTypes.Calling && (
                <Stack.Item>
                <Calling onselectedPane={handleselectedPane}/>
                </Stack.Item>
              )}
              
                {(selectedPane === CommandPanelTypes.Chat || selectedPane === CommandPanelTypes.People) && ( 
              <div className='chat-container' style={{width:'50%'}}><ChatScreen /></div>
               )} 
            </Stack>
          )}
        {/* ) : (
          <div className={loadingStyle}>
            <Label>Your screen is being shared</Label>
          </div>
        )} */}
        </Stack.Item>
       
    </Stack>
  );
};
