import React from 'react';
import NewLocalSettings from './LocalSettings';
import ParticipantStack from '../containers/ParticipantStack';
import { VideoDeviceInfo, AudioDeviceInfo, DeviceManager } from '@azure/communication-calling';
import { Stack } from '@fluentui/react';
import {
  fullHeightStyles,
  paneHeaderStyle,
  settingsContainerStyle,
  paneHeaderTextStyle
} from './styles/CommandPanel.styles';
import Footer from './Footer';
//import Calling from './Calling';

export interface CommandPanelProps {
  selectedPane: string;
  videoDeviceInfo: VideoDeviceInfo;
  videoDeviceList: VideoDeviceInfo[];
  audioDeviceList: AudioDeviceInfo[];
  audioSpeakerDeviceInfo: AudioDeviceInfo;
  audioSpeakerDeviceList: AudioDeviceInfo[];
  audioDeviceInfo: AudioDeviceInfo;
  setSelectedPane: any;
  setVideoDeviceInfo(device: VideoDeviceInfo): void;
  setAudioDeviceInfo(device: AudioDeviceInfo): void;
  setAudioSpeakerDeviceInfo(device: AudioDeviceInfo): void;
  deviceManager: DeviceManager;
  socket: any;
}
export enum CommandPanelTypes {
  None = 'none',
  People = 'People',
  Settings = 'Settings',
  Recording = 'Recording',
  Calling = 'Calling',
  Chat = 'Chat'
}

export default (props: CommandPanelProps): JSX.Element => {
  return (
    <Stack styles={fullHeightStyles} >
      {props.selectedPane !== CommandPanelTypes.People &&
      <Stack.Item className={paneHeaderStyle}>
        <div className={paneHeaderTextStyle}>{props.selectedPane}</div>
      </Stack.Item>
     }
      {props.selectedPane === CommandPanelTypes.People && (
        <Stack.Item styles={fullHeightStyles}>
          <ParticipantStack socket={props.socket}/>
        </Stack.Item>
      )}
      {props.selectedPane === CommandPanelTypes.People && (
        <Stack.Item>
          <Footer />
        </Stack.Item>
      )}
      {/* {props.selectedPane === CommandPanelTypes.Calling && (
        <Stack.Item>
          <Calling />
        </Stack.Item>
      )} */}
      {props.selectedPane === CommandPanelTypes.Settings && (
        <Stack.Item>
          <div className={settingsContainerStyle}>
            <NewLocalSettings
              videoDeviceList={props.videoDeviceList}
              audioDeviceList={props.audioDeviceList}
              audioSpeakerDeviceList={props.audioSpeakerDeviceList}
              audioDeviceInfo={props.audioDeviceInfo}
              audioSpeakerDeviceInfo={props.audioSpeakerDeviceInfo}
              videoDeviceInfo={props.videoDeviceInfo}
              setVideoDeviceInfo={props.setVideoDeviceInfo}
              setAudioDeviceInfo={props.setAudioDeviceInfo}
              setAudioSpeakerDeviceInfo={props.setAudioSpeakerDeviceInfo}
              deviceManager={props.deviceManager}
            />
          </div>
        </Stack.Item>
      )}
    </Stack>
  );
};
