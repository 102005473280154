import { Provider as ProviderUI, teamsTheme, Dialog, Input } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { CallAgent, CallClient } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
//import { AnyIfEmpty } from 'react-redux';

export type TokenResponse = {
  tokenCredential: AzureCommunicationTokenCredential;
  userId: string;
};

export interface CallingProps {
  onselectedPane: () => void;
  getToken(): Promise<TokenResponse>;
  CallingAgent(tokenCredential: AzureCommunicationTokenCredential): Promise<CallAgent>;
  setPhoneCalling(callAgent: CallAgent, phoneNumber: string): void;
  endPhoneCalling(call: any): void;
}

const Calling = (props: CallingProps) => {
  const [open, setOpen] = useState(true)
  const [pstn, setPstn] = useState('')
  //const [call, setCall] = useState()
  //const [pstnerror, setPstnerror] = useState(false)
  //const { setPhoneCalling } = props;


  const onCancel = () => {
    setOpen(false);

    props.onselectedPane();
  }
  const onConfirm = async() => {
    setOpen(true);
    const { tokenCredential } = await props.getToken();
    //const callAgent = await props.CallingAgent(tokenCredential);


    const callClient = new CallClient();

    if (callClient === undefined) {
      throw new Error('CallClient is not initialized');
    }

    const callAgent: CallAgent = await callClient.createCallAgent(tokenCredential);
    
   // let calling: any =  setPhoneCalling(callAgent,pstn);
   callAgent.startCall(
    [{phoneNumber: pstn}], { alternateCallerId: {phoneNumber: '+918074503984'}
  });
 
    //setCall(call)
  }
 
  const pstnval = (e: any) => {
    if(e.target.value) setPstn(e.target.value)
  }

  return (
    <ProviderUI theme={teamsTheme}>
    <Dialog
      content={<Input placeholder="Who would you like to call?" onChange={pstnval} value={pstn}  required/>}
      confirmButton="Start Call"
      cancelButton="HangUp"
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      header="Call your friend"
      //trigger={<Button content="Open a dialog" />}
    />
    </ProviderUI>
  )
}

export default Calling