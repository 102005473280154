import React, { useState } from 'react';
import {  DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import { VideoCameraEmphasisIcon } from '@fluentui/react-icons-northstar';
import {
  endCallContainerStyle,
  endCallTitleStyle,
  buttonStyle,
  mainStackTokens,
  buttonsStackTokens,
  upperStackTokens,
  videoCameraIconStyle
} from './styles/EndCall.styles';
import { CommunicationUserKind } from '@azure/communication-common';
import { Call, RemoteParticipant } from '@azure/communication-calling';
import { ExtendedChatMessage } from '../core/reducers/MessagesReducer';
//import axios from 'axios';
import { saveEndMeetingInfo } from '../Utils/FileUpload';
//import { removeParticipant, removeThreadMemberByUserId } from '../core/sideEffects';


export interface EndCallProps {
  message: string;
  rejoinHandler(): void;
  homeHandler(): void;
  call: Call;
  userId: string;
  user: CommunicationUserKind;
  messages: ExtendedChatMessage[];
  remoteParticipants: RemoteParticipant[];
  accountId: any;
  endUserCall(): void;
}

export default (props: EndCallProps): JSX.Element => {
  const goHomePage = 'Leave call';
  const rejoinCall = 'Rejoin call';
  const [ endcall, setEndCall ] = useState<any>(props.call);

  // useEffect(() => {
  //   removeParticipant(props.call, props.user);
  //   removeThreadMemberByUserId(props.userId);
   
  // }, [])
  // setTimeout(() => {
  //   handleendcall();
  // }, 5000);

const handleendcall = async() => {
  props.endUserCall();
  if(Object.keys(endcall).length){

   saveEndMeetingInfo({
    clientId: endcall._callAgent.clientId,
    gId: endcall._info._groupId,
    accountId: props.accountId
   });
    // let postObj = {
    //   httpMethod: 'POST',
    //   path: '/endcallinfos3',
    //   body:{
    //     clientId: endcall._callAgent.clientId, 
    //     groupId: endcall._info._groupId, 
    //     //chat:  props.messages,
    //    // totalusers: props.remoteParticipants
    //   }
    // };
    // await axios.post('https://859rhjazlf.execute-api.us-east-1.amazonaws.com/acs-gatheringjar/endcallinfos3',postObj);
    //await axios.post('http://localhost:5000/savechats3',{ groupId: endcall._info._groupId, chat:  chatinfo});
    setEndCall('');
   }
    
    sessionStorage.removeItem("msgseen");
    sessionStorage.removeItem("msgcount");
    props.homeHandler();
  
}

  
  return (
    <Stack verticalAlign="center" tokens={mainStackTokens} className={endCallContainerStyle}>
      <Stack tokens={upperStackTokens}>
        <div className={endCallTitleStyle}>{props.message}</div>
        <Stack horizontal tokens={buttonsStackTokens}>
          <PrimaryButton className={buttonStyle} onClick={props.rejoinHandler}>
            <VideoCameraEmphasisIcon className={videoCameraIconStyle} size="medium" />
            {rejoinCall}
          </PrimaryButton>
          <DefaultButton className={buttonStyle} onClick={handleendcall}>
            {goHomePage}
          </DefaultButton>
        </Stack>
        {/* <div className={bottomStackFooterStyle}>
          <a href={feedbackLink}>Give Feedback</a>
          &nbsp;on this sample app on Github
        </div> */}
      </Stack>
    </Stack>
  );
};


