import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';

import ChatArea from '../containers/ChatArea';
//import ChatHeader from '../containers/ChatHeader';
//import SidePanel from '../containers/SidePanel';
import { chatScreenBottomContainerStyle } from './styles/ChatScreen.styles';
//import { SidePanelTypes } from './SidePanel';
import { FluentThemeProvider } from '@azure/communication-react';


export interface ChatScreenProps {
   //leaveChatHandler(): void;
   //removedFromThreadHandler(): void;
  isRemoved: boolean;
   getFiles(): void;
}

export default (props: ChatScreenProps): JSX.Element => {
  // People pane will be visible when a chat is joined if the window width is greater than 600
  // const [selectedPane, setSelectedPane] = useState(
  //   window.innerWidth > 600 ? SidePanelTypes.People : SidePanelTypes.None
  // );

  // const { leaveChatHandler, isRemoved, removedFromThreadHandler } = props;

  // when the screen first loads we want to put focus on the sendbox
  useEffect(() => {
    document.getElementById('sendbox')?.focus();
    props.getFiles();
  }, []);

  // if a user is on the chat screen and they are removed from the chat thread
  // we want to move them to the removedFromThread screen
  // useEffect(() => {
  //   if (isRemoved) {
  //     removedFromThreadHandler();
  //   }
  // }, [isRemoved, removedFromThreadHandler]);

  return (
    <FluentThemeProvider>
    {/* <Stack className={chatScreenContainerStyle}> */}
      {/* <ChatHeader leaveChatHandler={leaveChatHandler} selectedPane={selectedPane} setSelectedPane={setSelectedPane} /> */}
      <Stack className={chatScreenBottomContainerStyle} horizontal={true}>
   
        <ChatArea />
        {/* <Stack.Item grow disableShrink>
          <SidePanel selectedPane={selectedPane} setSelectedPane={setSelectedPane} />
        </Stack.Item> */}
      </Stack>
    {/* </Stack> */}
    </FluentThemeProvider>
  );
};
