import React, { useState, useEffect } from 'react';
import { Separator, Pivot, PivotItem, Stack, Persona, PersonaSize, PersonaPresence, Callout, Link, Modal, ResponsiveMode, Text, PrimaryButton, Spinner } from '@fluentui/react';
import { Call, LocalVideoStream, RemoteParticipant, VideoDeviceInfo } from '@azure/communication-calling';
import MediaControls from './MediaControls';
import { CommandPanelTypes } from './CommandPanel';
//import { Dialog } from '@fluentui/react-northstar';
import { UserFriendsIcon, SettingsIcon, CloseIcon, ChatIcon } from '@fluentui/react-icons-northstar';
import { Constants } from '../core/constants';
import {
  headerContainer,
  pivotItemStyles,
  separatorContainerStyle,
  separatorStyles,
  pivotItemStyle,
  headerCenteredContainer,
  showProfileDropdownStyle,
  editLabelStyle,
  profileModalStyles,
  modalHeaderStyle,
  iconButtonStyles,
  personIconStyle,
  uploadButton,
  chatIconContainer,
  pivotItemChatSelectedStyle,
  pivotItemChatStyle,
  countBadge,
  endcallbuttonStyle
} from './styles/Header.styles';
import { ParticipantStream } from '../core/reducers';
import { ExtendedChatMessage } from '../core/reducers/MessagesReducer';
import { Input } from '@fluentui/react-northstar';
import { warningStyle } from './styles/Configuration.styles';
//import axios from 'axios';
import { uploadProfileImage, getProfileImage } from '../Utils/FileUpload';

//import S3 from 'react-aws-s3';


export interface HeaderProps {
  selectedPane: CommandPanelTypes;
  setSelectedPane: any;
  endCallHandler(): void;
  actionable: boolean;
  localVideo: boolean;
  mic: boolean;
  shareScreen: boolean;
  call: Call;
  messages: ExtendedChatMessage[];
  endCall(): void;
  screenShareStreams: ParticipantStream[];
  activeScreenShareStream: ParticipantStream | undefined;
  localVideoRendererIsBusy: boolean;
  cameraPermission: string;
  microphonePermission: string;
  screenWidth: number;
  setMic(mic: boolean): void;
  setLocalVideoStream(localVideoStream: LocalVideoStream | undefined): void;
  setScreenShare(screenShare: boolean): void;
  isLocalScreenShareSupportedInBrowser(): boolean;
  localVideoStream: LocalVideoStream | undefined;
  videoDeviceInfo: VideoDeviceInfo | undefined;
  displayName: string;
  userspic: string;
  uploadpic(displayName: string, email:string, profileImage: any):void;
  user: any;
  uloadProfileImage(image:any):void;
  groupId: string;
  getAllFiles(): void;
  accountId: any;
  reloadCallHandler(): void;
  remoteParticipants: RemoteParticipant;
  newwaitinguser: any;
  waitList: any;
  connList: any;
  attendeeData: any;
  local: any;
  setAttendeeData(data: any): void;
  userId:any;
  socket: any;
  settings: any;
  ismeetinglocked: boolean;
  iswaitingenabled: boolean;
  handraised:any;
}

export default (props: HeaderProps): JSX.Element => {
const [showprofile, setShowProfile] = useState(false);
const [showprofilemodal, setShowProfileModal] = useState(false);
const [isvalidFile, setIsValidFile] = useState(true);
const [profileImage, setProfileImage] = useState<any>(null);
const [userImageName, setUserImageName] = useState("");
const [isuploading, setIsUploading] = useState(false);
const [showMore, setShowMore] = useState(false);

const isRaiseHand = props.handraised.some((el:any) => el == props.userId);

const mSettings = props.settings.MeetingSettings;
const isIamHost = mSettings.host[0] == props.user.userEmail;

 const togglePeople = (selectedPane: string, setSelectedPane: (pane: string) => void): void => {
    return selectedPane !== CommandPanelTypes.People
      ? setSelectedPane(CommandPanelTypes.People)
      : setSelectedPane(CommandPanelTypes.None);
  };

  const toggleOptions = (selectedPane: string, setSelectedPane: (pane: string) => void): void => {
    return selectedPane !== CommandPanelTypes.Settings
      ? setSelectedPane(CommandPanelTypes.Settings)
      : setSelectedPane(CommandPanelTypes.None);
  };

  // const toggleCall = (selectedPane: string, setSelectedPane: (pane: string) => void): void => {
  //   return selectedPane !== CommandPanelTypes.Calling
  //     ? setSelectedPane(CommandPanelTypes.Calling)
  //     : setSelectedPane(CommandPanelTypes.None);
  // };

  const toggleChat = (selectedPane: string, setSelectedPane: (pane: string) => void): void => {
    return selectedPane !== CommandPanelTypes.Chat
      ? setSelectedPane(CommandPanelTypes.Chat)
      : setSelectedPane(CommandPanelTypes.None);
  };

  const handleLocalVideoOnOff = async (): Promise<void> => {
    if (props.localVideoStream) {
      await props.call.stopVideo(props.localVideoStream);
      props.setLocalVideoStream(undefined);
    } else {
      if (props.videoDeviceInfo) {
        const localVideoStream = new LocalVideoStream(props.videoDeviceInfo);
        props.setLocalVideoStream(localVideoStream);
        await props.call.startVideo(localVideoStream);
      }
    }
  };

  const handleMuteOnOff = (): void => {
    props.setMic(!props.mic);
  };

  const handleScreenSharingOnOff = (): void => {
    props.setScreenShare(!props.shareScreen);
  };

  useEffect(() => {
    if (props.call && props.call.localVideoStreams.length === 0 && props.localVideoStream) {
      props.call.startVideo(props.localVideoStream);
    }
  }, [props.call, props.localVideoStream]);

 

  useEffect(() => {
   
    if(props.user && props.user.userEmail && props.user.displayName){
    let imgname = `${props.user.userEmail}#${props.user.displayName}.jpg`;
    getProfileImage({accountId: props.accountId, userImage: imgname}).then((pimg)=>{
       if(pimg !== null){
        setUserImageName(pimg);
        props.uloadProfileImage([...new Set(props.userspic), pimg]);
       }
       
    });
   
  }
  }, [props.user.displayName])
  

  if(props.selectedPane === CommandPanelTypes.Chat){
      sessionStorage.setItem("msgseen", "yes");
      sessionStorage.setItem("msgcount", `${props.messages.length}`);
  }else{
    sessionStorage.setItem("msgseen", "no");
    sessionStorage.setItem("msgcount", `${props.messages.length}`);
  }
let prevcount:any = sessionStorage.getItem("msgcount") ? sessionStorage.getItem("msgcount") : 0;

const isValidFileUploaded = (file: any) => {
  const validExtensions = ['png', 'jpeg', 'jpg']
  const fileExtension = file.type.split('/')[1]
  return validExtensions.includes(fileExtension)
}
const onFileChange = (e: any) => {

  if (e.target.files.length < 1) {
    return;
  }
  const file = e.target.files[0];
  if (isValidFileUploaded(file)) {
    setProfileImage(file);
    setIsValidFile(true);
  } else {
    setIsValidFile(false);
  }
};

// useEffect(() => {
//   props.getAllFiles();
// }, [userImageName])


const uploadProfileImageS3 = async ()=>{
  if(profileImage !== null && props.user.userEmail){
   
    let Ftype = profileImage.name.split('.').pop().toLowerCase();
    if(Ftype == 'png' || 'jpeg') Ftype = 'jpg';
    let extension = Ftype;
     if(Ftype == 'jpg' || 'jpeg' || 'png') extension = 'jpeg';
   
    let new_file = new File([profileImage], `${props.user.userEmail}#${props.user.displayName}.${Ftype}`,{type: `image/${extension}`});
    let fname = new_file.name;

   /**Store profileImage in azure blob Storage */
   setIsUploading(true);
   uploadProfileImage({
    file: new_file,
    accountId: props.accountId
   }).then(async(res:any)=>{
    
    if(res.status === 200){
       let uimg = await getProfileImage({accountId: props.accountId, userImage: fname});
      if(uimg !== null){
        setUserImageName(uimg);
        setShowProfileModal(false);
        setShowProfile(false);
        props.uloadProfileImage([...props.userspic, uimg]);
        props.getAllFiles();    
       
      }
      setIsUploading(false);
      if(userImageName) props.reloadCallHandler();
    }
   
   })

  }
};

const handleMeetingLock = () => {
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: 'lock',
      type: "meetinglock"
    }
  }))
}

const handleWaitingRoom = () => {
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: 'wait',
      type: "checkwaitingroom"
    }
  }))
}

const endMeetingAll = () => {
 
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data: 'end',
      type: "endallmeeting"
    }
  }))
}

const handleRaiseHand = (uid:any) => {
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data:  uid,
      type: "raisehand"
    }
  }))
}

const handleHandsDown = (uid:any) => {
  props.socket.current?.send(JSON.stringify({
    action: "sendMessage",
    message: {
      connections: props.connList,
      data:  uid,
      type: "handsdown"
    }
  }))
}

  return (
    <Stack
      id="header"
      className={props.screenWidth > Constants.MINI_HEADER_WINDOW_WIDTH ? headerContainer : headerCenteredContainer}
    >
      
      <div className="header-logo" style={{'display':'flex','flex':'auto', position:'absolute',float:'left',left:'0',height:'auto',width:'60%'}}><img style={{'width':'20%','marginLeft':'2rem'}} src="./images/logo.png" alt="Logo"/></div>
     
      <div id="showmore" style={{fontSize:'24px',cursor:'pointer', padding:'0 10px 10px 0'}} onClick={()=>setShowMore(!showMore)} >...</div>
      {showMore && (
        <Callout
        //className={streamContainer}
        role="dialog"
        gapSpace={0}
        target={`#showmore`}
        onDismiss={()=>setShowMore(!showMore)}
        setInitialFocus
        style={{minHeight:'1.5rem',padding:'10px'}}
      >
        {isIamHost ? (
          <>
          <div style={{cursor:'pointer',textAlign:'left'}} onClick={handleMeetingLock}>{props.ismeetinglocked ? 'Unlock Meeting':'Lock Meeting'}</div>
          <div style={{cursor:'pointer',textAlign:'left',padding:'10px 0'}} onClick={handleWaitingRoom}>{props.iswaitingenabled ? 'Disable Waiting Room':'Enable Waiting Room'}</div>
          <div className={endcallbuttonStyle} onClick={endMeetingAll}>End meeting for all</div>
          </>
        ): (
          <>
          {!isRaiseHand ? <div style={{cursor:'pointer'}} onClick={()=>handleRaiseHand(props.userId)}>Raise Hand</div>
          : <div style={{cursor:'pointer'}} onClick={()=>handleHandsDown(props.userId)}>Hands Down</div>}
          </>
        )}
         
      </Callout>
      )}
          <div className={chatIconContainer}>
           <ChatIcon
              outline={props.selectedPane === CommandPanelTypes.Chat ? false : true}
              size="medium"
              className={props.selectedPane === CommandPanelTypes.Chat ? pivotItemChatSelectedStyle: pivotItemChatStyle}
              onClick={()=>toggleChat(props.selectedPane, props.setSelectedPane)}
            />
   {/* props.selectedPane !== CommandPanelTypes.Chat && */}
        { props.selectedPane !== CommandPanelTypes.Chat &&
          props.messages.length > 0 && (props.messages.length - prevcount) > 0 &&
          sessionStorage.getItem("msgseen") !== "yes"
          && <span className={countBadge}>{(props.messages.length - prevcount )}</span>}
      </div>
      <Pivot
        onKeyDownCapture={(e): void => {
          if ((e.target as HTMLElement).id === CommandPanelTypes.People && e.keyCode === 39) e.preventDefault();
        }}
        getTabId={(itemKey: string): string => itemKey}
        onLinkClick={(item): void => {
          if (!item) return;
          if (item.props.itemKey === CommandPanelTypes.Settings)
            toggleOptions(props.selectedPane, props.setSelectedPane);
          if (item.props.itemKey === CommandPanelTypes.People) togglePeople(props.selectedPane, props.setSelectedPane);
         // if (item.props.itemKey === CommandPanelTypes.Calling) toggleCall(props.selectedPane, props.setSelectedPane);
          //if (item.props.itemKey === CommandPanelTypes.Chat) toggleChat(props.selectedPane, props.setSelectedPane);
        }}
        styles={pivotItemStyles}
        initialSelectedKey={CommandPanelTypes.None}
        selectedKey={props.selectedPane}
      >
        {/* <PivotItem
          itemKey={CommandPanelTypes.Chat}
          onRenderItemLink={(): JSX.Element => (
            <ChatIcon
              outline={props.selectedPane === CommandPanelTypes.Chat ? false : true}
              size="medium"
              className={pivotItemStyle}
            />
          )}
        /> */}
        
        <PivotItem
          itemKey={CommandPanelTypes.Settings}
          onRenderItemLink={(): JSX.Element => (
            <SettingsIcon
              outline={props.selectedPane === CommandPanelTypes.Settings ? false : true}
              size="medium"
              className={pivotItemStyle}
            />
          )}
        />
        
        {/* <PivotItem
          itemKey={CommandPanelTypes.Calling}
          onRenderItemLink={(): JSX.Element => (
            <CallIcon
              outline={props.selectedPane === CommandPanelTypes.Calling ? false : true}
              size="medium"
              className={pivotItemStyle}
              />
          )}
          
         /> */}
      
        <PivotItem
          itemKey={CommandPanelTypes.People}
          onRenderItemLink={(): JSX.Element => (
            <UserFriendsIcon
              outline={props.selectedPane === CommandPanelTypes.People ? false : true}
              size="medium"
              className={pivotItemStyle}
            />
          )}
        />
    
        <PivotItem itemKey={CommandPanelTypes.None} />
      </Pivot>
      {props.screenWidth > Constants.MINI_HEADER_WINDOW_WIDTH && (
        <div className={separatorContainerStyle}>
          <Separator styles={separatorStyles} vertical={true} />
        </div>
      )}
      <MediaControls
        micActive={props.mic}
        onMicChange={handleMuteOnOff}
        cameraActive={props.localVideoStream !== undefined}
        onCameraChange={handleLocalVideoOnOff}
        screenShareActive={props.shareScreen}
        activeScreenShareStream={props.screenShareStreams[0] ?? undefined}
        onScreenShareChange={handleScreenSharingOnOff}
        onEndCallClick={(): void => {
          if (props.localVideo) handleLocalVideoOnOff();
          props.endCall();
        }}
        cameraPermission={props.cameraPermission}
        microphonePermission={props.microphonePermission}
        localVideoRendererIsBusy={props.localVideoRendererIsBusy}
        compressedMode={props.screenWidth <= Constants.TAB_HEADER_WINDOW_WIDTH}
        isLocalScreenShareSupportedInBrowser={props.isLocalScreenShareSupportedInBrowser}
      />
    <div className={'profileimage'}>
    <Persona
       //text={props.displayName}
      //styles={itemStyles}
      className={personIconStyle}
      size={PersonaSize.size40}
      imageUrl={userImageName && userImageName}
      presence={PersonaPresence.online }
      id="uploadpic"
      onClick={()=>setShowProfile(!showprofile)}
    />
    {showprofile && 
      <Callout
          
          className={showProfileDropdownStyle}
          ariaLabelledBy={""}
          ariaDescribedBy={""}
          role="dialog"
          gapSpace={0}
          target={`#uploadpic`}
          //onDismiss={""}
          setInitialFocus
        >
          <Link  className={editLabelStyle} onClick={()=>setShowProfileModal(true)}>Edit Profile</Link>
        </Callout>
    }
    </div> 
    {showprofilemodal &&
    <Modal
              titleAriaId={"profilemodal"}
              isOpen={showprofilemodal}
              onDismiss={()=>setShowProfileModal(false)}
              isBlocking={false}
              className={profileModalStyles}
              responsiveMode={ResponsiveMode.small}
            //dragOptions={isDraggable ? dragOptions : undefined}
            >
               <div className={modalHeaderStyle}> 
              <Text variant='xLarge' >Upload Profile Image</Text>
                {/* <IconButton
                        className={iconButtonStyles}
                        ariaLabel="Close popup modal"
                        onClick={()=>setShowProfileModal(false)}
                      /> */}
                      <CloseIcon className={iconButtonStyles} size="large" onClick={()=>setShowProfileModal(false)} />
                </div> 
                <Input type="file" onChange={onFileChange} />
                {!isvalidFile && (
                <div role="alert" className={warningStyle}>
                  {'File is not valid '}
                  {' '}
                </div>
              )}
              <PrimaryButton text="Upload" className={uploadButton} onClick={uploadProfileImageS3} disabled={isuploading} />
              {isuploading && <Spinner label="uploading.." ariaLive="assertive" labelPosition="right" style={{ paddingLeft: '7rem', margin:'-1.5rem 0', position:'absolute' }} />}
            </Modal> }
          
    </Stack>
  );
};
