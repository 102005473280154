import { connect } from 'react-redux';
import Header, { HeaderProps } from '../components/Header';
import { setMicrophone, setShareUnshareScreen, uploadFile, getAllProfileImages } from '../core/sideEffects';
import {  setContosoUsersImage } from '../core/actions/ContosoClientAction';
import { utils } from '../Utils/Utils';
import { Constants } from '../core/constants';
import { State } from '../core/reducers';
import { setAttendeeDataList } from 'core/actions/sdk';

const mapStateToProps = (state: State, props: HeaderProps) => ({
  actionable:
    state.calls.callState === Constants.INCOMING ||
    state.calls.callState === Constants.RINGING ||
    state.calls.callState === Constants.CONNECTING ||
    state.calls.callState === Constants.CONNECTED,
  mic: state.controls.mic,
  userId: state.sdk.userId,
  call: state.calls.call,
  messages: state.chat.messages,
  shareScreen: state.controls.shareScreen,
  endCall: (): void => {
    //state.calls.call && endCall(state.calls.call, { forEveryone: false });
    props.endCallHandler();
  },
  videoDeviceInfo: state.devices.videoDeviceInfo,
  screenWidth: props.screenWidth,
  screenShareStreams: state.streams.screenShareStreams,
  localVideoRendererIsBusy: state.streams.localVideoRendererIsBusy,
  cameraPermission: state.devices.cameraPermission,
  microphonePermission: state.devices.microphonePermission,
  displayName: state.calls.callAgent?.displayName,
  // userspic :  state.contosoClient.images && state.contosoClient.images.length && state.contosoClient.images.filter((l:any)=>{
  //   let img = l.split('@').pop();
  //   if(img.substring(0, img.lastIndexOf("."))== state.calls.callAgent?.displayName ) return l;
  // }),
  userspic :  state.contosoClient.images,
  user: state.contosoClient.user,
  groupId: state.calls.group,
  accountId: state.sdk.meetingData.AccountInfo.OrgAccountId,
  remoteParticipants : state.calls.remoteParticipants,
  waitList: state.sdk.waitingList,
  connList: state.sdk.connectionList,
  attendeeData: state.sdk.attendeeData,
  local: state.sdk.localwaitinguser,
  settings: state.sdk.meetingData,
  ismeetinglocked: state.sdk.isMeetingLocked,
  iswaitingenabled: state.sdk.isWaitingRoomEnabled,
  handraised: state.sdk.raiseHands
});

const mapDispatchToProps = (dispatch: any) => ({
  setMic: (mic: boolean): void => dispatch(setMicrophone(mic)),
  setScreenShare: (screenShare: boolean): void => dispatch(setShareUnshareScreen(screenShare)),
  // Only support Desktop -- Chrome | Edge (Chromium) | Safari
  isLocalScreenShareSupportedInBrowser: (): boolean => {
    return (
      !utils.isMobileSession() &&
      (/chrome/i.test(navigator.userAgent.toLowerCase()) || /safari/i.test(navigator.userAgent.toLowerCase()))
    );
  },
  uploadpic: async (displayName: string, userEmail: string, profileImage: any) => {
    dispatch(uploadFile(displayName, userEmail, profileImage));
  },
  uloadProfileImage:(image: any): void => dispatch(setContosoUsersImage(image)),
  getAllFiles: () => dispatch(getAllProfileImages()),
  setAttendeeData: (data: any): void => dispatch(setAttendeeDataList(data))
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(Header);
